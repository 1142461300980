/*///////////////////////////////

mixin

/////////////////////////////*/

@use "../setting"as *;

//タブレット
@mixin tab {
  @media (min-width: #{$w-breakpoint-tab + 1}px) {
    @content;
  }
}

//PC
@mixin pc {
  @media (min-width: #{$w-breakpoint-sp + 1}px) {
    @content;
  }
}

@mixin sp {
  @media (max-width: #{$w-breakpoint-sp}px) {
    @content;
  }
}


@mixin pc-plus {
  @media (max-width: 1200px) {
    @content;
  }
}

// 520
@mixin small {
  @media (max-width: 520px) {
    @content;
  }
}


@mixin mini {
  @media (max-width: 320px) {
    @content;
  }
}

//mixin
@mixin box {
  display: block;
  content: "";
}

@mixin auto {
  margin-right: auto;
  margin-left: auto;
}

@mixin centering($direction) {
  position: absolute;

  @if $direction==xy {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @else if $direction==x {
    left: 50%;
    transform: translateX(-50%);
  }

  @else if $direction==y {
    top: 50%;
    transform: translateY(-50%);
  }
}

//line-height
@mixin default-height {
  line-height: 1;
}

// img
@mixin contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@mixin cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// font
@mixin gothic {
  font-family: "Noto Sans JP", sans-serif;
}

@mixin mincho {
  font-family: "Noto Serif JP", serif;
}

@mixin sippori {
  font-family: "Shippori Mincho", serif;
}

@mixin en {
  font-family: 'Chillax', sans-serif;

}

// 画像出し分け
$webpClassName: ".webp";
$noWebpClassName: ".no-webp";

@mixin webp($filePath, $fileName, $fileExtension: "jpg") {
  #{$webpClassName} & {
    background-image: url(#{$filePath}webp/#{$fileName}.webp);
  }

  #{$noWebpClassName} & {
    background-image: url(#{$filePath}.#{$fileExtension});
  }
}

// polygon
@mixin polygon-red {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background: $red;
}

@mixin polygon-green {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background: $green;
}

@mixin polygon-blue {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background: $blue-dark;
}

@mixin polygon-gray {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background: $gray;
}




@function vw($vw_value) {
  @return ($vw_value / 7.5+0vw);
}