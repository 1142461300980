@use "../global"as *;

.archive {
    &-mv {
        position: relative;
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;

        @include pc() {
            background-image: url(../images/background-service_mv.png);
            background-position: center;
        }

        @include sp() {
            background-image: url(../images/background_mvsp.png);
            height: vw(800);
        }


        @include mini() {
            height: 50vh;
        }


        &_wrap {
            padding-top: 25%;

            @include pc() {
                padding-top: 15%;
            }

            @include mini() {
                padding-top: 15vh;
            }
        }

        &_loopSlide {
            position: relative;
            display: flex;
            overflow: hidden;
            padding: vw(20) 0 0;
            z-index: 1;

            ul {
                display: flex;
                list-style: none;
                padding: 0;
                animation: infinity-scroll-right 20s infinite linear .5s both;

                li {
                    width: calc(100vw / 3);

                    @include pc {
                        width: calc(100vw / 6);
                    }

                    padding-right: vw(15);

                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }

                }
            }

            @keyframes infinity-scroll-right {
                from {
                    transform: translateX(-100%);
                }

                to {
                    transform: translateX(0%);
                }
            }

            &:nth-of-type(2) {

                ul {
                    margin-left: 40px;

                    &:nth-of-type(2) {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-left: 0;
                    }
                }
            }

        }
    }


    &-works {
        position: relative;
        padding: vw(60) 0;

        &::before {
            position: absolute;
            bottom: 0%;
            left: -5%;
            width: 500px;
            height: 604px;
            background: url(../images/background_service01.png) no-repeat left top;
            background-size: 450px auto;
            content: "";

            @include sp() {
                bottom: 0%;
                left: -5%;
                width: 350px;
                height: 450px;
                background-size: 350px auto;
            }

            @include small() {
                bottom: -0%;
                left: -10%;
                width: 250px;
                height: 350px;
                background-size: 250px auto;
            }
        }

        &::after {
            position: absolute;
            bottom: -10%;
            right: -8%;
            width: 400px;
            height: 600px;
            background: url(../images/service_newsimg.png) no-repeat left top;
            background-size: 400px auto;
            content: "";

            @include sp() {
                bottom: -12%;
                right: -12%;
                width: 300px;
                height: 500px;
                background-size: 300px auto;
            }

            @include small() {
                bottom: -10%;
                right: -8%;
                width: 200px;
                height: 400px;
                background-size: 200px auto;
            }
        }

        @include pc() {
            padding: vw(40) 0 vw(20);
        }

        .ttl {
            margin-bottom: vw(40);

            @include pc() {
                margin-bottom: 40px;
            }
        }

        .sub_ttl {
            margin-bottom: vw(30);

            @include sp() {
                font-size: max(vw(20), 16px);
            }
        }

        &_categories {

            .inner {
                display: flex;

                @include pc() {
                    justify-content: flex-start;
                }

                @include sp() {
                    flex-wrap: wrap;
                    justify-content: center;
                }

                @include small() {
                    display: block;
                }
            }
        }

        &_tab_btn {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 5rem;
            border: 0.1rem solid $color-yellow;
            background-color: #fff;
            transition: all .2s;
            cursor: pointer;
            font-size: max(vw(28), 12px);
            font-weight: bold;

            @include pc() {
                font-size: max(vw(7), 20px);
                width: 20%;
            }

            @include sp() {
                width: 46%;

                &:nth-child(2n) {
                    margin-left: 20px;
                }
            }

            @include small() {
                width: 100%;

                &:nth-child(2n) {
                    margin-left: 0px;
                }


            }

            &:not(:last-of-type) {
                @include pc() {
                    margin-right: 1em;
                }

                @include sp() {
                    margin-bottom: 26px;
                }
            }

            &.active {
                color: #333;
                background-color: $color-yellow;
                border: 1px solid $color-yellow;
            }


        }

        &_inner {
            position: relative;
            margin-top: vw(20);
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            @include small() {
                display: block;
            }

        }

        .menu-box {
            &:first-child {
                margin: 10px;
            }
        }

        &_card {
            height: inherit;
            background: #fff;
            position: relative;
            padding-bottom: 40px;
            margin: 0 0 vw(120);
            // box-shadow: 0 2px 8px rgba(0, 0, 0, .05);
            width: calc(50% - 10px);
            z-index: 1;

            @include pc() {
                padding: 0;
                width: calc(33.333% - 25px);

                +.archive-works_card {
                    margin-left: 20px;
                }

                &:nth-child(3n + 1) {
                    margin-left: 0;
                }

            }

            @include sp() {
                margin: 0 0 vw(200);

                &:nth-child(2n) {
                    margin-left: 20px;
                }
            }

            @include small() {
                width: 100%;
                margin: 0 0 vw(350);

                &:nth-child(2n) {
                    margin-left: 0px;
                }
            }

            @include mini() {
                margin: 0 0 vw(400);
            }


            a {
                color: #333;

                &:hover {
                    opacity: 1;
                }
            }

            &ImgBox {
                position: relative;
                overflow: hidden;
                display: block;
                width: 100%;

                &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    padding-top: 70%;
                }



                @include pc() {
                    // width: 50%;
                }

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                    -o-object-position: center center;
                    object-position: center center;
                    transition: transform .5s, opacity .5s, -webkit-transform .5s;
                }

                &:hover {
                    .archive-works_cardImg {
                        &::after {
                            opacity: 1;
                        }
                    }

                    img {
                        transform: scale(1.1);
                        opacity: 1;

                    }

                    .archive-works_cardlogo {
                        img {
                            transform: scale(1);

                        }
                    }


                }
            }

            &logo {
                position: absolute;
                top: 0;
                right: 0;
                width: 80px;
                height: 80px;

            }

            &Desc {
                padding: 15px vw(10);
                position: absolute;
                width: 95%;
                left: 2.5%;
                top: 65%;
                background: #fff;
                height: inherit;
                -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
                box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
                border-radius: 20px;

                @include sp() {
                    padding: 15px vw(25);
                }


                @include small() {
                    padding: 15px vw(30);
                }


                .archive-works_cardTtl {
                    font-size: min(vw(14), 2.6rem);
                    letter-spacing: 2px;
                    color: #444;
                    line-height: 1.5;
                    width: 100%;

                    @include pc() {
                        font-size: min(vw(9), 1.8rem);
                    }

                    @include small() {
                        font-size: min(vw(30), 2.6rem);
                    }
                }


                .archive-works_cardcont {
                    color: #666;
                    letter-spacing: 2px;
                    font-size: min(vw(11), 2rem);
                    line-height: 1.5;
                    text-transform: uppercase;
                    margin-top: vw(5);
                    border-bottom: 1px solid #eee;
                    padding-bottom: 20px;

                    @include pc() {
                        font-size: min(vw(8), 16px);
                    }

                    @include small() {
                        font-size: min(vw(25), 2rem);
                    }
                }

                .archive-works_cardname {
                    padding: 10px 0 0;
                    font-size: min(vw(14), 2.4rem);

                    @include pc() {
                        padding: 15px 0 0;
                        font-size: min(vw(8), 18px);
                    }

                    @include small() {
                        font-size: min(vw(23), 2.4rem);
                    }

                }
            }
        }
    }

    &-news {
        position: relative;
        padding: vw(100) 0 vw(80);
        // background: #F9F9F9;

        @include pc() {
            padding: vw(20) 0;
        }

        &:before {
            position: absolute;
            top: -5%;
            left: -10%;
            width: 500px;
            height: 604px;
            background: url(../images/service_accordionimg.png) no-repeat left top;
            background-size: 450px auto;
            content: "";

            @include sp() {
                content: none;
            }
        }

        &::after {
            position: absolute;
            bottom: -10%;
            right: -8%;
            width: 400px;
            height: 600px;
            background: url(../images/service_newsimg.png) no-repeat left top;
            background-size: 400px auto;
            content: "";

            @include sp() {
                bottom: -12%;
                right: -12%;
                width: 300px;
                height: 500px;
                background-size: 300px auto;
            }

            @include small() {
                bottom: -10%;
                right: -8%;
                width: 200px;
                height: 400px;
                background-size: 200px auto;
            }
        }

        &_categories {
            margin-top: vw(50);
            display: flex;

            @include pc() {
                justify-content: flex-start;
            }

            @include sp() {
                flex-wrap: wrap;
                justify-content: center;
            }

            @include small() {
                display: block;
            }

        }

        &_tab_btn {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 5rem;
            border: 0.1rem solid $color-yellow;
            transition: all .2s;
            cursor: pointer;
            font-size: max(vw(28), 12px);
            font-weight: bold;

            @include pc() {
                font-size: max(vw(7), 20px);
                width: 20%;
            }

            @include sp() {
                width: 46%;

                &:nth-child(2n) {
                    margin-left: 20px;
                }
            }

            @include small() {
                width: 100%;

                &:nth-child(2n) {
                    margin-left: 0px;
                }


            }

            &:not(:last-of-type) {
                @include pc() {
                    margin-right: 1em;
                }

                @include sp() {
                    margin-bottom: 26px;
                }
            }

            &.active {
                color: #333;
                background-color: $color-yellow;
                border: 1px solid $color-yellow;
            }


        }

        &_content {
            position: relative;
            margin-top: vw(20);
            // background-color: #fff;
            border-radius: 20px;
            // padding: vw(30) vw(30) vw(10);

            @include pc() {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                // max-width: 1000px;
                // margin: vw(40) auto 0;
                // padding: vw(30) vw(50) vw(10);
            }

            &-item {

                a {
                    display: block;
                    padding: 25px 10px;
                    text-decoration: none;
                    color: #444;
                    position: relative;

                    @include pc() {
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        padding: 6px 0;
                        margin: 6px 0;
                    }

                    // &::before {
                    //   content: "";
                    //   position: absolute;
                    //   right: 0;
                    //   top: 50%;
                    //   width: max(vw(30), 30px);
                    //   height: 1px;
                    //   background: #000;
                    //   -webkit-transition: all 0.4s;
                    //   transition: all 0.4s;

                    //   @include pc {
                    //     right: 20px;
                    //     width: 5%;
                    //   }
                    // }

                    // &::after {
                    //   content: "";
                    //   display: inline-block;
                    //   position: absolute;
                    //   right: vw(-18);
                    //   top: 45%;
                    //   height: 1px;
                    //   width: 10px;
                    //   border-color: transparent transparent transparent #000;
                    //   border-style: solid;
                    //   border-width: 4px 0 0 7px;
                    //   transition: all 0.4s;

                    //   @include pc {
                    //     right: 8px;
                    //     top: 13px;
                    //   }

                    //   @include mini {
                    //     right: vw(-30);
                    //   }
                    // }

                    // &:hover {
                    //   opacity: 1;

                    //   &::before {
                    //     right: 5px;

                    //     @include sp {
                    //       right: -5px;
                    //     }
                    //   }

                    //   &::after {
                    //     right: -5px;


                    //     @include sp {
                    //       right: -17px;
                    //     }
                    //   }
                    // }


                }

                &Category {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 4rem;
                    border: 0.1rem solid $color-yellow;
                    transition: all .2s;
                    cursor: pointer;
                    font-size: vw(15);
                    max-width: 160px;

                    @include pc() {
                        font-size: 1.6rem;
                        width: 18%;
                        margin-right: vw(15);
                    }

                    @include small() {
                        font-size: vw(30);
                    }
                }

                &Date {
                    letter-spacing: 1px;
                    font-weight: 700;
                    color: #aaa;
                    line-height: 1.5;
                    font-size: vw(15);
                    width: auto;
                    font-family: Heebo, sans-serif;
                    display: block;
                    margin-bottom: 10px;

                    @include pc() {
                        font-size: 1.6rem;
                        width: 15%;
                    }

                    @include small() {
                        font-size: vw(30);
                    }
                }

                &Ttl {
                    font-weight: 400;
                    margin-right: auto;
                    line-height: 1.5;
                    font-size: vw(18);

                    @include pc() {
                        padding: 20px 0;
                        font-size: 2rem;
                        text-overflow: ellipsis;
                        -webkit-box-flex: 1;
                        -ms-flex: 1;
                        flex: 1;
                    }

                    @include small() {
                        font-size: vw(35);
                    }

                    &:hover {
                        text-decoration: underline;
                    }

                    .headline {
                        font-weight: bold;
                    }

                    .desc {
                        line-height: 1.5;
                    }
                }

                &:nth-child(2) {
                    border-top: 1px solid #e6e6e6;
                    border-bottom: 1px solid #e6e6e6;
                }

                &:nth-child(3) {
                    border-bottom: 1px solid #e6e6e6;
                }
            }
        }

    }

    &-contact {
        margin-top: 80px;
        position: relative;
        z-index: 1;

        @include sp() {
            padding: vw(40) 0;
        }

        &_title {
            text-align: center;
            padding: vw(20) 0;
            color: #fff !important;

            &::before {
                background: #fff !important;
            }
        }

        &_content {
            padding: vw(20) 0 0;
        }

        &_head {
            color: #fff;
            text-align: center;
            line-height: 1.5;
            margin: 30px 0;
            font-size: vw(35);

            @include pc() {
                margin-bottom: 40px;
                font-size: max(vw(10), 26px);
                margin: 30px 0;
            }
        }

        &_inner {
            justify-content: space-between;
            padding: vw(30) 0;

            @include sp() {
                display: block;
            }
        }


        &_item {
            position: relative;
            -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
            background-color: #FFF3D7;
            padding: 15px 10px;

            @include pc() {
                width: calc(48% - 8px);
                padding: 15px 15px 10px;
            }

            p {
                color: #333;
                text-align: center;
                font-size: min(vw(11), 16px);
                line-height: 1.5;

                @include sp() {
                    font-size: vw(20);
                }

                @include mini() {
                    font-size: vw(25);
                }

            }

            // &:nth-child(2) {
            //   margin-left: vw(60);
            // }

            @include sp() {
                &+.archive-contact_item {
                    margin-top: vw(100);
                }
            }

            @include small() {
                &+.archive-contact_item {
                    margin-top: vw(150);
                }
            }
        }

        &_btn_txt {
            position: absolute;
            top: -35px;
            background-color: #fff;
            font-weight: bold;
            display: block;
            width: 95%;
            font-size: max(1.0666666667vw, 16px);
            padding: 10px;
            filter: drop-shadow(2px 4px 5px rgba(0, 0, 0, 0.3));


            &::before {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -20px;
                border: 12px solid transparent;
                border-top: 18px solid #fff;
            }

            @include sp() {
                top: vw(-38);
                left: 15px;
            }

            @include small() {
                top: vw(-28);
                left: 10px;
                padding: 5px 10px;

                &::before {
                    border: 10px solid transparent;
                    border-top: 14px solid #fff;
                    left: 52%;
                }
            }

            @include mini() {
                top: vw(-50);
            }

        }

        &_btn {
            margin: 20px auto 10px;
            max-width: 500px;

            a {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 60px;
                border-radius: 20px;
                -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
                box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
                font-size: vw(25);
                font-weight: bold;
                color: #333 !important;

                &::after {
                    content: '';
                    width: 8px;
                    height: 8px;
                    border: 0;
                    border-top: solid 2px #333;
                    border-right: solid 2px #333;
                    position: absolute;
                    top: 50%;
                    right: 30px;
                    margin-top: -4px;
                    transform: rotate(45deg);

                    @include small() {
                        width: 6px;
                        height: 6px;
                        right: 10px;
                    }
                }

                @include pc() {
                    font-size: min(vw(12), 22px);
                }

                @include small() {
                    font-size: vw(35);
                    height: 40px;
                }
            }
        }
    }

    &-accordion {

        padding-bottom: vw(60);

        &_inner {
            position: relative;
            padding: 60px 0 20px;
            background-color: #F9F9F9;
            margin: vw(70) auto 0;

            @include pc() {
                max-width: 980px;
                margin: vw(40) auto 0;
            }

            &::before {
                position: absolute;
                bottom: -70%;
                left: -60%;
                width: 500px;
                height: 604px;
                background: url(../images/service_accordionimg.png) no-repeat left top;
                background-size: 450px auto;
                content: "";
                z-index: 1;

                @include sp() {
                    bottom: -50%;
                    left: -70%;
                    width: 350px;
                    height: 450px;
                    background-size: 350px auto;
                }

                @include small() {
                    bottom: -45%;
                    left: -80%;
                    width: 250px;
                    height: 350px;
                    background-size: 250px auto;
                }
            }
        }

        &_container {
            max-width: 90%;
            margin: 50px auto;

            @include pc() {
                max-width: 80%;
            }
        }

        &-list {
            margin-bottom: 40px;
            padding: 0 20px;
            line-height: 1.5;
        }

        &_title {
            position: relative;
            font-size: vw(25);
            font-weight: bold;
            padding: 30px 10px 30px 25px;
            background-color: #fff;

            @include pc() {
                font-size: 2rem;
                padding: 30px;
            }

            &::before {
                position: absolute;
                content: 'Q';
                font-size: vw(25);
                left: 0px;

                @include pc() {
                    font-size: 2rem;
                }
            }

            &::after {
                position: absolute;
                display: block;
                content: '';
                top: 40%;
                right: 0px;
                border: 10px solid transparent;
                border-top: 12px solid $ttl;
                transition: all .3s ease-in-out;

                @include sp() {
                    right: 0;
                }
            }

        }

        &_text {
            display: none;
            padding: 20px 20px;
            border-top: 1px solid #333;
        }
    }
}