@use "../global"as *;

.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;


  &-inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    padding: 20px 30px 20px 10px;

    @include pc() {
      padding: 30px 30px;
      height: 120px;
    }
  }

  &-logo {
    width: 200px;
    height: 50px;
    margin-right: auto;
    margin-left: 0;
    display: flex;
    align-items: center;


    @include small() {
      width: 160px;
    }
  }


  &_nav {

    &igation {}

    &List {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: 20px;

      @include pc {
        white-space: nowrap;
      }
    }

    &item {
      padding-right: 30px;

      a {
        font-size: max(vw(8), 12px);
      }

      &:last-child {
        padding-right: 0;
      }

      &.menu_item {
        &-help {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 20px;
            font-weight: bold;
          }

        }

        &-contact {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 20px;
            font-weight: bold;
            color: #fff;
            border-radius: 30px;
          }

        }
      }
    }
  }

  &-btn {
    position: relative;
    width: 40px;
    height: 44px;
    cursor: pointer;
    transition: all .5s;
    z-index: 10;

    @include pc() {
      width: 40px;
      height: 44px;
    }

    span {
      display: block;
      width: 100%;
      height: 2px;
      background: #000;
      position: absolute;
      transition: all .5s;

      &:nth-of-type(1) {
        top: 10px;
      }

      &:nth-of-type(2) {
        top: 45%;
        transform: translateY(-50%);
      }

      &:nth-of-type(3) {
        bottom: 33%;
      }
    }

    &.active {
      span {
        background: #333;

        &:nth-of-type(1) {
          top: -7px;
          -webkit-transform: translateY(20px) rotate(-45deg);
          transform: translateY(20px) rotate(-45deg);
        }

        &:nth-of-type(2) {
          left: 50%;
          opacity: 0;
          -webkit-animation: active-btn05-bar02 .8s forwards;
          animation: active-btn05-bar02 .8s forwards;
        }



        &:nth-of-type(3) {
          bottom: 20%;
          --webkit-transform: translateY(-20px) rotate(45deg);
          transform: translateY(-20px) rotate(45deg);
        }

      }
    }

    // &_inner {
    //   width: 25px;
    //   height: 17px;
    //   position: relative;

    //   @include pc() {
    //     width: 30px;
    //     height: 20px;
    //   }

    // }
  }

  &-nav {
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    color: #000;
    background: #fff;
    text-align: center;
    // -webkit-transform: translateX(100%);
    // transform: translateX(100%);
    opacity: 0;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
    width: 100%;
    height: 100vh;
    visibility: hidden;

    // @include pc() {
    //   right: -444px;
    // }

    &.active {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      opacity: 1;
      transition: 0.8s;
      visibility: visible;
    }
  }

  &-menu {
    width: 100%;
    margin-right: 0;
    margin-left: auto;
    padding-top: 100px;


    @include small() {
      padding: 100px 13.3333333333vw 0;
    }

    &_item {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      color: #333;

      @include small() {
        justify-content: flex-start;
      }

      &:first-of-type,
      &:last-of-type {
        .header-menu_link {
          p {
            color: rgba($color: #fff, $alpha: 0.3);
            line-height: 1;
            margin-left: 2px;
            font-size: 1.3rem;
          }
        }
      }

      &:not(.border) {
        padding: 30px 0;
      }

      &:first-of-type {
        padding-bottom: 30px;
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
      }

      &:last-of-type {
        padding-top: 30px;
        border-top: 1px solid rgba($color: #fff, $alpha: 0.3);
      }

      // @for $i from 1 through 15 {
      //   &:nth-child(#{$i}) {
      //     transition-delay: 50ms * $i;
      //   }
      // }

      &.flex-box {
        padding: 0 13.3333333333vw 0;

        @include small() {
          padding: 0;
          display: block;
        }
      }

      &-btn {
        width: 48%;
        margin-top: 40px;

        @include small() {
          width: 100%;
        }

        a {
          font-weight: bold;

          color: #333;
          height: auto;
          padding: 10px 20px;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;

          @include small() {
            justify-content: flex-start;
          }
        }
      }


    }

    &_link {
      color: #333;
      font-size: 1.8rem;
      font-weight: 500;

      // opacity: 0;
      // transform: translate3d(0, 100%, 0);
      // transition: all .3s cubic-bezier(0.25, 0.1, 0.25, 1);

      // &.active {
      //   opacity: 1;
      //   transform: translate3d(0, 0, 0);
      // }

      p {
        margin-bottom: 10px;

        span {
          font-size: 1.2rem;
          padding: 4px 8px;
          border: 2px solid #fff;
        }
      }
    }
  }

  &-black-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-color: #000;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    cursor: pointer;

    &.active {
      opacity: 0.8;
      visibility: visible;
    }
  }
}

body.active {
  height: 100%;
  overflow: hidden;
}