@use "../global"as *;

.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.border-box {
  border-radius: 30px;
}


.radius {
  border-radius: 12px;
}


.view-all_btn {
  margin: 30px auto 50px;
  width: 100%;
  height: 40px;
  max-width: 180px;
  position: relative;
  z-index: 1;

  @include pc() {
    margin: 48px auto;
    height: 48px;
    width: 180px;
  }



  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    color: #333;
    position: relative;
    font-size: 16px;
    letter-spacing: 2px;
    padding: 0 48px 0 20px;
    transition: all 0.4s;

    &::before {
      content: "";
      position: absolute;
      right: 20px;
      width: 30px;
      height: 30px;
      background: url(../images/allow_btn.png) no-repeat left top;
      background-size: 30px auto;
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
    }

    &:hover {
      background-color: #fff;
      color: #333;
      opacity: 1;

      &::before {
        right: 9%;
        // background: #fff;
      }

      &::after {
        right: 1%;
        border-color: transparent transparent transparent #fff;
      }
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    color: #333;
    position: relative;
    font-size: 16px;
    letter-spacing: 2px;
    padding: 0 48px 0 20px;
    transition: all 0.4s;

    &::before {
      content: "";
      position: absolute;
      right: 20px;
      width: 30px;
      height: 30px;
      background: url(../images/allow_btn-bottom.png) no-repeat left top;
      background-size: 30px auto;
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
    }

    &:hover {
      background-color: #fff;
      color: #333;
      opacity: 1;

      &::before {
        right: 9%;
        // background: #fff;
      }

      &::after {
        right: 1%;
        border-color: transparent transparent transparent #fff;
      }
    }
  }

  &.service {
    a {
      &::before {
        content: "";
        position: absolute;
        right: 20px;
        width: 30px;
        height: 30px;
        background: url(../images/allow_btn02.png) no-repeat left top;
        background-size: 30px auto;
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
      }

      &:hover {
        background-color: #fff;
        color: #333;
        opacity: 1;

        &::before {
          right: 9%;
          // background: #fff;
        }

        &::after {
          right: 1%;
          border-color: transparent transparent transparent #fff;
        }
      }

    }
  }
}