@use "../global"as *;

.index {
  &-mv {
    position: relative;
    background-image: url(../images/background_mvsp.png);
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;

    @include pc() {
      background-image: url(../images/background_mv1.png);
      background-position: center;
    }


    &_wrap {
      height: vw(1200);

      @include pc() {
        height: vw(420);
      }

      @include small() {
        height: vw(1550);
      }

      @include mini() {
        height: 70vh;
      }
    }

    &-inner {
      position: relative;
      justify-content: center;
      flex-flow: column;
      padding-top: 90px;

      @include pc() {
        height: inherit;
        padding: 0.6666666667vw 0;
        flex-flow: row;
        margin-top: 0;

        &::after {
          position: absolute;
          top: 56%;
          width: 80%;
          height: 50px;
          background: url(../images/border_home.png) no-repeat center;
          background-size: 100% auto;
          content: "";
          z-index: 1;
        }
      }


    }



    &-ttl {
      // @include sippori;
      position: relative;
      text-align: left;
      line-height: 1.5;
      padding-top: 80px;
      width: 100%;
      padding: vw(80) vw(80) 0;

      @include pc() {
        width: 38%;
        padding: 120px 0 0;
        margin-right: 50px;
        border: none;

        &::before {
          content: none;
        }
      }

      // @include pc-plus() {
      //   width: 45%;
      // }

      h1 {
        font-size: vw(50);
        padding-bottom: 10px;

        @include pc() {
          padding-bottom: 20px;
          font-size: vw(25);
        }
      }

      p {
        position: relative;
        font-size: 1.4rem;
        padding-top: 20px;
        border-top: 5px solid #333;

        &::before {
          position: absolute;
          content: '';
          width: 50%;
          left: 0;
          top: -5px;
          border-top: 6px solid $color-yellow;
        }

        @include pc() {
          border-top: none;
          font-size: vw(7);

          &::before {
            content: none;
          }
        }

        @include small() {
          padding-top: vw(10);
          font-size: vw(20);
        }
      }
    }

    &_img {
      position: relative;
      width: vw(500);
      margin-bottom: vw(40);
      z-index: 10;

      @include pc() {
        width: 30%;
        margin-bottom: 0;
      }

      @include small() {
        margin-bottom: vw(60);
      }
    }

    &_btncontainer {
      margin-top: 30px;

      &.pc {
        @include sp() {
          display: none;
        }
      }

      &.sp {
        @include sp() {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 0;
          padding: 0 vw(100) 0;
        }

        @include small() {
          display: block;
          padding: 0 vw(100) 0;
        }
      }

      @include pc() {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    &_btn {
      -webkit-box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);

      @include sp() {
        width: 48%;
      }


      @include small() {
        width: 100%;
      }

      a {
        font-weight: bold;
        font-size: max(vw(28), 12px);
        color: $ttl;
        // padding: 8px 25px;
        height: auto;
        padding: 15px 0;
        justify-content: center;

        @include pc() {
          font-size: max(vw(7), 20px);
          width: 220px;
          height: 50px;
        }
      }

      +.index-mv_btn {
        @include pc() {
          margin-left: 40px;
        }

        @include small() {
          margin-top: vw(30);
        }
      }
    }
  }

  &-concept {
    position: relative;
    background-color: #F9F9F9;
    padding: 60px 0 vw(220);
    z-index: 1;

    @include pc() {
      padding: 100px 0;
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      border-style: solid;
      bottom: 0;
      border-color: transparent #fff transparent;
      border-width: 20vw 50vw 0;

      @include pc() {
        border-width: 8vw 50vw 0;
      }
    }

    &_imgBox {
      margin-top: vw(40);

      @include pc() {
        margin-top: 60px;
      }
    }

    &_img {
      img {
        max-width: 500px;
        margin: 0 auto;

        @include pc() {
          width: 700px;
        }
      }
    }

    &-body {
      display: block;
      position: relative;

      // &::after {
      //   position: absolute;
      //   right: -65px;
      //   bottom: 130px;
      //   width: 149px;
      //   height: 100px;
      //   background: url(../images/dots.png) no-repeat left top;
      //   background-size: 149px auto;
      //   content: "";
      //   z-index: 1;
      // }

      @include pc() {
        text-align: center;
        margin-top: vw(30);
      }

      p {
        font-size: vw(30);
        margin-top: vw(30);

        @include pc() {
          font-size: 2rem;
        }
      }
    }
  }


  &-service {
    padding: vw(50) 0 vw(80);
    position: relative;
    z-index: 1;

    @include pc() {
      padding: vw(50) 0 0;
    }

    &_content {
      margin-top: vw(120);

      @include pc() {
        margin-top: 0;
      }
    }

    &_list {
      @include pc() {
        margin: vw(30) 0;
      }
    }

    &_inner {
      position: relative;
      justify-content: flex-end;

      @include sp() {
        flex-flow: column;
      }
    }

    &_item {
      position: relative;
      background: rgb(249, 249, 249);

      @include pc() {
        padding: vw(30) 0 vw(10);
        background: linear-gradient(270deg, rgb(249, 249, 249) 30%, rgb(249, 249, 249) 65%, rgba(255, 255, 255, 0) 60%);
      }



      &:first-child {
        .index-service_inner {

          &::before {
            position: absolute;
            top: 5%;
            left: -25%;
            width: 400px;
            height: 354px;
            background: url(../images/background_service01.png) no-repeat left top;
            background-size: 400px auto;
            content: "";

            @include sp() {
              top: 75%;
              left: -35%;
            }

            @include small() {
              top: 70%;
              left: -40%;
              width: 300px;
              height: 454px;
              background-size: 300px auto;
            }

            @include mini() {
              content: none;
            }
          }

          &::after {
            position: absolute;
            top: -220%;
            right: -35%;
            width: 500px;
            height: 700px;
            background: url(../images/background_service1.png) no-repeat left top;
            background-size: 500px auto;
            content: "";

            @include sp() {
              top: -80%;
              width: 400px;
              height: 600px;
              background-size: 400px auto;
            }

            @include small() {
              top: -80%;
              width: 300px;
              height: 500px;
              background-size: 300px auto;
            }

            @include mini() {
              content: none;
            }

          }
        }
      }

      &:nth-child(2n) {
        margin: vw(120) 0;

        @include pc() {
          margin: vw(30) 0;
        }

        @include pc() {
          background: linear-gradient(90deg, rgb(249, 249, 249) 30%, rgb(249, 249, 249) 70%, rgba(255, 255, 255, 0) 60%);
        }

        .index-service_inner {
          flex-flow: column;

          @include pc() {
            flex-flow: row-reverse;
          }
        }

        .index-service_img {
          @include pc() {
            width: 35%;
          }
        }

        .index-service_textBox {
          padding-left: 0;

          @include pc() {
            padding-right: vw(20);
          }
        }
      }

      &:last-child {
        .index-service_inner {
          &::before {
            position: absolute;
            top: 10%;
            left: -30%;
            width: 450px;
            height: 704px;
            background: url(../images/background_service03.png) no-repeat left top;
            background-size: 450px auto;
            content: "";
            z-index: 1;

            @include sp() {
              left: -60%;
            }

            @include small() {
              top: 60%;
              left: -45%;
              width: 300px;
              height: 504px;
              background-size: 300px auto;
            }

            @include mini() {
              content: none;
            }
          }
        }
      }


    }


    &_img {
      position: relative;
      width: 80%;
      margin-top: -40px;
      z-index: 1;

      @include pc() {
        width: 30%;
      }
    }

    &_textBox {
      position: relative;
      margin-top: vw(20);
      z-index: 1;

      @include pc() {
        padding-left: vw(30);
        width: 65%;
        margin-top: 0;
      }

      .number {
        font-weight: bold;
        font-size: max(vw(60), 6rem);
        display: block;
        margin-bottom: 10px;

        @include pc() {
          font-size: max(vw(20), 5rem);
        }
      }

      p {
        font-size: vw(30);

        @include pc() {
          font-size: 2rem;
        }
      }
    }


    &_ttl {
      margin: vw(10) 0 vw(18);
      font-size: max(vw(30), 20px);

      @include pc() {
        margin: vw(5) 0 vw(10);
        font-size: 3rem;
      }
    }


    &_slider {
      &-item {
        position: relative;

        a {
          color: #fff;
          display: block;
        }
      }

      &_cont {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(30%, rgba(0, 0, 0, .1)), to(rgba(0, 0, 0, .6)));
        background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .1) 30%, rgba(0, 0, 0, .6));
        padding: 88px 24px 24px;

      }

      &_ttl {
        padding-bottom: 20px;
        margin-bottom: 20px;

        &::before {
          content: "";
          display: block;
          width: 56px;
          height: 1px;
          background: #fff !important;
          position: absolute;
          top: unset !important;
          left: 0 !important;
          bottom: 0 !important;
          opacity: 1;
          -webkit-transition: .4s;
        }

        .en {
          color: #fff;

          @include pc {
            font-size: 24px !important;
            letter-spacing: 3px;
            margin-bottom: 12px;
            font-weight: lighter;
            text-transform: uppercase;
          }
        }
      }

      &-txt {
        font-size: 11px;
        color: #fff;
        line-height: 1.5;
      }
    }
  }

  &-works {
    padding: vw(60) 0;

    @include pc() {
      padding: vw(20) 0 vw(10);
    }

    .ttl {
      margin-bottom: vw(40);

      @include pc() {
        margin-bottom: 40px;
      }
    }

    .sub_ttl {
      @include sp() {
        font-size: max(vw(20), 16px);
      }
    }

    &_loopSlide {
      position: relative;
      display: flex;
      overflow: hidden;
      padding: vw(20) 0 0;
      z-index: 1;

      ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        animation: infinity-scroll-right 20s infinite linear .5s both;

        li {
          width: calc(100vw / 3);

          @include pc {
            width: calc(100vw / 8);
          }

          padding-right: vw(15);

          img {
            display: block;
            width: 100%;
            height: auto;

            @include pc() {
              object-fit: cover;
            }
          }

        }
      }

      @keyframes infinity-scroll-right {
        from {
          transform: translateX(-100%);
        }

        to {
          transform: translateX(0%);
        }
      }

      &:nth-of-type(2) {

        ul {
          margin-left: 40px;

          &:nth-of-type(2) {
            margin-left: 0;
          }

          &:last-child {
            margin-left: 0;
          }
        }
      }

    }

    &_categories {
      margin-top: vw(50);

      .inner {
        display: flex;

        @include pc() {
          justify-content: flex-start;
        }

        @include sp() {
          flex-wrap: wrap;
          justify-content: center;
        }

        @include small() {
          display: block;
        }
      }

      @include small() {
        margin-top: vw(80);
      }
    }

    &_tab_btn {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 5rem;
      border: 0.1rem solid $color-yellow;
      background-color: #fff;
      transition: all .2s;
      cursor: pointer;
      font-size: max(vw(28), 12px);
      font-weight: bold;

      @include pc() {
        font-size: max(vw(7), 20px);
        width: 20%;
      }

      @include sp() {
        width: 46%;

        &:nth-child(2n) {
          margin-left: 20px;
        }
      }

      @include small() {
        width: 100%;

        &:nth-child(2n) {
          margin-left: 0px;
        }


      }

      &:not(:last-of-type) {
        @include pc() {
          margin-right: 1em;
        }

        @include sp() {
          margin-bottom: 26px;
        }
      }

      &.active {
        color: #333;
        background-color: $color-yellow;
        border: 1px solid $color-yellow;
      }


    }

    &_inner {
      position: relative;
      margin-top: vw(20);
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      @include small() {
        margin-top: vw(100);
        display: block;
      }

      &::before {
        position: absolute;
        bottom: -50%;
        left: -50%;
        width: 500px;
        height: 604px;
        background: url(../images/service_accordionimg.png) no-repeat left top;
        background-size: 450px auto;
        content: "";

        @include sp() {
          bottom: -50%;
          left: -70%;
          width: 350px;
          height: 450px;
          background-size: 350px auto;
        }

        @include small() {
          bottom: -45%;
          left: -80%;
          width: 250px;
          height: 350px;
          background-size: 250px auto;
        }

        @include mini() {
          content: none;
        }
      }

    }

    &_card {
      height: inherit;
      background: #fff;
      position: relative;
      padding-bottom: 40px;
      margin: 0 0 vw(120);
      // box-shadow: 0 2px 8px rgba(0, 0, 0, .05);
      width: calc(50% - 10px);

      @include pc() {
        padding: 0;
        width: calc(33.333% - 25px);

        +.index-works_card {
          margin-left: 20px;
        }

        &:nth-child(n+4) {
          margin-bottom: vw(80);
        }

        &:nth-child(4n) {
          margin-left: 0;
        }

      }

      @include sp() {
        margin: 0 0 vw(200);

        &:nth-child(2n) {
          margin-left: 20px;
        }
      }

      @include small() {
        width: 100%;
        margin: 0 0 vw(350);

        &:nth-child(2n) {
          margin-left: 0px;
        }
      }

      @include mini() {
        margin: 0 0 vw(400);
      }


      a {
        color: #333;

        &:hover {
          opacity: 1;
        }
      }

      &ImgBox {
        position: relative;
        overflow: hidden;
        display: block;
        width: 100%;

        &::before {
          content: "";
          display: block;
          width: 100%;
          padding-top: 70%;
        }



        @include pc() {
          // width: 50%;
        }

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          -o-object-position: center center;
          object-position: center center;
          transition: transform .5s, opacity .5s, -webkit-transform .5s;
        }

        &:hover {
          .index-works_cardImg {
            &::after {
              opacity: 1;
            }
          }

          img {
            transform: scale(1.1);
            opacity: 1;

          }

          .index-works_cardlogo {
            img {
              transform: scale(1);

            }
          }


        }
      }

      &logo {
        position: absolute;
        top: 0;
        right: 0;
        width: 80px;
        height: 80px;

      }

      &Desc {
        padding: 15px vw(10);
        position: absolute;
        width: 95%;
        left: 2.5%;
        top: 65%;
        background: #fff;
        height: inherit;
        -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
        box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
        border-radius: 20px;

        @include sp() {
          padding: 15px vw(25);
        }


        @include small() {
          padding: 15px vw(30);
        }


        .index-works_cardTtl {
          font-size: min(vw(14), 2.6rem);
          letter-spacing: 2px;
          color: #444;
          line-height: 1.5;
          width: 100%;

          @include pc() {
            font-size: min(vw(9), 1.8rem);
          }

          @include small() {
            font-size: min(vw(30), 2.6rem);
          }
        }


        .index-works_cardcont {
          color: #666;
          letter-spacing: 2px;
          font-size: min(vw(11), 2rem);
          line-height: 1.5;
          text-transform: uppercase;
          margin-top: vw(5);
          border-bottom: 1px solid #eee;
          padding-bottom: 20px;

          @include pc() {
            font-size: min(vw(8), 16px);
          }

          @include small() {
            font-size: min(vw(25), 2rem);
          }
        }

        .index-works_cardname {
          padding: 10px 0 0;
          font-size: min(vw(14), 2.4rem);

          @include pc() {
            padding: 15px 0 0;
            font-size: min(vw(8), 18px);
          }

          @include small() {
            font-size: min(vw(23), 2.4rem);
          }

        }
      }
    }

  }


  &-news {
    position: relative;
    padding: vw(100) 0 vw(80);
    // background: #F9F9F9;



    @include pc() {
      padding: vw(20) 0;
    }

    &_categories {
      margin-top: vw(50);
      display: flex;

      @include pc() {
        justify-content: flex-start;
      }

      @include sp() {
        flex-wrap: wrap;
        justify-content: center;
      }

      @include small() {
        display: block;
      }

    }

    &_tab_btn {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 5rem;
      border: 0.1rem solid $color-yellow;
      transition: all .2s;
      cursor: pointer;
      font-size: max(vw(28), 12px);
      font-weight: bold;

      @include pc() {
        font-size: max(vw(7), 20px);
        width: 20%;
      }

      @include sp() {
        width: 46%;

        &:nth-child(2n) {
          margin-left: 20px;
        }
      }

      @include small() {
        width: 100%;

        &:nth-child(2n) {
          margin-left: 0px;
        }


      }

      &:not(:last-of-type) {
        @include pc() {
          margin-right: 1em;
        }

        @include sp() {
          margin-bottom: 26px;
        }
      }

      &.active {
        color: #333;
        background-color: $color-yellow;
        border: 1px solid $color-yellow;
      }


    }

    &_content {
      position: relative;
      margin-top: vw(20);
      // background-color: #fff;
      border-radius: 20px;
      // padding: vw(30) vw(30) vw(10);

      @include pc() {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        // max-width: 1000px;
        // margin: vw(40) auto 0;
        // padding: vw(30) vw(50) vw(10);
      }

      &-item {

        a {
          display: block;
          padding: 25px 10px;
          text-decoration: none;
          color: #444;
          position: relative;

          @include pc() {
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 6px 0;
            margin: 6px 0;
          }

          // &::before {
          //   content: "";
          //   position: absolute;
          //   right: 0;
          //   top: 50%;
          //   width: max(vw(30), 30px);
          //   height: 1px;
          //   background: #000;
          //   -webkit-transition: all 0.4s;
          //   transition: all 0.4s;

          //   @include pc {
          //     right: 20px;
          //     width: 5%;
          //   }
          // }

          // &::after {
          //   content: "";
          //   display: inline-block;
          //   position: absolute;
          //   right: vw(-18);
          //   top: 45%;
          //   height: 1px;
          //   width: 10px;
          //   border-color: transparent transparent transparent #000;
          //   border-style: solid;
          //   border-width: 4px 0 0 7px;
          //   transition: all 0.4s;

          //   @include pc {
          //     right: 8px;
          //     top: 13px;
          //   }

          //   @include mini {
          //     right: vw(-30);
          //   }
          // }

          // &:hover {
          //   opacity: 1;

          //   &::before {
          //     right: 5px;

          //     @include sp {
          //       right: -5px;
          //     }
          //   }

          //   &::after {
          //     right: -5px;


          //     @include sp {
          //       right: -17px;
          //     }
          //   }
          // }


        }

        &Category {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 4rem;
          border: 0.1rem solid $color-yellow;
          transition: all .2s;
          cursor: pointer;
          font-size: vw(15);
          max-width: 160px;

          @include pc() {
            font-size: 1.6rem;
            width: 18%;
            margin-right: vw(15);
          }

          @include small() {
            font-size: vw(30);
            height: 3rem;
          }
        }

        &Date {
          letter-spacing: 1px;
          font-weight: 700;
          color: #aaa;
          line-height: 1.5;
          font-size: vw(15);
          width: auto;
          font-family: Heebo, sans-serif;
          display: block;
          margin-bottom: 10px;

          @include pc() {
            font-size: 1.6rem;
            width: 15%;
          }

          @include small() {
            font-size: vw(30);
          }
        }

        &Ttl {
          font-weight: 400;
          margin-right: auto;
          line-height: 1.5;
          font-size: vw(18);

          @include pc() {
            padding: 20px 0;
            font-size: 2rem;
            text-overflow: ellipsis;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
          }

          @include small() {
            font-size: vw(35);
          }

          &:hover {
            text-decoration: underline;
          }

          .headline {
            font-weight: bold;
          }

          .desc {
            line-height: 1.5;
          }
        }

        &:nth-child(2) {
          border-top: 1px solid #e6e6e6;
          border-bottom: 1px solid #e6e6e6;
        }

        &:nth-child(3) {
          border-bottom: 1px solid #e6e6e6;
        }
      }

      &::after {
        position: absolute;
        bottom: -50%;
        right: -40%;
        width: 400px;
        height: 600px;
        background: url(../images/service_newsimg.png) no-repeat left top;
        background-size: 400px auto;
        content: "";

        @include sp() {
          content: none;
          // bottom: -60%;
          // right: -45%;
          // width: 300px;
          // height: 500px;
          // background-size: 300px auto;
        }

        @include small() {
          bottom: -50%;
          right: -35%;
          width: 200px;
          height: 400px;
          background-size: 200px auto;
        }
      }
    }

  }

  &-contact {
    margin-top: 80px;
    position: relative;
    z-index: 1;

    @include sp() {
      padding: vw(40) 0;
    }

    &_title {
      text-align: center;
      padding: vw(20) 0;
      color: #fff !important;

      &::before {
        background: #fff !important;
      }
    }

    &_content {
      padding: vw(20) 0 0;
    }

    &_head {
      color: #fff;
      text-align: center;
      line-height: 1.5;
      margin: 30px 0;
      font-size: vw(35);

      @include pc() {
        margin-bottom: 40px;
        font-size: max(vw(10), 26px);
        margin: 30px 0;
      }
    }

    &_inner {
      justify-content: space-between;
      padding: vw(30) 0;

      @include sp() {
        display: block;
      }
    }


    &_item {
      position: relative;
      -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
      background-color: #FFF3D7;
      padding: 15px 10px;

      @include pc() {
        width: calc(48% - 8px);
        padding: 15px 15px 10px;
      }

      p {
        color: #333;
        text-align: center;
        font-size: min(vw(11), 16px);
        line-height: 1.5;

        @include sp() {
          font-size: vw(20);
        }

        @include mini() {
          font-size: vw(25);
        }

      }

      // &:nth-child(2) {
      //   margin-left: vw(60);
      // }

      @include sp() {
        &+.index-contact_item {
          margin-top: vw(100);
        }
      }

      @include small() {
        &+.index-contact_item {
          margin-top: vw(150);
        }
      }
    }

    &_btn_txt {
      position: absolute;
      top: -35px;
      background-color: #fff;
      font-weight: bold;
      display: block;
      width: 95%;
      font-size: max(1.0666666667vw, 16px);
      padding: 10px;
      filter: drop-shadow(2px 4px 5px rgba(0, 0, 0, 0.3));


      &::before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -20px;
        border: 12px solid transparent;
        border-top: 18px solid #fff;
      }

      @include sp() {
        top: vw(-38);
        left: 15px;
      }

      @include small() {
        top: vw(-28);
        left: 10px;
        padding: 5px 10px;

        &::before {
          border: 10px solid transparent;
          border-top: 14px solid #fff;
          left: 52%;
        }
      }

      @include mini() {
        top: vw(-50);
      }

    }

    &_btn {
      margin: 20px auto 10px;
      max-width: 500px;

      a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        border-radius: 20px;
        -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
        box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
        font-size: vw(25);
        font-weight: bold;
        color: #333 !important;

        &::after {
          content: '';
          width: 8px;
          height: 8px;
          border: 0;
          border-top: solid 2px #333;
          border-right: solid 2px #333;
          position: absolute;
          top: 50%;
          right: 30px;
          margin-top: -4px;
          transform: rotate(45deg);

          @include small() {
            width: 6px;
            height: 6px;
            right: 10px;
          }
        }

        @include pc() {
          font-size: min(vw(12), 22px);
        }

        @include small() {
          font-size: vw(35);
          height: 40px;
        }
      }
    }
  }
}