@use "../global"as *;

.back-yellow {
    background-color: $color-yellow;
}

.back-black {
    background-color: $ttl;
}

.back-yellow_fresh {
    background-color: $color-fresh;
}

.back-white {
    background-color: $white;
}

.back-blue {
    background-color: $color-blue;
}

.back-green {
    background-color: $color-green;
}


.color-yellow {
    color: $color-yellow;
}

.color-black {
    color: $ttl;
}

.color-fresh {
    color: $color-fresh;
}

.color-white {
    color: $white;
}