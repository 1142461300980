@use "../global"as *;

.document {
    position: relative;

    &-mv {
        position: relative;
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;

        @include pc() {
            background-image: url(../images/background-contact_mv.png);
            background-position: -200px -50px;
            background-size: auto;
        }

        @include sp() {
            background-image: url(../images/background_mvsp.png);
            height: vw(1200);
        }

        @include small() {
            height: vw(1550);
        }

        @include mini() {
            height: 50vh;
        }

        &_wrap {
            height: vw(1200);

            @include pc() {
                height: vw(420);
            }

            @include small() {
                height: vw(1550);
            }

            @include mini() {
                height: 60vh;
            }
        }
    }

    &-form {
        position: relative;
        margin-top: vw(-1000);
        z-index: 10;

        @include pc() {
            margin-top: vw(-350);

        }

        @include small() {
            margin-top: vw(-1400);
            padding: vw(60) 0;
        }

        @include mini() {
            margin-top: -38vh;
        }

        // &::before {
        //     position: absolute;
        //     bottom: 0%;
        //     left: -5%;
        //     width: 500px;
        //     height: 604px;
        //     background: url(../images/background_service01.png) no-repeat left top;
        //     background-size: 450px auto;
        //     content: "";

        //     @include sp() {
        //         bottom: 0%;
        //         left: -5%;
        //         width: 350px;
        //         height: 450px;
        //         background-size: 350px auto;
        //     }

        //     @include small() {
        //         bottom: -0%;
        //         left: -30%;
        //         width: 250px;
        //         height: 350px;
        //         background-size: 250px auto;
        //     }

        //     @include mini() {
        //         content: none;
        //     }
        // }

        @include pc() {
            padding: vw(40) 0 vw(20);
        }

        &_inner {
            position: relative;
            align-items: flex-start;
            margin-top: vw(40);

            @include sp() {
                display: block;
            }
        }

        .ttl {
            margin-bottom: vw(40);

            @include pc() {
                margin-bottom: 40px;
            }
        }

        .sub_ttl {
            @include sp() {
                font-size: max(vw(20), 10px);
            }
        }

        &-left {
            flex: 0 0 48%;

            p {
                font-size: vw(30);
                font-weight: bold;

                @include pc() {
                    font-size: max(vw(6), 18px);
                }
            }
        }

        &-right {
            flex: 0 0 48%;

            @include sp() {
                margin-top: vw(80);
            }
        }

        &_img {
            display: block;
            max-width: 250px;
            margin: 40px auto 0;
        }

        table {
            width: 100%;
            margin-bottom: 60px;
        }

        table th,
        table td {
            float: left;
            width: 100%;
            padding: 0 0 25px;
            box-sizing: border-box;
            text-align: left;
        }

        .must {
            background-color: #e90606;
            color: #fff;
            margin: 0 0 0 1em;
            font-size: 1rem;
            padding: 5px 10px;
            border-radius: 10px;
        }

        &NameList {
            display: flex;
            justify-content: space-between;

            p {
                width: 48%;
            }
        }

        td input[type="text"],
        td input[type="email"],
        td input[type="tel"],
        td textarea {
            height: 58px;
            font-size: 1.2rem;
            padding: 5px 10px 5px 10px;
            border: 1px solid #404040;
        }

        td input[type="text"],
        td input[type="email"],
        td input[type="tel"],
        td input[type="number"],
        td select,
        td textarea {
            color: #333;
            width: 100%;
            height: 45px;
            font-size: 1.3rem;
            padding: 5px 10px 5px 10px;
            -webkit-appearance: none;
            box-sizing: border-box;
            border: 1px solid #A4A4A4;
        }


    }


    .document-form_btn input {
        width: 100%;
        border: none;
        cursor: pointer;
        color: #333 !important;
        border-radius: 80px;
        padding: 15px 32px;
        margin-bottom: 24px;
        -webkit-appearance: none;
        appearance: none;
        letter-spacing: .1em;
        transition: all .4s ease;
        color: transparent;
        font-size: 18px;
    }

    &-contact {
        margin-top: 80px;
        position: relative;
        z-index: 1;

        @include sp() {
            padding: vw(40) 0;
        }

        &_title {
            text-align: center;
            padding: vw(20) 0;
            color: #fff !important;

            &::before {
                background: #fff !important;
            }
        }

        &_content {
            padding: vw(20) 0 0;
        }

        &_head {
            color: #fff;
            text-align: center;
            line-height: 1.5;
            margin: 30px 0;
            font-size: vw(35);

            @include pc() {
                margin-bottom: 40px;
                font-size: max(vw(10), 26px);
                margin: 30px 0;
            }
        }

        &_inner {
            justify-content: space-between;
            padding: vw(30) 0;

            @include sp() {
                display: block;
            }
        }


        &_item {
            position: relative;
            -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
            background-color: #FFF3D7;
            padding: 15px 10px;

            @include pc() {
                width: calc(48% - 8px);
                padding: 15px 15px 10px;
            }

            p {
                color: #333;
                text-align: center;
                font-size: min(vw(11), 16px);
                line-height: 1.5;

                @include sp() {
                    font-size: vw(20);
                }

                @include mini() {
                    font-size: vw(25);
                }

            }

            // &:nth-child(2) {
            //   margin-left: vw(60);
            // }

            @include sp() {
                &+.single-contact_item {
                    margin-top: vw(100);
                }
            }

            @include small() {
                &+.single-contact_item {
                    margin-top: vw(150);
                }
            }
        }

        &_btn_txt {
            position: absolute;
            top: -35px;
            background-color: #fff;
            font-weight: bold;
            display: block;
            width: 95%;
            font-size: max(1.0666666667vw, 16px);
            padding: 10px;
            filter: drop-shadow(2px 4px 5px rgba(0, 0, 0, 0.3));


            &::before {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -20px;
                border: 12px solid transparent;
                border-top: 18px solid #fff;
            }

            @include sp() {
                top: vw(-38);
                left: 15px;
            }

            @include small() {
                top: vw(-28);
                left: 10px;
                padding: 5px 10px;

                &::before {
                    border: 10px solid transparent;
                    border-top: 14px solid #fff;
                    left: 52%;
                }
            }

            @include mini() {
                top: vw(-50);
            }

        }

        &_btn {
            margin: 20px auto 10px;
            max-width: 500px;

            a {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 60px;
                border-radius: 20px;
                -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
                box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
                font-size: vw(25);
                font-weight: bold;
                color: #333 !important;

                &::after {
                    content: '';
                    width: 8px;
                    height: 8px;
                    border: 0;
                    border-top: solid 2px #333;
                    border-right: solid 2px #333;
                    position: absolute;
                    top: 50%;
                    right: 30px;
                    margin-top: -4px;
                    transform: rotate(45deg);

                    @include small() {
                        width: 6px;
                        height: 6px;
                        right: 10px;
                    }
                }

                @include pc() {
                    font-size: min(vw(12), 22px);
                }

                @include small() {
                    font-size: vw(35);
                    height: 40px;
                }
            }
        }
    }

}