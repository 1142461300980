/*///////////////////////////////

variable

/////////////////////////////*/

/*-----------------------------
ブレークポイント
-----------------------------*/
$w-breakpoint-tab: 1200;
$w-breakpoint-sp: 868;

//最大幅
$w-max: 1920px;

//最大幅（inner）左右padding
$w-padding-rl: 20px;

/*-----------------------------
color
-----------------------------*/

$main: #1e1e1e;
$color-yellow: #FFC700;
$color-fresh: #F4F403;
$color-blue: #38AFFE;
$color-green: #00CC7F;
$ttl: #333;
$white: #fff;
$blue: #193285;
$blue-light: #f1f7fc;