@use "../global"as *;

.inner {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: vw(5) 0;


  &.w-1200 {
    padding-right: vw(90);
    padding-left: vw(90);

    @include pc {
      padding-right: vw(40);
      padding-left: vw(40);
    }

    max-width: calc(1200px + 40px);
  }

  &.w-1400 {
    max-width: 1400px;
  }
}