/*///////////////////////////////

bace

/////////////////////////////*/
///
@use "../global"as *;

/*-----------------------------
font
-----------------------------*/
// @font-face {
//   font-family: "genjuu";
//   src: url("../font/GenJyuuGothicX-Heavy.ttf") format("truetype");
// }

/*-----------------------------
bace
-----------------------------*/
html {
  scroll-behavior: smooth;
}

header,
main,
footer {
  @include gothic;
  font-weight: 400;
  color: $main;
  font-size: 1.5rem;
}

main {
  overflow: hidden;
}

p {
  line-height: 2.2;
}

a {
  transition: 0.2s;

  &:hover {
    opacity: 0.7;
  }
}

.wrap {
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}