@use "../global"as *;

#aws {
    .service {

        &-mv {
            &-inner {
                @include pc() {
                    &::after {
                        position: absolute;
                        top: 47%;
                        width: 80%;
                        height: 50px;
                        background: url(../images/border_aws.png) no-repeat center;
                        background-size: 100% auto;
                        content: "";
                    }
                }
            }
        }



        &-melit {
            &_ttl {
                &::before {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    margin-left: -20px;
                    border: 18px solid transparent;
                    border-top: 23px solid $color-fresh;
                }
            }
        }

        &-aws_partner {
            margin-top: vw(20);
            // height: 50px;

            @include pc() {
                // width: 500px;
                display: flex;
                align-items: center;
                width: 80%;
            }


            span {
                font-size: vw(22);

                @include pc() {
                    font-size: max(vw(8), 18px);
                }
            }
        }

        &-aws_img {
            display: inline-block;
        }
    }
}