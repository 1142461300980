@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

main {
  display: block;
}

html {
  font-size: 62.5%;
  overflow: auto;
}

* {
  box-sizing: border-box;
  -webkit-appearance: none;
  box-shadow: none;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

* {
  box-sizing: border-box;
  -webkit-appearance: none;
  box-shadow: none;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

/*///////////////////////////////

bace

/////////////////////////////*/
/*///////////////////////////////

variable

/////////////////////////////*/
/*-----------------------------
ブレークポイント
-----------------------------*/
/*-----------------------------
color
-----------------------------*/
/*///////////////////////////////

mixin

/////////////////////////////*/
/*-----------------------------
font
-----------------------------*/
/*-----------------------------
bace
-----------------------------*/
html {
  scroll-behavior: smooth;
}

header,
main,
footer {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  color: #1e1e1e;
  font-size: 1.5rem;
}

main {
  overflow: hidden;
}

p {
  line-height: 2.2;
}

a {
  transition: 0.2s;
}
a:hover {
  opacity: 0.7;
}

.wrap {
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
}
.header-inner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  padding: 20px 30px 20px 10px;
}
@media (min-width: 869px) {
  .header-inner {
    padding: 30px 30px;
    height: 120px;
  }
}
.header-logo {
  width: 200px;
  height: 50px;
  margin-right: auto;
  margin-left: 0;
  display: flex;
  align-items: center;
}
@media (max-width: 520px) {
  .header-logo {
    width: 160px;
  }
}
.header_navList {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 20px;
}
@media (min-width: 869px) {
  .header_navList {
    white-space: nowrap;
  }
}
.header_navitem {
  padding-right: 30px;
}
.header_navitem a {
  font-size: max(1.0666666667vw, 12px);
}
.header_navitem:last-child {
  padding-right: 0;
}
.header_navitem.menu_item-help a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-weight: bold;
}
.header_navitem.menu_item-contact a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-weight: bold;
  color: #fff;
  border-radius: 30px;
}
.header-btn {
  position: relative;
  width: 40px;
  height: 44px;
  cursor: pointer;
  transition: all 0.5s;
  z-index: 10;
}
@media (min-width: 869px) {
  .header-btn {
    width: 40px;
    height: 44px;
  }
}
.header-btn span {
  display: block;
  width: 100%;
  height: 2px;
  background: #000;
  position: absolute;
  transition: all 0.5s;
}
.header-btn span:nth-of-type(1) {
  top: 10px;
}
.header-btn span:nth-of-type(2) {
  top: 45%;
  transform: translateY(-50%);
}
.header-btn span:nth-of-type(3) {
  bottom: 33%;
}
.header-btn.active span {
  background: #333;
}
.header-btn.active span:nth-of-type(1) {
  top: -7px;
  -webkit-transform: translateY(20px) rotate(-45deg);
  transform: translateY(20px) rotate(-45deg);
}
.header-btn.active span:nth-of-type(2) {
  left: 50%;
  opacity: 0;
  -webkit-animation: active-btn05-bar02 0.8s forwards;
  animation: active-btn05-bar02 0.8s forwards;
}
.header-btn.active span:nth-of-type(3) {
  bottom: 20%;
  --webkit-transform: translateY(-20px) rotate(45deg);
  transform: translateY(-20px) rotate(45deg);
}
.header-nav {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  color: #000;
  background: #fff;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  width: 100%;
  height: 100vh;
  visibility: hidden;
}
.header-nav.active {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  opacity: 1;
  transition: 0.8s;
  visibility: visible;
}
.header-menu {
  width: 100%;
  margin-right: 0;
  margin-left: auto;
  padding-top: 100px;
}
@media (max-width: 520px) {
  .header-menu {
    padding: 100px 13.3333333333vw 0;
  }
}
.header-menu_item {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #333;
}
@media (max-width: 520px) {
  .header-menu_item {
    justify-content: flex-start;
  }
}
.header-menu_item:first-of-type .header-menu_link p, .header-menu_item:last-of-type .header-menu_link p {
  color: rgba(255, 255, 255, 0.3);
  line-height: 1;
  margin-left: 2px;
  font-size: 1.3rem;
}
.header-menu_item:not(.border) {
  padding: 30px 0;
}
.header-menu_item:first-of-type {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.header-menu_item:last-of-type {
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.header-menu_item.flex-box {
  padding: 0 13.3333333333vw 0;
}
@media (max-width: 520px) {
  .header-menu_item.flex-box {
    padding: 0;
    display: block;
  }
}
.header-menu_item-btn {
  width: 48%;
  margin-top: 40px;
}
@media (max-width: 520px) {
  .header-menu_item-btn {
    width: 100%;
  }
}
.header-menu_item-btn a {
  font-weight: bold;
  color: #333;
  height: auto;
  padding: 10px 20px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (max-width: 520px) {
  .header-menu_item-btn a {
    justify-content: flex-start;
  }
}
.header-menu_link {
  color: #333;
  font-size: 1.8rem;
  font-weight: 500;
}
.header-menu_link p {
  margin-bottom: 10px;
}
.header-menu_link p span {
  font-size: 1.2rem;
  padding: 4px 8px;
  border: 2px solid #fff;
}
.header-black-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  cursor: pointer;
}
.header-black-bg.active {
  opacity: 0.8;
  visibility: visible;
}

body.active {
  height: 100%;
  overflow: hidden;
}

.footer {
  padding: 6.6666666667vw 0 0;
  position: relative;
}
.footer-breadcrumb {
  background: #f3f3f3;
  padding: 2.4vw 0;
}
.footer-logo {
  width: 160px;
}
@media (min-width: 869px) {
  .footer-logo {
    text-align: left;
    width: 45%;
  }
}
@media (max-width: 320px) {
  .footer-logo {
    margin: 0 auto;
  }
}
.footer-content_inner {
  padding-bottom: 4vw;
}
@media (min-width: 869px) {
  .footer-content_inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 5.3333333333vw;
  }
}
.footer-content_address {
  margin: 4vw 0;
}
@media (min-width: 869px) {
  .footer-content_address {
    width: 55%;
    margin: 0 4.6666666667vw;
  }
}
.footer-content_addressImg img {
  width: 60px;
}
.footer-content_address p {
  margin-top: 10px;
}
.footer-content_address a {
  color: #fff;
  display: inline-block;
}
@media (min-width: 869px) {
  .footer-content_navwrap {
    width: 80%;
  }
}
.footer-content_navList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footer-content_navList li {
  margin-top: 2.6666666667vw;
  width: 50%;
}
@media (min-width: 869px) {
  .footer-content_navList li {
    margin-top: 1.3333333333vw;
  }
}
@media (max-width: 520px) {
  .footer-content_navList li {
    width: 100%;
    margin-top: 4vw;
  }
}
.footer-content_navList li a {
  color: #333;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.footer-content_navList li a::before {
  content: "";
  width: 6px;
  height: 6px;
  border: 0;
  border-top: solid 2px #333;
  border-right: solid 2px #333;
  position: absolute;
  top: 50%;
  left: -20px;
  margin-top: -4px;
  transform: rotate(45deg);
}
@media (min-width: 869px) {
  .footer-content_navList li a {
    justify-content: flex-start;
  }
}
@media (max-width: 868px) {
  .footer-content_navList li a {
    padding-left: 15px;
  }
  .footer-content_navList li a::before {
    left: -5px;
  }
}
.footer-copy {
  position: relative;
  height: 54px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  color: #333;
}
@media (max-width: 868px) {
  .footer-copy {
    font-size: 12px;
  }
}

.inner {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0.6666666667vw 0;
}
.inner.w-1200 {
  padding-right: 12vw;
  padding-left: 12vw;
  max-width: 1240px;
}
@media (min-width: 869px) {
  .inner.w-1200 {
    padding-right: 5.3333333333vw;
    padding-left: 5.3333333333vw;
  }
}
.inner.w-1400 {
  max-width: 1400px;
}

.ttl {
  position: relative;
}
.ttl span {
  display: block;
}
.ttl .en {
  color: #333;
  font-family: "Chillax", sans-serif;
  display: block;
}
.ttl .ja {
  letter-spacing: 6px;
}
@media (min-width: 869px) {
  .ttl .ja {
    display: flex;
    align-items: center;
    text-align: left;
  }
}

.section-title {
  position: relative;
  z-index: 1;
}
.section-title .ttl {
  font-size: 5.3333333333vw;
  text-align: center;
  color: #333;
  padding-bottom: 20px;
}
@media (min-width: 869px) {
  .section-title .ttl {
    font-size: 3.4rem;
  }
}
@media (max-width: 320px) {
  .section-title .ttl {
    font-size: 20px;
  }
}
.section-title .ttl::before {
  content: "";
  display: block;
  position: absolute;
  width: 80px;
  height: 3px;
  text-align: center;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateY(0%) translateX(-50%);
  transform: translateY(0%) translateX(-50%);
  background: #FFC700;
}
.section-title p {
  text-align: center;
  font-size: 2rem;
  font-weight: lighter;
}

#aws .section-title {
  position: relative;
}
#aws .section-title .ttl {
  font-size: 5.3333333333vw;
  text-align: center;
  color: #333;
  padding-bottom: 20px;
}
@media (min-width: 869px) {
  #aws .section-title .ttl {
    font-size: 3.4rem;
  }
}
#aws .section-title .ttl::before {
  content: "";
  display: block;
  position: absolute;
  width: 80px;
  height: 3px;
  text-align: center;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateY(0%) translateX(-50%);
  transform: translateY(0%) translateX(-50%);
  background: #F4F403;
}
#aws .section-title p {
  text-align: center;
  font-size: 2rem;
  font-weight: lighter;
}

#service_now .section-title {
  position: relative;
}
#service_now .section-title .ttl {
  font-size: 5.3333333333vw;
  text-align: center;
  color: #333;
  padding-bottom: 20px;
}
@media (min-width: 869px) {
  #service_now .section-title .ttl {
    font-size: 3.4rem;
  }
}
#service_now .section-title .ttl::before {
  content: "";
  display: block;
  position: absolute;
  width: 80px;
  height: 3px;
  text-align: center;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateY(0%) translateX(-50%);
  transform: translateY(0%) translateX(-50%);
  background: #00CC7F;
}
#service_now .section-title p {
  text-align: center;
  font-size: 2rem;
  font-weight: lighter;
}

#grandit .section-title {
  position: relative;
}
#grandit .section-title .ttl {
  font-size: 5.3333333333vw;
  text-align: center;
  color: #333;
  padding-bottom: 20px;
}
@media (min-width: 869px) {
  #grandit .section-title .ttl {
    font-size: 3.4rem;
  }
}
#grandit .section-title .ttl::before {
  content: "";
  display: block;
  position: absolute;
  width: 80px;
  height: 3px;
  text-align: center;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateY(0%) translateX(-50%);
  transform: translateY(0%) translateX(-50%);
  background: #38AFFE;
}
#grandit .section-title p {
  text-align: center;
  font-size: 2rem;
  font-weight: lighter;
}

.archive .section-title {
  position: relative;
}
.archive .section-title .ttl {
  font-size: 5.3333333333vw;
  text-align: center;
  color: #333;
  padding-bottom: 20px;
  margin-bottom: 5.3333333333vw;
}
@media (min-width: 869px) {
  .archive .section-title .ttl {
    margin-bottom: 40px;
    font-size: 3.4rem;
  }
}
.archive .section-title .ttl::before {
  content: "";
  display: block;
  position: absolute;
  width: 80px;
  height: 3px;
  text-align: center;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateY(0%) translateX(-50%);
  transform: translateY(0%) translateX(-50%);
  background: #FFC700;
}
.archive .section-title p {
  text-align: center;
  font-weight: lighter;
  font-size: 2.6666666667vw;
}
@media (min-width: 869px) {
  .archive .section-title p {
    font-size: 2rem;
  }
}
@media (max-width: 868px) {
  .archive .section-title {
    padding: 4vw 13.3333333333vw 8vw;
  }
}

.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.border-box {
  border-radius: 30px;
}

.radius {
  border-radius: 12px;
}

.view-all_btn {
  margin: 30px auto 50px;
  width: 100%;
  height: 40px;
  max-width: 180px;
  position: relative;
  z-index: 1;
}
@media (min-width: 869px) {
  .view-all_btn {
    margin: 48px auto;
    height: 48px;
    width: 180px;
  }
}
.view-all_btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  color: #333;
  position: relative;
  font-size: 16px;
  letter-spacing: 2px;
  padding: 0 48px 0 20px;
  transition: all 0.4s;
}
.view-all_btn a::before {
  content: "";
  position: absolute;
  right: 20px;
  width: 30px;
  height: 30px;
  background: url(../images/allow_btn.png) no-repeat left top;
  background-size: 30px auto;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.view-all_btn a:hover {
  background-color: #fff;
  color: #333;
  opacity: 1;
}
.view-all_btn a:hover::before {
  right: 9%;
}
.view-all_btn a:hover::after {
  right: 1%;
  border-color: transparent transparent transparent #fff;
}
.view-all_btn span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  color: #333;
  position: relative;
  font-size: 16px;
  letter-spacing: 2px;
  padding: 0 48px 0 20px;
  transition: all 0.4s;
}
.view-all_btn span::before {
  content: "";
  position: absolute;
  right: 20px;
  width: 30px;
  height: 30px;
  background: url(../images/allow_btn-bottom.png) no-repeat left top;
  background-size: 30px auto;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.view-all_btn span:hover {
  background-color: #fff;
  color: #333;
  opacity: 1;
}
.view-all_btn span:hover::before {
  right: 9%;
}
.view-all_btn span:hover::after {
  right: 1%;
  border-color: transparent transparent transparent #fff;
}
.view-all_btn.service a::before {
  content: "";
  position: absolute;
  right: 20px;
  width: 30px;
  height: 30px;
  background: url(../images/allow_btn02.png) no-repeat left top;
  background-size: 30px auto;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.view-all_btn.service a:hover {
  background-color: #fff;
  color: #333;
  opacity: 1;
}
.view-all_btn.service a:hover::before {
  right: 9%;
}
.view-all_btn.service a:hover::after {
  right: 1%;
  border-color: transparent transparent transparent #fff;
}

.sec-pt {
  padding-top: 100px;
}
@media (min-width: 869px) {
  .sec-pt {
    padding-top: 144px;
  }
}

.sec-orange {
  background: #F9F0E8;
}

.accordion-trigger {
  text-align: center;
  cursor: pointer;
  display: block;
  /* background-color: lightblue; */
  position: relative;
}
@media (max-width: 520px) {
  .accordion-trigger {
    text-align: left;
  }
}

.accordion-trigger::before,
.accordion-trigger::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  margin: auto;
  height: 1px;
  width: 15px;
  background-color: #000;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}
@media (max-width: 520px) {
  .accordion-trigger::before,
  .accordion-trigger::after {
    left: -30px;
  }
}

.accordion-trigger::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.accordion-trigger.is-opened::after {
  opacity: 0;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.accordion-target {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  height: 0;
  -webkit-transition: height 0.4s ease-out;
  transition: height 0.4s ease-out;
  text-align: center;
  display: block;
}
@media (max-width: 520px) {
  .accordion-target {
    text-align: left;
  }
}

.accordion-target > .list__item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* border-bottom: 1px solid #ccc; */
  opacity: 1;
  padding-top: 10px;
  padding-bottom: 15px;
  font-size: 1.8rem;
  position: relative;
}

.accordion-target > .list__item:first-child {
  padding-top: 0;
}

.accordion-target > .list__item > a {
  display: block;
  margin-bottom: -15px;
  padding: 15px 0;
  color: #000;
  text-decoration: none;
  font-family: "Chillax", sans-serif;
}

.fedein {
  opacity: 0;
  transform: translate(0, 30px);
  transition: 0.4s;
}
.fedein.isAnimate {
  opacity: 1;
  transform: translate(0, 0);
}

@-webkit-keyframes active-btn05-bar02 {
  100% {
    height: 0;
  }
}
@keyframes active-btn05-bar02 {
  100% {
    height: 0;
  }
}
.slider .slick-slide {
  width: 260px;
  margin: 0 5px;
  position: relative;
  overflow: hidden;
}
.slider .slick-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider .slick-next {
  right: 0;
}
.slider .slick-next::before {
  left: 20px;
}
.slider .slick-prev {
  left: 0;
}
.slider .slick-prev::before {
  border-width: 0 0 3px 3px;
  right: 22px;
}

.slick-slider .slick-arrow {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 0;
  position: absolute;
  top: 200px;
  z-index: 1;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slick-slider .slick-arrow::before {
  content: "";
  display: block;
  width: 32px;
  height: 32px;
  border: 2px solid #fff;
  border-width: 3px 3px 0 0;
  position: absolute;
  top: 24px;
  left: 20px;
  transform: rotate(45deg);
}
.slick-slider:hover .slick-arrow {
  opacity: 1;
}

.slider .slick-next {
  right: 0;
}

.slider .slick-prev {
  left: 0;
}

.slider .slick-next::before {
  left: 20px;
}

.slider .slick-prev::before {
  border-width: 0 0 3px 3px;
  right: 22px;
}

.back-yellow {
  background-color: #FFC700;
}

.back-black {
  background-color: #333;
}

.back-yellow_fresh {
  background-color: #F4F403;
}

.back-white {
  background-color: #fff;
}

.back-blue {
  background-color: #38AFFE;
}

.back-green {
  background-color: #00CC7F;
}

.color-yellow {
  color: #FFC700;
}

.color-black {
  color: #333;
}

.color-fresh {
  color: #F4F403;
}

.color-white {
  color: #fff;
}

.archive-mv {
  position: relative;
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
}
@media (min-width: 869px) {
  .archive-mv {
    background-image: url(../images/background-service_mv.png);
    background-position: center;
  }
}
@media (max-width: 868px) {
  .archive-mv {
    background-image: url(../images/background_mvsp.png);
    height: 106.6666666667vw;
  }
}
@media (max-width: 320px) {
  .archive-mv {
    height: 50vh;
  }
}
.archive-mv_wrap {
  padding-top: 25%;
}
@media (min-width: 869px) {
  .archive-mv_wrap {
    padding-top: 15%;
  }
}
@media (max-width: 320px) {
  .archive-mv_wrap {
    padding-top: 15vh;
  }
}
.archive-mv_loopSlide {
  position: relative;
  display: flex;
  overflow: hidden;
  padding: 2.6666666667vw 0 0;
  z-index: 1;
}
.archive-mv_loopSlide ul {
  display: flex;
  list-style: none;
  padding: 0;
  animation: infinity-scroll-right 20s infinite linear 0.5s both;
}
.archive-mv_loopSlide ul li {
  width: 33.3333333333vw;
  padding-right: 2vw;
}
@media (min-width: 869px) {
  .archive-mv_loopSlide ul li {
    width: 16.6666666667vw;
  }
}
.archive-mv_loopSlide ul li img {
  display: block;
  width: 100%;
  height: auto;
}
@keyframes infinity-scroll-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
.archive-mv_loopSlide:nth-of-type(2) ul {
  margin-left: 40px;
}
.archive-mv_loopSlide:nth-of-type(2) ul:nth-of-type(2) {
  margin-left: 0;
}
.archive-mv_loopSlide:nth-of-type(2) ul:last-child {
  margin-left: 0;
}
.archive-works {
  position: relative;
  padding: 8vw 0;
}
.archive-works::before {
  position: absolute;
  bottom: 0%;
  left: -5%;
  width: 500px;
  height: 604px;
  background: url(../images/background_service01.png) no-repeat left top;
  background-size: 450px auto;
  content: "";
}
@media (max-width: 868px) {
  .archive-works::before {
    bottom: 0%;
    left: -5%;
    width: 350px;
    height: 450px;
    background-size: 350px auto;
  }
}
@media (max-width: 520px) {
  .archive-works::before {
    bottom: 0%;
    left: -10%;
    width: 250px;
    height: 350px;
    background-size: 250px auto;
  }
}
.archive-works::after {
  position: absolute;
  bottom: -10%;
  right: -8%;
  width: 400px;
  height: 600px;
  background: url(../images/service_newsimg.png) no-repeat left top;
  background-size: 400px auto;
  content: "";
}
@media (max-width: 868px) {
  .archive-works::after {
    bottom: -12%;
    right: -12%;
    width: 300px;
    height: 500px;
    background-size: 300px auto;
  }
}
@media (max-width: 520px) {
  .archive-works::after {
    bottom: -10%;
    right: -8%;
    width: 200px;
    height: 400px;
    background-size: 200px auto;
  }
}
@media (min-width: 869px) {
  .archive-works {
    padding: 5.3333333333vw 0 2.6666666667vw;
  }
}
.archive-works .ttl {
  margin-bottom: 5.3333333333vw;
}
@media (min-width: 869px) {
  .archive-works .ttl {
    margin-bottom: 40px;
  }
}
.archive-works .sub_ttl {
  margin-bottom: 4vw;
}
@media (max-width: 868px) {
  .archive-works .sub_ttl {
    font-size: max(2.6666666667vw, 16px);
  }
}
.archive-works_categories .inner {
  display: flex;
}
@media (min-width: 869px) {
  .archive-works_categories .inner {
    justify-content: flex-start;
  }
}
@media (max-width: 868px) {
  .archive-works_categories .inner {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 520px) {
  .archive-works_categories .inner {
    display: block;
  }
}
.archive-works_tab_btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  border: 0.1rem solid #FFC700;
  background-color: #fff;
  transition: all 0.2s;
  cursor: pointer;
  font-size: max(3.7333333333vw, 12px);
  font-weight: bold;
}
@media (min-width: 869px) {
  .archive-works_tab_btn {
    font-size: max(0.9333333333vw, 20px);
    width: 20%;
  }
}
@media (max-width: 868px) {
  .archive-works_tab_btn {
    width: 46%;
  }
  .archive-works_tab_btn:nth-child(2n) {
    margin-left: 20px;
  }
}
@media (max-width: 520px) {
  .archive-works_tab_btn {
    width: 100%;
  }
  .archive-works_tab_btn:nth-child(2n) {
    margin-left: 0px;
  }
}
@media (min-width: 869px) {
  .archive-works_tab_btn:not(:last-of-type) {
    margin-right: 1em;
  }
}
@media (max-width: 868px) {
  .archive-works_tab_btn:not(:last-of-type) {
    margin-bottom: 26px;
  }
}
.archive-works_tab_btn.active {
  color: #333;
  background-color: #FFC700;
  border: 1px solid #FFC700;
}
.archive-works_inner {
  position: relative;
  margin-top: 2.6666666667vw;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 520px) {
  .archive-works_inner {
    display: block;
  }
}
.archive-works .menu-box:first-child {
  margin: 10px;
}
.archive-works_card {
  height: inherit;
  background: #fff;
  position: relative;
  padding-bottom: 40px;
  margin: 0 0 16vw;
  width: calc(50% - 10px);
  z-index: 1;
}
@media (min-width: 869px) {
  .archive-works_card {
    padding: 0;
    width: calc(33.333% - 25px);
  }
  .archive-works_card + .archive-works_card {
    margin-left: 20px;
  }
  .archive-works_card:nth-child(3n+1) {
    margin-left: 0;
  }
}
@media (max-width: 868px) {
  .archive-works_card {
    margin: 0 0 26.6666666667vw;
  }
  .archive-works_card:nth-child(2n) {
    margin-left: 20px;
  }
}
@media (max-width: 520px) {
  .archive-works_card {
    width: 100%;
    margin: 0 0 46.6666666667vw;
  }
  .archive-works_card:nth-child(2n) {
    margin-left: 0px;
  }
}
@media (max-width: 320px) {
  .archive-works_card {
    margin: 0 0 53.3333333333vw;
  }
}
.archive-works_card a {
  color: #333;
}
.archive-works_card a:hover {
  opacity: 1;
}
.archive-works_cardImgBox {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
}
.archive-works_cardImgBox::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 70%;
}
.archive-works_cardImgBox img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
}
.archive-works_cardImgBox:hover .archive-works_cardImg::after {
  opacity: 1;
}
.archive-works_cardImgBox:hover img {
  transform: scale(1.1);
  opacity: 1;
}
.archive-works_cardImgBox:hover .archive-works_cardlogo img {
  transform: scale(1);
}
.archive-works_cardlogo {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
}
.archive-works_cardDesc {
  padding: 15px 1.3333333333vw;
  position: absolute;
  width: 95%;
  left: 2.5%;
  top: 65%;
  background: #fff;
  height: inherit;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}
@media (max-width: 868px) {
  .archive-works_cardDesc {
    padding: 15px 3.3333333333vw;
  }
}
@media (max-width: 520px) {
  .archive-works_cardDesc {
    padding: 15px 4vw;
  }
}
.archive-works_cardDesc .archive-works_cardTtl {
  font-size: min(1.8666666667vw, 2.6rem);
  letter-spacing: 2px;
  color: #444;
  line-height: 1.5;
  width: 100%;
}
@media (min-width: 869px) {
  .archive-works_cardDesc .archive-works_cardTtl {
    font-size: min(1.2vw, 1.8rem);
  }
}
@media (max-width: 520px) {
  .archive-works_cardDesc .archive-works_cardTtl {
    font-size: min(4vw, 2.6rem);
  }
}
.archive-works_cardDesc .archive-works_cardcont {
  color: #666;
  letter-spacing: 2px;
  font-size: min(1.4666666667vw, 2rem);
  line-height: 1.5;
  text-transform: uppercase;
  margin-top: 0.6666666667vw;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}
@media (min-width: 869px) {
  .archive-works_cardDesc .archive-works_cardcont {
    font-size: min(1.0666666667vw, 16px);
  }
}
@media (max-width: 520px) {
  .archive-works_cardDesc .archive-works_cardcont {
    font-size: min(3.3333333333vw, 2rem);
  }
}
.archive-works_cardDesc .archive-works_cardname {
  padding: 10px 0 0;
  font-size: min(1.8666666667vw, 2.4rem);
}
@media (min-width: 869px) {
  .archive-works_cardDesc .archive-works_cardname {
    padding: 15px 0 0;
    font-size: min(1.0666666667vw, 18px);
  }
}
@media (max-width: 520px) {
  .archive-works_cardDesc .archive-works_cardname {
    font-size: min(3.0666666667vw, 2.4rem);
  }
}
.archive-news {
  position: relative;
  padding: 13.3333333333vw 0 10.6666666667vw;
}
@media (min-width: 869px) {
  .archive-news {
    padding: 2.6666666667vw 0;
  }
}
.archive-news:before {
  position: absolute;
  top: -5%;
  left: -10%;
  width: 500px;
  height: 604px;
  background: url(../images/service_accordionimg.png) no-repeat left top;
  background-size: 450px auto;
  content: "";
}
@media (max-width: 868px) {
  .archive-news:before {
    content: none;
  }
}
.archive-news::after {
  position: absolute;
  bottom: -10%;
  right: -8%;
  width: 400px;
  height: 600px;
  background: url(../images/service_newsimg.png) no-repeat left top;
  background-size: 400px auto;
  content: "";
}
@media (max-width: 868px) {
  .archive-news::after {
    bottom: -12%;
    right: -12%;
    width: 300px;
    height: 500px;
    background-size: 300px auto;
  }
}
@media (max-width: 520px) {
  .archive-news::after {
    bottom: -10%;
    right: -8%;
    width: 200px;
    height: 400px;
    background-size: 200px auto;
  }
}
.archive-news_categories {
  margin-top: 6.6666666667vw;
  display: flex;
}
@media (min-width: 869px) {
  .archive-news_categories {
    justify-content: flex-start;
  }
}
@media (max-width: 868px) {
  .archive-news_categories {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 520px) {
  .archive-news_categories {
    display: block;
  }
}
.archive-news_tab_btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  border: 0.1rem solid #FFC700;
  transition: all 0.2s;
  cursor: pointer;
  font-size: max(3.7333333333vw, 12px);
  font-weight: bold;
}
@media (min-width: 869px) {
  .archive-news_tab_btn {
    font-size: max(0.9333333333vw, 20px);
    width: 20%;
  }
}
@media (max-width: 868px) {
  .archive-news_tab_btn {
    width: 46%;
  }
  .archive-news_tab_btn:nth-child(2n) {
    margin-left: 20px;
  }
}
@media (max-width: 520px) {
  .archive-news_tab_btn {
    width: 100%;
  }
  .archive-news_tab_btn:nth-child(2n) {
    margin-left: 0px;
  }
}
@media (min-width: 869px) {
  .archive-news_tab_btn:not(:last-of-type) {
    margin-right: 1em;
  }
}
@media (max-width: 868px) {
  .archive-news_tab_btn:not(:last-of-type) {
    margin-bottom: 26px;
  }
}
.archive-news_tab_btn.active {
  color: #333;
  background-color: #FFC700;
  border: 1px solid #FFC700;
}
.archive-news_content {
  position: relative;
  margin-top: 2.6666666667vw;
  border-radius: 20px;
}
@media (min-width: 869px) {
  .archive-news_content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
.archive-news_content-item a {
  display: block;
  padding: 25px 10px;
  text-decoration: none;
  color: #444;
  position: relative;
}
@media (min-width: 869px) {
  .archive-news_content-item a {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 6px 0;
    margin: 6px 0;
  }
}
.archive-news_content-itemCategory {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  border: 0.1rem solid #FFC700;
  transition: all 0.2s;
  cursor: pointer;
  font-size: 2vw;
  max-width: 160px;
}
@media (min-width: 869px) {
  .archive-news_content-itemCategory {
    font-size: 1.6rem;
    width: 18%;
    margin-right: 2vw;
  }
}
@media (max-width: 520px) {
  .archive-news_content-itemCategory {
    font-size: 4vw;
  }
}
.archive-news_content-itemDate {
  letter-spacing: 1px;
  font-weight: 700;
  color: #aaa;
  line-height: 1.5;
  font-size: 2vw;
  width: auto;
  font-family: Heebo, sans-serif;
  display: block;
  margin-bottom: 10px;
}
@media (min-width: 869px) {
  .archive-news_content-itemDate {
    font-size: 1.6rem;
    width: 15%;
  }
}
@media (max-width: 520px) {
  .archive-news_content-itemDate {
    font-size: 4vw;
  }
}
.archive-news_content-itemTtl {
  font-weight: 400;
  margin-right: auto;
  line-height: 1.5;
  font-size: 2.4vw;
}
@media (min-width: 869px) {
  .archive-news_content-itemTtl {
    padding: 20px 0;
    font-size: 2rem;
    text-overflow: ellipsis;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
@media (max-width: 520px) {
  .archive-news_content-itemTtl {
    font-size: 4.6666666667vw;
  }
}
.archive-news_content-itemTtl:hover {
  text-decoration: underline;
}
.archive-news_content-itemTtl .headline {
  font-weight: bold;
}
.archive-news_content-itemTtl .desc {
  line-height: 1.5;
}
.archive-news_content-item:nth-child(2) {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.archive-news_content-item:nth-child(3) {
  border-bottom: 1px solid #e6e6e6;
}
.archive-contact {
  margin-top: 80px;
  position: relative;
  z-index: 1;
}
@media (max-width: 868px) {
  .archive-contact {
    padding: 5.3333333333vw 0;
  }
}
.archive-contact_title {
  text-align: center;
  padding: 2.6666666667vw 0;
  color: #fff !important;
}
.archive-contact_title::before {
  background: #fff !important;
}
.archive-contact_content {
  padding: 2.6666666667vw 0 0;
}
.archive-contact_head {
  color: #fff;
  text-align: center;
  line-height: 1.5;
  margin: 30px 0;
  font-size: 4.6666666667vw;
}
@media (min-width: 869px) {
  .archive-contact_head {
    margin-bottom: 40px;
    font-size: max(1.3333333333vw, 26px);
    margin: 30px 0;
  }
}
.archive-contact_inner {
  justify-content: space-between;
  padding: 4vw 0;
}
@media (max-width: 868px) {
  .archive-contact_inner {
    display: block;
  }
}
.archive-contact_item {
  position: relative;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  background-color: #FFF3D7;
  padding: 15px 10px;
}
@media (min-width: 869px) {
  .archive-contact_item {
    width: calc(48% - 8px);
    padding: 15px 15px 10px;
  }
}
.archive-contact_item p {
  color: #333;
  text-align: center;
  font-size: min(1.4666666667vw, 16px);
  line-height: 1.5;
}
@media (max-width: 868px) {
  .archive-contact_item p {
    font-size: 2.6666666667vw;
  }
}
@media (max-width: 320px) {
  .archive-contact_item p {
    font-size: 3.3333333333vw;
  }
}
@media (max-width: 868px) {
  .archive-contact_item + .archive-contact_item {
    margin-top: 13.3333333333vw;
  }
}
@media (max-width: 520px) {
  .archive-contact_item + .archive-contact_item {
    margin-top: 20vw;
  }
}
.archive-contact_btn_txt {
  position: absolute;
  top: -35px;
  background-color: #fff;
  font-weight: bold;
  display: block;
  width: 95%;
  font-size: max(1.0666666667vw, 16px);
  padding: 10px;
  filter: drop-shadow(2px 4px 5px rgba(0, 0, 0, 0.3));
}
.archive-contact_btn_txt::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -20px;
  border: 12px solid transparent;
  border-top: 18px solid #fff;
}
@media (max-width: 868px) {
  .archive-contact_btn_txt {
    top: -5.0666666667vw;
    left: 15px;
  }
}
@media (max-width: 520px) {
  .archive-contact_btn_txt {
    top: -3.7333333333vw;
    left: 10px;
    padding: 5px 10px;
  }
  .archive-contact_btn_txt::before {
    border: 10px solid transparent;
    border-top: 14px solid #fff;
    left: 52%;
  }
}
@media (max-width: 320px) {
  .archive-contact_btn_txt {
    top: -6.6666666667vw;
  }
}
.archive-contact_btn {
  margin: 20px auto 10px;
  max-width: 500px;
}
.archive-contact_btn a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-radius: 20px;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  font-size: 3.3333333333vw;
  font-weight: bold;
  color: #333 !important;
}
.archive-contact_btn a::after {
  content: "";
  width: 8px;
  height: 8px;
  border: 0;
  border-top: solid 2px #333;
  border-right: solid 2px #333;
  position: absolute;
  top: 50%;
  right: 30px;
  margin-top: -4px;
  transform: rotate(45deg);
}
@media (max-width: 520px) {
  .archive-contact_btn a::after {
    width: 6px;
    height: 6px;
    right: 10px;
  }
}
@media (min-width: 869px) {
  .archive-contact_btn a {
    font-size: min(1.6vw, 22px);
  }
}
@media (max-width: 520px) {
  .archive-contact_btn a {
    font-size: 4.6666666667vw;
    height: 40px;
  }
}
.archive-accordion {
  padding-bottom: 8vw;
}
.archive-accordion_inner {
  position: relative;
  padding: 60px 0 20px;
  background-color: #F9F9F9;
  margin: 9.3333333333vw auto 0;
}
@media (min-width: 869px) {
  .archive-accordion_inner {
    max-width: 980px;
    margin: 5.3333333333vw auto 0;
  }
}
.archive-accordion_inner::before {
  position: absolute;
  bottom: -70%;
  left: -60%;
  width: 500px;
  height: 604px;
  background: url(../images/service_accordionimg.png) no-repeat left top;
  background-size: 450px auto;
  content: "";
  z-index: 1;
}
@media (max-width: 868px) {
  .archive-accordion_inner::before {
    bottom: -50%;
    left: -70%;
    width: 350px;
    height: 450px;
    background-size: 350px auto;
  }
}
@media (max-width: 520px) {
  .archive-accordion_inner::before {
    bottom: -45%;
    left: -80%;
    width: 250px;
    height: 350px;
    background-size: 250px auto;
  }
}
.archive-accordion_container {
  max-width: 90%;
  margin: 50px auto;
}
@media (min-width: 869px) {
  .archive-accordion_container {
    max-width: 80%;
  }
}
.archive-accordion-list {
  margin-bottom: 40px;
  padding: 0 20px;
  line-height: 1.5;
}
.archive-accordion_title {
  position: relative;
  font-size: 3.3333333333vw;
  font-weight: bold;
  padding: 30px 10px 30px 25px;
  background-color: #fff;
}
@media (min-width: 869px) {
  .archive-accordion_title {
    font-size: 2rem;
    padding: 30px;
  }
}
.archive-accordion_title::before {
  position: absolute;
  content: "Q";
  font-size: 3.3333333333vw;
  left: 0px;
}
@media (min-width: 869px) {
  .archive-accordion_title::before {
    font-size: 2rem;
  }
}
.archive-accordion_title::after {
  position: absolute;
  display: block;
  content: "";
  top: 40%;
  right: 0px;
  border: 10px solid transparent;
  border-top: 12px solid #333;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 868px) {
  .archive-accordion_title::after {
    right: 0;
  }
}
.archive-accordion_text {
  display: none;
  padding: 20px 20px;
  border-top: 1px solid #333;
}

.document {
  position: relative;
}
.document-mv {
  position: relative;
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
}
@media (min-width: 869px) {
  .document-mv {
    background-image: url(../images/background-contact_mv.png);
    background-position: -200px -50px;
    background-size: auto;
  }
}
@media (max-width: 868px) {
  .document-mv {
    background-image: url(../images/background_mvsp.png);
    height: 160vw;
  }
}
@media (max-width: 520px) {
  .document-mv {
    height: 206.6666666667vw;
  }
}
@media (max-width: 320px) {
  .document-mv {
    height: 50vh;
  }
}
.document-mv_wrap {
  height: 160vw;
}
@media (min-width: 869px) {
  .document-mv_wrap {
    height: 56vw;
  }
}
@media (max-width: 520px) {
  .document-mv_wrap {
    height: 206.6666666667vw;
  }
}
@media (max-width: 320px) {
  .document-mv_wrap {
    height: 60vh;
  }
}
.document-form {
  position: relative;
  margin-top: -133.3333333333vw;
  z-index: 10;
}
@media (min-width: 869px) {
  .document-form {
    margin-top: -46.6666666667vw;
  }
}
@media (max-width: 520px) {
  .document-form {
    margin-top: -186.6666666667vw;
    padding: 8vw 0;
  }
}
@media (max-width: 320px) {
  .document-form {
    margin-top: -38vh;
  }
}
@media (min-width: 869px) {
  .document-form {
    padding: 5.3333333333vw 0 2.6666666667vw;
  }
}
.document-form_inner {
  position: relative;
  align-items: flex-start;
  margin-top: 5.3333333333vw;
}
@media (max-width: 868px) {
  .document-form_inner {
    display: block;
  }
}
.document-form .ttl {
  margin-bottom: 5.3333333333vw;
}
@media (min-width: 869px) {
  .document-form .ttl {
    margin-bottom: 40px;
  }
}
@media (max-width: 868px) {
  .document-form .sub_ttl {
    font-size: max(2.6666666667vw, 10px);
  }
}
.document-form-left {
  flex: 0 0 48%;
}
.document-form-left p {
  font-size: 4vw;
  font-weight: bold;
}
@media (min-width: 869px) {
  .document-form-left p {
    font-size: max(0.8vw, 18px);
  }
}
.document-form-right {
  flex: 0 0 48%;
}
@media (max-width: 868px) {
  .document-form-right {
    margin-top: 10.6666666667vw;
  }
}
.document-form_img {
  display: block;
  max-width: 250px;
  margin: 40px auto 0;
}
.document-form table {
  width: 100%;
  margin-bottom: 60px;
}
.document-form table th,
.document-form table td {
  float: left;
  width: 100%;
  padding: 0 0 25px;
  box-sizing: border-box;
  text-align: left;
}
.document-form .must {
  background-color: #e90606;
  color: #fff;
  margin: 0 0 0 1em;
  font-size: 1rem;
  padding: 5px 10px;
  border-radius: 10px;
}
.document-formNameList {
  display: flex;
  justify-content: space-between;
}
.document-formNameList p {
  width: 48%;
}
.document-form td input[type=text],
.document-form td input[type=email],
.document-form td input[type=tel],
.document-form td textarea {
  height: 58px;
  font-size: 1.2rem;
  padding: 5px 10px 5px 10px;
  border: 1px solid #404040;
}
.document-form td input[type=text],
.document-form td input[type=email],
.document-form td input[type=tel],
.document-form td input[type=number],
.document-form td select,
.document-form td textarea {
  color: #333;
  width: 100%;
  height: 45px;
  font-size: 1.3rem;
  padding: 5px 10px 5px 10px;
  -webkit-appearance: none;
  box-sizing: border-box;
  border: 1px solid #A4A4A4;
}
.document .document-form_btn input {
  width: 100%;
  border: none;
  cursor: pointer;
  color: #333 !important;
  border-radius: 80px;
  padding: 15px 32px;
  margin-bottom: 24px;
  -webkit-appearance: none;
  appearance: none;
  letter-spacing: 0.1em;
  transition: all 0.4s ease;
  color: transparent;
  font-size: 18px;
}
.document-contact {
  margin-top: 80px;
  position: relative;
  z-index: 1;
}
@media (max-width: 868px) {
  .document-contact {
    padding: 5.3333333333vw 0;
  }
}
.document-contact_title {
  text-align: center;
  padding: 2.6666666667vw 0;
  color: #fff !important;
}
.document-contact_title::before {
  background: #fff !important;
}
.document-contact_content {
  padding: 2.6666666667vw 0 0;
}
.document-contact_head {
  color: #fff;
  text-align: center;
  line-height: 1.5;
  margin: 30px 0;
  font-size: 4.6666666667vw;
}
@media (min-width: 869px) {
  .document-contact_head {
    margin-bottom: 40px;
    font-size: max(1.3333333333vw, 26px);
    margin: 30px 0;
  }
}
.document-contact_inner {
  justify-content: space-between;
  padding: 4vw 0;
}
@media (max-width: 868px) {
  .document-contact_inner {
    display: block;
  }
}
.document-contact_item {
  position: relative;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  background-color: #FFF3D7;
  padding: 15px 10px;
}
@media (min-width: 869px) {
  .document-contact_item {
    width: calc(48% - 8px);
    padding: 15px 15px 10px;
  }
}
.document-contact_item p {
  color: #333;
  text-align: center;
  font-size: min(1.4666666667vw, 16px);
  line-height: 1.5;
}
@media (max-width: 868px) {
  .document-contact_item p {
    font-size: 2.6666666667vw;
  }
}
@media (max-width: 320px) {
  .document-contact_item p {
    font-size: 3.3333333333vw;
  }
}
@media (max-width: 868px) {
  .document-contact_item + .single-contact_item {
    margin-top: 13.3333333333vw;
  }
}
@media (max-width: 520px) {
  .document-contact_item + .single-contact_item {
    margin-top: 20vw;
  }
}
.document-contact_btn_txt {
  position: absolute;
  top: -35px;
  background-color: #fff;
  font-weight: bold;
  display: block;
  width: 95%;
  font-size: max(1.0666666667vw, 16px);
  padding: 10px;
  filter: drop-shadow(2px 4px 5px rgba(0, 0, 0, 0.3));
}
.document-contact_btn_txt::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -20px;
  border: 12px solid transparent;
  border-top: 18px solid #fff;
}
@media (max-width: 868px) {
  .document-contact_btn_txt {
    top: -5.0666666667vw;
    left: 15px;
  }
}
@media (max-width: 520px) {
  .document-contact_btn_txt {
    top: -3.7333333333vw;
    left: 10px;
    padding: 5px 10px;
  }
  .document-contact_btn_txt::before {
    border: 10px solid transparent;
    border-top: 14px solid #fff;
    left: 52%;
  }
}
@media (max-width: 320px) {
  .document-contact_btn_txt {
    top: -6.6666666667vw;
  }
}
.document-contact_btn {
  margin: 20px auto 10px;
  max-width: 500px;
}
.document-contact_btn a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-radius: 20px;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  font-size: 3.3333333333vw;
  font-weight: bold;
  color: #333 !important;
}
.document-contact_btn a::after {
  content: "";
  width: 8px;
  height: 8px;
  border: 0;
  border-top: solid 2px #333;
  border-right: solid 2px #333;
  position: absolute;
  top: 50%;
  right: 30px;
  margin-top: -4px;
  transform: rotate(45deg);
}
@media (max-width: 520px) {
  .document-contact_btn a::after {
    width: 6px;
    height: 6px;
    right: 10px;
  }
}
@media (min-width: 869px) {
  .document-contact_btn a {
    font-size: min(1.6vw, 22px);
  }
}
@media (max-width: 520px) {
  .document-contact_btn a {
    font-size: 4.6666666667vw;
    height: 40px;
  }
}

.single {
  position: relative;
}
.single-mv {
  position: relative;
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
}
@media (min-width: 869px) {
  .single-mv {
    background-image: url(../images/background-service_mv.png);
    background-position: center;
  }
}
@media (max-width: 868px) {
  .single-mv {
    background-image: url(../images/background_mvsp.png);
    height: 160vw;
  }
}
@media (max-width: 520px) {
  .single-mv {
    height: 206.6666666667vw;
  }
}
@media (max-width: 320px) {
  .single-mv {
    height: 50vh;
  }
}
.single-mv_wrap {
  height: 160vw;
}
@media (min-width: 869px) {
  .single-mv_wrap {
    height: 56vw;
  }
}
@media (max-width: 520px) {
  .single-mv_wrap {
    height: 206.6666666667vw;
  }
}
@media (max-width: 320px) {
  .single-mv_wrap {
    height: 60vh;
  }
}
.single_detail {
  margin-top: 5.3333333333vw;
}
.single_detail h1 {
  font-size: max(4.6666666667vw, 24px);
  margin: 2.6666666667vw 0;
  border-bottom: 1px solid #707070;
  padding-bottom: 20px;
}
@media (min-width: 869px) {
  .single_detail h1 {
    margin: 1.3333333333vw 0;
    font-size: 3rem;
  }
}
.single_categoryName {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  border: 0.1rem solid #FFC700;
  transition: all 0.2s;
  cursor: pointer;
  font-size: 2vw;
  max-width: 160px;
  width: 35%;
}
@media (min-width: 869px) {
  .single_categoryName {
    font-size: 1.6rem;
    width: 18%;
    margin-right: 2vw;
  }
}
@media (max-width: 520px) {
  .single_categoryName {
    font-size: 4vw;
  }
}
.single_body-Img {
  display: block;
  height: 600px;
  margin: 1.3333333333vw 0 3.3333333333vw;
}
.single_body-Img img {
  object-fit: cover;
  height: 100%;
}
@media (max-width: 868px) {
  .single_body-Img {
    height: auto;
  }
}
.single_body-cont {
  font-size: 3.7333333333vw;
}
.single_body-cont h1 {
  font-size: max(5.0666666667vw, 26px);
  margin: 2.6666666667vw 0;
  border-bottom: 1px solid #707070;
  padding-bottom: 20px;
}
@media (min-width: 869px) {
  .single_body-cont h1 {
    margin: 1.3333333333vw 0;
    font-size: 3rem;
  }
}
.single_body-cont h2 {
  font-size: max(4.6666666667vw, 22px);
  border-bottom: 1px solid #707070;
  padding-bottom: 20px;
}
@media (min-width: 869px) {
  .single_body-cont h2 {
    font-size: max(0.9333333333vw, 26px);
  }
}
.single_body-cont h3 {
  font-size: max(4.2666666667vw, 20px);
  border-bottom: 1px solid #707070;
  padding-bottom: 20px;
}
@media (min-width: 869px) {
  .single_body-cont h3 {
    font-size: max(0.9333333333vw, 24px);
  }
}
.single_body-cont h4 {
  font-size: max(4vw, 18px);
  border-bottom: 1px solid #707070;
  padding-bottom: 20px;
}
@media (min-width: 869px) {
  .single_body-cont h4 {
    font-size: max(0.9333333333vw, 20px);
  }
}
@media (min-width: 869px) {
  .single_body-cont {
    font-size: 2rem;
  }
}
@media (max-width: 868px) {
  .single_contentList {
    margin-top: 8vw;
  }
}
.single_contentList-item h3 {
  font-size: max(3.7333333333vw, 16px);
  padding: 15px 30px 15px;
  -webkit-box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}
@media (min-width: 869px) {
  .single_contentList-item h3 {
    font-size: max(0.9333333333vw, 20px);
  }
}
.single_contentList-item p {
  margin-top: 15px;
  color: #707070;
}
.single_contentList-item:nth-child(2n) {
  margin: 4vw 0;
}
@media (max-width: 868px) {
  .single_contentList-item:nth-child(2n) {
    margin: 6.6666666667vw 0;
  }
}
.single-works {
  position: relative;
  margin-top: -133.3333333333vw;
  z-index: 10;
}
@media (min-width: 869px) {
  .single-works {
    margin-top: -46.6666666667vw;
  }
}
@media (max-width: 520px) {
  .single-works {
    margin-top: -186.6666666667vw;
    padding: 8vw 0;
  }
}
@media (max-width: 320px) {
  .single-works {
    margin-top: -35vh;
  }
}
.single-works::before {
  position: absolute;
  bottom: 0%;
  left: -5%;
  width: 500px;
  height: 604px;
  background: url(../images/background_service01.png) no-repeat left top;
  background-size: 450px auto;
  content: "";
  z-index: 1;
}
@media (max-width: 868px) {
  .single-works::before {
    bottom: 0%;
    left: -5%;
    width: 350px;
    height: 450px;
    background-size: 350px auto;
  }
}
@media (max-width: 520px) {
  .single-works::before {
    bottom: 0%;
    left: -10%;
    width: 250px;
    height: 350px;
    background-size: 250px auto;
  }
}
.single-works::after {
  position: absolute;
  bottom: -10%;
  right: -8%;
  width: 400px;
  height: 600px;
  background: url(../images/service_newsimg.png) no-repeat left top;
  background-size: 400px auto;
  content: "";
}
@media (max-width: 868px) {
  .single-works::after {
    bottom: -12%;
    right: -12%;
    width: 300px;
    height: 500px;
    background-size: 300px auto;
  }
}
@media (max-width: 520px) {
  .single-works::after {
    bottom: -10%;
    right: -8%;
    width: 200px;
    height: 400px;
    background-size: 200px auto;
  }
}
@media (min-width: 869px) {
  .single-works {
    padding: 5.3333333333vw 0 2.6666666667vw;
  }
}
.single-works .ttl {
  margin-bottom: 5.3333333333vw;
}
@media (min-width: 869px) {
  .single-works .ttl {
    margin-bottom: 40px;
  }
}
.single-works .sub_ttl {
  margin-bottom: 4vw;
}
@media (max-width: 868px) {
  .single-works .sub_ttl {
    font-size: max(2.6666666667vw, 16px);
  }
}
.single-works_inner {
  position: relative;
  margin-top: 10.6666666667vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 520px) {
  .single-works_inner {
    display: block;
  }
}
@media (max-width: 868px) {
  .single-works_inner {
    margin-top: 16vw;
  }
}
.single-works .menu-box:first-child {
  margin: 10px;
}
.single-works_card {
  height: inherit;
  background: #fff;
  position: relative;
  margin: 0 0 16vw;
  z-index: 1;
  width: 100%;
}
@media (min-width: 869px) {
  .single-works_card {
    padding: 0;
    width: calc(33.333% - 25px);
    margin-bottom: 10.6666666667vw;
  }
  .single-works_card + .single-works_card {
    margin-left: 20px;
  }
}
@media (max-width: 520px) {
  .single-works_card {
    margin: 0 0 40vw;
  }
  .single-works_card:nth-child(2n) {
    margin-left: 0px;
  }
}
@media (max-width: 320px) {
  .single-works_card {
    margin: 0 0 46.6666666667vw;
  }
}
.single-works_card a {
  color: #333;
}
.single-works_card a:hover {
  opacity: 1;
}
.single-works_cardImgBox {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
}
.single-works_cardImgBox::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 70%;
}
.single-works_cardImgBox img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
}
.single-works_cardImgBox:hover .single-works_cardImg::after {
  opacity: 1;
}
.single-works_cardImgBox:hover img {
  transform: scale(1.1);
  opacity: 1;
}
.single-works_cardImgBox:hover .single-works_cardlogo img {
  transform: scale(1);
}
.single-works_cardlogo {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
}
.single-works_cardDesc {
  padding: 15px 1.3333333333vw;
  position: absolute;
  width: 95%;
  left: 2.5%;
  top: 65%;
  background: #fff;
  height: inherit;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}
@media (max-width: 868px) {
  .single-works_cardDesc {
    padding: 25px 3.3333333333vw;
  }
}
@media (max-width: 520px) {
  .single-works_cardDesc {
    padding: 15px 4vw;
  }
}
.single-works_cardDesc .single-works_cardTtl {
  font-size: min(4vw, 2.6rem);
  letter-spacing: 2px;
  color: #444;
  line-height: 1.5;
  width: 100%;
}
@media (min-width: 869px) {
  .single-works_cardDesc .single-works_cardTtl {
    font-size: min(1.2vw, 1.8rem);
  }
}
.single-works_cardDesc .single-works_cardcont {
  color: #666;
  letter-spacing: 2px;
  font-size: min(3.0666666667vw, 2.4rem);
  line-height: 1.5;
  text-transform: uppercase;
  margin-top: 0.6666666667vw;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}
@media (min-width: 869px) {
  .single-works_cardDesc .single-works_cardcont {
    font-size: min(1.0666666667vw, 16px);
  }
}
@media (max-width: 520px) {
  .single-works_cardDesc .single-works_cardcont {
    font-size: min(3.3333333333vw, 2rem);
  }
}
.single-works_cardDesc .single-works_cardname {
  padding: 10px 0 0;
  font-size: min(3.0666666667vw, 2.4rem);
}
@media (min-width: 869px) {
  .single-works_cardDesc .single-works_cardname {
    padding: 15px 0 0;
    font-size: min(1.0666666667vw, 18px);
  }
}
@media (max-width: 520px) {
  .single-works_cardDesc .single-works_cardname {
    font-size: min(3.0666666667vw, 2.4rem);
  }
}
@media (min-width: 869px) {
  .single-works_card:nth-child(n+4) {
    margin: 0 0 16vw;
  }
}
.single-news {
  position: relative;
  margin-top: -133.3333333333vw;
  z-index: 10;
}
@media (min-width: 869px) {
  .single-news {
    margin-top: -46.6666666667vw;
  }
}
@media (max-width: 520px) {
  .single-news {
    margin-top: -186.6666666667vw;
    padding: 8vw 0;
  }
}
@media (max-width: 320px) {
  .single-news {
    margin-top: -45vh;
  }
}
.single-news::before {
  position: absolute;
  bottom: 0%;
  left: -5%;
  width: 500px;
  height: 604px;
  background: url(../images/background_service01.png) no-repeat left top;
  background-size: 450px auto;
  content: "";
  z-index: 1;
}
@media (max-width: 868px) {
  .single-news::before {
    bottom: 0%;
    left: -5%;
    width: 350px;
    height: 450px;
    background-size: 350px auto;
  }
}
@media (max-width: 520px) {
  .single-news::before {
    bottom: 0%;
    left: -30%;
    width: 250px;
    height: 350px;
    background-size: 250px auto;
  }
}
@media (max-width: 320px) {
  .single-news::before {
    content: none;
  }
}
.single-news::after {
  position: absolute;
  bottom: -10%;
  right: -8%;
  width: 400px;
  height: 600px;
  background: url(../images/service_newsimg.png) no-repeat left top;
  background-size: 400px auto;
  content: "";
}
@media (max-width: 868px) {
  .single-news::after {
    bottom: -5%;
    right: -30%;
    width: 300px;
    height: 500px;
    background-size: 300px auto;
  }
}
@media (max-width: 520px) {
  .single-news::after {
    bottom: -7%;
    right: -40%;
    width: 300px;
    height: 400px;
    background-size: 300px auto;
  }
}
@media (max-width: 320px) {
  .single-news::after {
    content: none;
  }
}
@media (min-width: 869px) {
  .single-news {
    padding: 5.3333333333vw 0 2.6666666667vw;
  }
}
.single-news .ttl {
  margin-bottom: 5.3333333333vw;
}
@media (min-width: 869px) {
  .single-news .ttl {
    margin-bottom: 40px;
  }
}
.single-news .sub_ttl {
  margin-bottom: 4vw;
}
@media (max-width: 868px) {
  .single-news .sub_ttl {
    font-size: max(2.6666666667vw, 16px);
  }
}
.single-news_inner {
  position: relative;
  margin-top: 9.3333333333vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 520px) {
  .single-news_inner {
    margin-top: 16vw;
    display: block;
  }
}
.single-news .menu-box:first-child {
  margin: 10px;
}
.single-news_card {
  height: inherit;
  background: #fff;
  position: relative;
  margin: 0 0 16vw;
  z-index: 1;
  width: 100%;
}
@media (min-width: 869px) {
  .single-news_card {
    padding: 0;
    width: calc(33.333% - 25px);
    margin-bottom: 10.6666666667vw;
  }
  .single-news_card + .single-news_card {
    margin-left: 20px;
  }
}
@media (max-width: 520px) {
  .single-news_card {
    margin: 0 0 26.6666666667vw;
  }
  .single-news_card:nth-child(2n) {
    margin-left: 0px;
  }
}
@media (max-width: 320px) {
  .single-news_card {
    margin: 0 0 33.3333333333vw;
  }
}
.single-news_card a {
  color: #333;
}
.single-news_card a:hover {
  opacity: 1;
}
.single-news_cardImgBox {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
}
.single-news_cardImgBox::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 70%;
}
.single-news_cardImgBox img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
}
.single-news_cardImgBox:hover .single-news_cardImg::after {
  opacity: 1;
}
.single-news_cardImgBox:hover img {
  transform: scale(1.1);
  opacity: 1;
}
.single-news_cardImgBox:hover .single-news_cardlogo img {
  transform: scale(1);
}
.single-news_cardcategory {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 20px;
  border: 1px solid #FFC700;
  font-size: min(3.0666666667vw, 2.4rem);
}
@media (min-width: 869px) {
  .single-news_cardcategory {
    font-size: min(1.0666666667vw, 16px);
  }
}
@media (max-width: 520px) {
  .single-news_cardcategory {
    font-size: min(3.3333333333vw, 2rem);
    padding: 10px 4vw;
  }
}
.single-news_cardDesc {
  padding: 15px 1.3333333333vw;
  position: absolute;
  width: 95%;
  left: 2.5%;
  top: 65%;
  background: #fff;
  height: inherit;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}
@media (max-width: 868px) {
  .single-news_cardDesc {
    padding: 25px 3.3333333333vw;
  }
}
@media (max-width: 520px) {
  .single-news_cardDesc {
    padding: 15px 4vw;
  }
}
.single-news_cardDesc .single-news_cardTtl {
  font-size: min(4vw, 2.6rem);
  letter-spacing: 2px;
  color: #444;
  line-height: 1.5;
  width: 100%;
}
@media (min-width: 869px) {
  .single-news_cardDesc .single-news_cardTtl {
    font-size: min(1.2vw, 1.8rem);
  }
}
.single-news_cardDesc .single-news_cardcont {
  color: #666;
  letter-spacing: 2px;
  font-size: min(3.0666666667vw, 2.4rem);
  line-height: 1.5;
  text-transform: uppercase;
  margin-top: 0.6666666667vw;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}
@media (min-width: 869px) {
  .single-news_cardDesc .single-news_cardcont {
    font-size: min(1.0666666667vw, 16px);
  }
}
@media (max-width: 520px) {
  .single-news_cardDesc .single-news_cardcont {
    font-size: min(3.3333333333vw, 2rem);
  }
}
.single-news_cardDesc .single-news_cardname {
  padding: 10px 0 0;
  font-size: min(1.8666666667vw, 2.4rem);
}
@media (min-width: 869px) {
  .single-news_cardDesc .single-news_cardname {
    padding: 15px 0 0;
    font-size: min(1.0666666667vw, 18px);
  }
}
@media (min-width: 869px) {
  .single-news_card:nth-child(n+4) {
    margin: 0 0 16vw;
  }
}
.single-contact {
  margin-top: 80px;
  position: relative;
  z-index: 1;
}
@media (max-width: 868px) {
  .single-contact {
    padding: 5.3333333333vw 0;
  }
}
.single-contact_title {
  text-align: center;
  padding: 2.6666666667vw 0;
  color: #fff !important;
}
.single-contact_title::before {
  background: #fff !important;
}
.single-contact_content {
  padding: 2.6666666667vw 0 0;
}
.single-contact_head {
  color: #fff;
  text-align: center;
  line-height: 1.5;
  margin: 30px 0;
  font-size: 4.6666666667vw;
}
@media (min-width: 869px) {
  .single-contact_head {
    margin-bottom: 40px;
    font-size: max(1.3333333333vw, 26px);
    margin: 30px 0;
  }
}
.single-contact_inner {
  justify-content: space-between;
  padding: 4vw 0;
}
@media (max-width: 868px) {
  .single-contact_inner {
    display: block;
  }
}
.single-contact_item {
  position: relative;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  background-color: #FFF3D7;
  padding: 15px 10px;
}
@media (min-width: 869px) {
  .single-contact_item {
    width: calc(48% - 8px);
    padding: 15px 15px 10px;
  }
}
.single-contact_item p {
  color: #333;
  text-align: center;
  font-size: min(1.4666666667vw, 16px);
  line-height: 1.5;
}
@media (max-width: 868px) {
  .single-contact_item p {
    font-size: 2.6666666667vw;
  }
}
@media (max-width: 320px) {
  .single-contact_item p {
    font-size: 3.3333333333vw;
  }
}
@media (max-width: 868px) {
  .single-contact_item + .single-contact_item {
    margin-top: 13.3333333333vw;
  }
}
@media (max-width: 520px) {
  .single-contact_item + .single-contact_item {
    margin-top: 20vw;
  }
}
.single-contact_btn_txt {
  position: absolute;
  top: -35px;
  background-color: #fff;
  font-weight: bold;
  display: block;
  width: 95%;
  font-size: max(1.0666666667vw, 16px);
  padding: 10px;
  filter: drop-shadow(2px 4px 5px rgba(0, 0, 0, 0.3));
}
.single-contact_btn_txt::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -20px;
  border: 12px solid transparent;
  border-top: 18px solid #fff;
}
@media (max-width: 868px) {
  .single-contact_btn_txt {
    top: -5.0666666667vw;
    left: 15px;
  }
}
@media (max-width: 520px) {
  .single-contact_btn_txt {
    top: -3.7333333333vw;
    left: 10px;
    padding: 5px 10px;
  }
  .single-contact_btn_txt::before {
    border: 10px solid transparent;
    border-top: 14px solid #fff;
    left: 52%;
  }
}
@media (max-width: 320px) {
  .single-contact_btn_txt {
    top: -6.6666666667vw;
  }
}
.single-contact_btn {
  margin: 20px auto 10px;
  max-width: 500px;
}
.single-contact_btn a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-radius: 20px;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  font-size: 3.3333333333vw;
  font-weight: bold;
  color: #333 !important;
}
.single-contact_btn a::after {
  content: "";
  width: 8px;
  height: 8px;
  border: 0;
  border-top: solid 2px #333;
  border-right: solid 2px #333;
  position: absolute;
  top: 50%;
  right: 30px;
  margin-top: -4px;
  transform: rotate(45deg);
}
@media (max-width: 520px) {
  .single-contact_btn a::after {
    width: 6px;
    height: 6px;
    right: 10px;
  }
}
@media (min-width: 869px) {
  .single-contact_btn a {
    font-size: min(1.6vw, 22px);
  }
}
@media (max-width: 520px) {
  .single-contact_btn a {
    font-size: 4.6666666667vw;
    height: 40px;
  }
}

.index-mv {
  position: relative;
  background-image: url(../images/background_mvsp.png);
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
}
@media (min-width: 869px) {
  .index-mv {
    background-image: url(../images/background_mv1.png);
    background-position: center;
  }
}
.index-mv_wrap {
  height: 160vw;
}
@media (min-width: 869px) {
  .index-mv_wrap {
    height: 56vw;
  }
}
@media (max-width: 520px) {
  .index-mv_wrap {
    height: 206.6666666667vw;
  }
}
@media (max-width: 320px) {
  .index-mv_wrap {
    height: 70vh;
  }
}
.index-mv-inner {
  position: relative;
  justify-content: center;
  flex-flow: column;
  padding-top: 90px;
}
@media (min-width: 869px) {
  .index-mv-inner {
    height: inherit;
    padding: 0.6666666667vw 0;
    flex-flow: row;
    margin-top: 0;
  }
  .index-mv-inner::after {
    position: absolute;
    top: 56%;
    width: 80%;
    height: 50px;
    background: url(../images/border_home.png) no-repeat center;
    background-size: 100% auto;
    content: "";
    z-index: 1;
  }
}
.index-mv-ttl {
  position: relative;
  text-align: left;
  line-height: 1.5;
  padding-top: 80px;
  width: 100%;
  padding: 10.6666666667vw 10.6666666667vw 0;
}
@media (min-width: 869px) {
  .index-mv-ttl {
    width: 38%;
    padding: 120px 0 0;
    margin-right: 50px;
    border: none;
  }
  .index-mv-ttl::before {
    content: none;
  }
}
.index-mv-ttl h1 {
  font-size: 6.6666666667vw;
  padding-bottom: 10px;
}
@media (min-width: 869px) {
  .index-mv-ttl h1 {
    padding-bottom: 20px;
    font-size: 3.3333333333vw;
  }
}
.index-mv-ttl p {
  position: relative;
  font-size: 1.4rem;
  padding-top: 20px;
  border-top: 5px solid #333;
}
.index-mv-ttl p::before {
  position: absolute;
  content: "";
  width: 50%;
  left: 0;
  top: -5px;
  border-top: 6px solid #FFC700;
}
@media (min-width: 869px) {
  .index-mv-ttl p {
    border-top: none;
    font-size: 0.9333333333vw;
  }
  .index-mv-ttl p::before {
    content: none;
  }
}
@media (max-width: 520px) {
  .index-mv-ttl p {
    padding-top: 1.3333333333vw;
    font-size: 2.6666666667vw;
  }
}
.index-mv_img {
  position: relative;
  width: 66.6666666667vw;
  margin-bottom: 5.3333333333vw;
  z-index: 10;
}
@media (min-width: 869px) {
  .index-mv_img {
    width: 30%;
    margin-bottom: 0;
  }
}
@media (max-width: 520px) {
  .index-mv_img {
    margin-bottom: 8vw;
  }
}
.index-mv_btncontainer {
  margin-top: 30px;
}
@media (max-width: 868px) {
  .index-mv_btncontainer.pc {
    display: none;
  }
}
@media (max-width: 868px) {
  .index-mv_btncontainer.sp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    padding: 0 13.3333333333vw 0;
  }
}
@media (max-width: 520px) {
  .index-mv_btncontainer.sp {
    display: block;
    padding: 0 13.3333333333vw 0;
  }
}
@media (min-width: 869px) {
  .index-mv_btncontainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.index-mv_btn {
  -webkit-box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
}
@media (max-width: 868px) {
  .index-mv_btn {
    width: 48%;
  }
}
@media (max-width: 520px) {
  .index-mv_btn {
    width: 100%;
  }
}
.index-mv_btn a {
  font-weight: bold;
  font-size: max(3.7333333333vw, 12px);
  color: #333;
  height: auto;
  padding: 15px 0;
  justify-content: center;
}
@media (min-width: 869px) {
  .index-mv_btn a {
    font-size: max(0.9333333333vw, 20px);
    width: 220px;
    height: 50px;
  }
}
@media (min-width: 869px) {
  .index-mv_btn + .index-mv_btn {
    margin-left: 40px;
  }
}
@media (max-width: 520px) {
  .index-mv_btn + .index-mv_btn {
    margin-top: 4vw;
  }
}
.index-concept {
  position: relative;
  background-color: #F9F9F9;
  padding: 60px 0 29.3333333333vw;
  z-index: 1;
}
@media (min-width: 869px) {
  .index-concept {
    padding: 100px 0;
  }
}
.index-concept::before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  border-style: solid;
  bottom: 0;
  border-color: transparent #fff transparent;
  border-width: 20vw 50vw 0;
}
@media (min-width: 869px) {
  .index-concept::before {
    border-width: 8vw 50vw 0;
  }
}
.index-concept_imgBox {
  margin-top: 5.3333333333vw;
}
@media (min-width: 869px) {
  .index-concept_imgBox {
    margin-top: 60px;
  }
}
.index-concept_img img {
  max-width: 500px;
  margin: 0 auto;
}
@media (min-width: 869px) {
  .index-concept_img img {
    width: 700px;
  }
}
.index-concept-body {
  display: block;
  position: relative;
}
@media (min-width: 869px) {
  .index-concept-body {
    text-align: center;
    margin-top: 4vw;
  }
}
.index-concept-body p {
  font-size: 4vw;
  margin-top: 4vw;
}
@media (min-width: 869px) {
  .index-concept-body p {
    font-size: 2rem;
  }
}
.index-service {
  padding: 6.6666666667vw 0 10.6666666667vw;
  position: relative;
  z-index: 1;
}
@media (min-width: 869px) {
  .index-service {
    padding: 6.6666666667vw 0 0;
  }
}
.index-service_content {
  margin-top: 16vw;
}
@media (min-width: 869px) {
  .index-service_content {
    margin-top: 0;
  }
}
@media (min-width: 869px) {
  .index-service_list {
    margin: 4vw 0;
  }
}
.index-service_inner {
  position: relative;
  justify-content: flex-end;
}
@media (max-width: 868px) {
  .index-service_inner {
    flex-flow: column;
  }
}
.index-service_item {
  position: relative;
  background: rgb(249, 249, 249);
}
@media (min-width: 869px) {
  .index-service_item {
    padding: 4vw 0 1.3333333333vw;
    background: linear-gradient(270deg, rgb(249, 249, 249) 30%, rgb(249, 249, 249) 65%, rgba(255, 255, 255, 0) 60%);
  }
}
.index-service_item:first-child .index-service_inner::before {
  position: absolute;
  top: 5%;
  left: -25%;
  width: 400px;
  height: 354px;
  background: url(../images/background_service01.png) no-repeat left top;
  background-size: 400px auto;
  content: "";
}
@media (max-width: 868px) {
  .index-service_item:first-child .index-service_inner::before {
    top: 75%;
    left: -35%;
  }
}
@media (max-width: 520px) {
  .index-service_item:first-child .index-service_inner::before {
    top: 70%;
    left: -40%;
    width: 300px;
    height: 454px;
    background-size: 300px auto;
  }
}
@media (max-width: 320px) {
  .index-service_item:first-child .index-service_inner::before {
    content: none;
  }
}
.index-service_item:first-child .index-service_inner::after {
  position: absolute;
  top: -220%;
  right: -35%;
  width: 500px;
  height: 700px;
  background: url(../images/background_service1.png) no-repeat left top;
  background-size: 500px auto;
  content: "";
}
@media (max-width: 868px) {
  .index-service_item:first-child .index-service_inner::after {
    top: -80%;
    width: 400px;
    height: 600px;
    background-size: 400px auto;
  }
}
@media (max-width: 520px) {
  .index-service_item:first-child .index-service_inner::after {
    top: -80%;
    width: 300px;
    height: 500px;
    background-size: 300px auto;
  }
}
@media (max-width: 320px) {
  .index-service_item:first-child .index-service_inner::after {
    content: none;
  }
}
.index-service_item:nth-child(2n) {
  margin: 16vw 0;
}
@media (min-width: 869px) {
  .index-service_item:nth-child(2n) {
    margin: 4vw 0;
  }
}
@media (min-width: 869px) {
  .index-service_item:nth-child(2n) {
    background: linear-gradient(90deg, rgb(249, 249, 249) 30%, rgb(249, 249, 249) 70%, rgba(255, 255, 255, 0) 60%);
  }
}
.index-service_item:nth-child(2n) .index-service_inner {
  flex-flow: column;
}
@media (min-width: 869px) {
  .index-service_item:nth-child(2n) .index-service_inner {
    flex-flow: row-reverse;
  }
}
@media (min-width: 869px) {
  .index-service_item:nth-child(2n) .index-service_img {
    width: 35%;
  }
}
.index-service_item:nth-child(2n) .index-service_textBox {
  padding-left: 0;
}
@media (min-width: 869px) {
  .index-service_item:nth-child(2n) .index-service_textBox {
    padding-right: 2.6666666667vw;
  }
}
.index-service_item:last-child .index-service_inner::before {
  position: absolute;
  top: 10%;
  left: -30%;
  width: 450px;
  height: 704px;
  background: url(../images/background_service03.png) no-repeat left top;
  background-size: 450px auto;
  content: "";
  z-index: 1;
}
@media (max-width: 868px) {
  .index-service_item:last-child .index-service_inner::before {
    left: -60%;
  }
}
@media (max-width: 520px) {
  .index-service_item:last-child .index-service_inner::before {
    top: 60%;
    left: -45%;
    width: 300px;
    height: 504px;
    background-size: 300px auto;
  }
}
@media (max-width: 320px) {
  .index-service_item:last-child .index-service_inner::before {
    content: none;
  }
}
.index-service_img {
  position: relative;
  width: 80%;
  margin-top: -40px;
  z-index: 1;
}
@media (min-width: 869px) {
  .index-service_img {
    width: 30%;
  }
}
.index-service_textBox {
  position: relative;
  margin-top: 2.6666666667vw;
  z-index: 1;
}
@media (min-width: 869px) {
  .index-service_textBox {
    padding-left: 4vw;
    width: 65%;
    margin-top: 0;
  }
}
.index-service_textBox .number {
  font-weight: bold;
  font-size: max(8vw, 6rem);
  display: block;
  margin-bottom: 10px;
}
@media (min-width: 869px) {
  .index-service_textBox .number {
    font-size: max(2.6666666667vw, 5rem);
  }
}
.index-service_textBox p {
  font-size: 4vw;
}
@media (min-width: 869px) {
  .index-service_textBox p {
    font-size: 2rem;
  }
}
.index-service_ttl {
  margin: 1.3333333333vw 0 2.4vw;
  font-size: max(4vw, 20px);
}
@media (min-width: 869px) {
  .index-service_ttl {
    margin: 0.6666666667vw 0 1.3333333333vw;
    font-size: 3rem;
  }
}
.index-service_slider-item {
  position: relative;
}
.index-service_slider-item a {
  color: #fff;
  display: block;
}
.index-service_slider_cont {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(30%, rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.6)));
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.6));
  padding: 88px 24px 24px;
}
.index-service_slider_ttl {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.index-service_slider_ttl::before {
  content: "";
  display: block;
  width: 56px;
  height: 1px;
  background: #fff !important;
  position: absolute;
  top: unset !important;
  left: 0 !important;
  bottom: 0 !important;
  opacity: 1;
  -webkit-transition: 0.4s;
}
.index-service_slider_ttl .en {
  color: #fff;
}
@media (min-width: 869px) {
  .index-service_slider_ttl .en {
    font-size: 24px !important;
    letter-spacing: 3px;
    margin-bottom: 12px;
    font-weight: lighter;
    text-transform: uppercase;
  }
}
.index-service_slider-txt {
  font-size: 11px;
  color: #fff;
  line-height: 1.5;
}
.index-works {
  padding: 8vw 0;
}
@media (min-width: 869px) {
  .index-works {
    padding: 2.6666666667vw 0 1.3333333333vw;
  }
}
.index-works .ttl {
  margin-bottom: 5.3333333333vw;
}
@media (min-width: 869px) {
  .index-works .ttl {
    margin-bottom: 40px;
  }
}
@media (max-width: 868px) {
  .index-works .sub_ttl {
    font-size: max(2.6666666667vw, 16px);
  }
}
.index-works_loopSlide {
  position: relative;
  display: flex;
  overflow: hidden;
  padding: 2.6666666667vw 0 0;
  z-index: 1;
}
.index-works_loopSlide ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  animation: infinity-scroll-right 20s infinite linear 0.5s both;
}
.index-works_loopSlide ul li {
  width: 33.3333333333vw;
  padding-right: 2vw;
}
@media (min-width: 869px) {
  .index-works_loopSlide ul li {
    width: 12.5vw;
  }
}
.index-works_loopSlide ul li img {
  display: block;
  width: 100%;
  height: auto;
}
@media (min-width: 869px) {
  .index-works_loopSlide ul li img {
    object-fit: cover;
  }
}
@keyframes infinity-scroll-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
.index-works_loopSlide:nth-of-type(2) ul {
  margin-left: 40px;
}
.index-works_loopSlide:nth-of-type(2) ul:nth-of-type(2) {
  margin-left: 0;
}
.index-works_loopSlide:nth-of-type(2) ul:last-child {
  margin-left: 0;
}
.index-works_categories {
  margin-top: 6.6666666667vw;
}
.index-works_categories .inner {
  display: flex;
}
@media (min-width: 869px) {
  .index-works_categories .inner {
    justify-content: flex-start;
  }
}
@media (max-width: 868px) {
  .index-works_categories .inner {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 520px) {
  .index-works_categories .inner {
    display: block;
  }
}
@media (max-width: 520px) {
  .index-works_categories {
    margin-top: 10.6666666667vw;
  }
}
.index-works_tab_btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  border: 0.1rem solid #FFC700;
  background-color: #fff;
  transition: all 0.2s;
  cursor: pointer;
  font-size: max(3.7333333333vw, 12px);
  font-weight: bold;
}
@media (min-width: 869px) {
  .index-works_tab_btn {
    font-size: max(0.9333333333vw, 20px);
    width: 20%;
  }
}
@media (max-width: 868px) {
  .index-works_tab_btn {
    width: 46%;
  }
  .index-works_tab_btn:nth-child(2n) {
    margin-left: 20px;
  }
}
@media (max-width: 520px) {
  .index-works_tab_btn {
    width: 100%;
  }
  .index-works_tab_btn:nth-child(2n) {
    margin-left: 0px;
  }
}
@media (min-width: 869px) {
  .index-works_tab_btn:not(:last-of-type) {
    margin-right: 1em;
  }
}
@media (max-width: 868px) {
  .index-works_tab_btn:not(:last-of-type) {
    margin-bottom: 26px;
  }
}
.index-works_tab_btn.active {
  color: #333;
  background-color: #FFC700;
  border: 1px solid #FFC700;
}
.index-works_inner {
  position: relative;
  margin-top: 2.6666666667vw;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 520px) {
  .index-works_inner {
    margin-top: 13.3333333333vw;
    display: block;
  }
}
.index-works_inner::before {
  position: absolute;
  bottom: -50%;
  left: -50%;
  width: 500px;
  height: 604px;
  background: url(../images/service_accordionimg.png) no-repeat left top;
  background-size: 450px auto;
  content: "";
}
@media (max-width: 868px) {
  .index-works_inner::before {
    bottom: -50%;
    left: -70%;
    width: 350px;
    height: 450px;
    background-size: 350px auto;
  }
}
@media (max-width: 520px) {
  .index-works_inner::before {
    bottom: -45%;
    left: -80%;
    width: 250px;
    height: 350px;
    background-size: 250px auto;
  }
}
@media (max-width: 320px) {
  .index-works_inner::before {
    content: none;
  }
}
.index-works_card {
  height: inherit;
  background: #fff;
  position: relative;
  padding-bottom: 40px;
  margin: 0 0 16vw;
  width: calc(50% - 10px);
}
@media (min-width: 869px) {
  .index-works_card {
    padding: 0;
    width: calc(33.333% - 25px);
  }
  .index-works_card + .index-works_card {
    margin-left: 20px;
  }
  .index-works_card:nth-child(n+4) {
    margin-bottom: 10.6666666667vw;
  }
  .index-works_card:nth-child(4n) {
    margin-left: 0;
  }
}
@media (max-width: 868px) {
  .index-works_card {
    margin: 0 0 26.6666666667vw;
  }
  .index-works_card:nth-child(2n) {
    margin-left: 20px;
  }
}
@media (max-width: 520px) {
  .index-works_card {
    width: 100%;
    margin: 0 0 46.6666666667vw;
  }
  .index-works_card:nth-child(2n) {
    margin-left: 0px;
  }
}
@media (max-width: 320px) {
  .index-works_card {
    margin: 0 0 53.3333333333vw;
  }
}
.index-works_card a {
  color: #333;
}
.index-works_card a:hover {
  opacity: 1;
}
.index-works_cardImgBox {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
}
.index-works_cardImgBox::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 70%;
}
.index-works_cardImgBox img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
}
.index-works_cardImgBox:hover .index-works_cardImg::after {
  opacity: 1;
}
.index-works_cardImgBox:hover img {
  transform: scale(1.1);
  opacity: 1;
}
.index-works_cardImgBox:hover .index-works_cardlogo img {
  transform: scale(1);
}
.index-works_cardlogo {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
}
.index-works_cardDesc {
  padding: 15px 1.3333333333vw;
  position: absolute;
  width: 95%;
  left: 2.5%;
  top: 65%;
  background: #fff;
  height: inherit;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}
@media (max-width: 868px) {
  .index-works_cardDesc {
    padding: 15px 3.3333333333vw;
  }
}
@media (max-width: 520px) {
  .index-works_cardDesc {
    padding: 15px 4vw;
  }
}
.index-works_cardDesc .index-works_cardTtl {
  font-size: min(1.8666666667vw, 2.6rem);
  letter-spacing: 2px;
  color: #444;
  line-height: 1.5;
  width: 100%;
}
@media (min-width: 869px) {
  .index-works_cardDesc .index-works_cardTtl {
    font-size: min(1.2vw, 1.8rem);
  }
}
@media (max-width: 520px) {
  .index-works_cardDesc .index-works_cardTtl {
    font-size: min(4vw, 2.6rem);
  }
}
.index-works_cardDesc .index-works_cardcont {
  color: #666;
  letter-spacing: 2px;
  font-size: min(1.4666666667vw, 2rem);
  line-height: 1.5;
  text-transform: uppercase;
  margin-top: 0.6666666667vw;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}
@media (min-width: 869px) {
  .index-works_cardDesc .index-works_cardcont {
    font-size: min(1.0666666667vw, 16px);
  }
}
@media (max-width: 520px) {
  .index-works_cardDesc .index-works_cardcont {
    font-size: min(3.3333333333vw, 2rem);
  }
}
.index-works_cardDesc .index-works_cardname {
  padding: 10px 0 0;
  font-size: min(1.8666666667vw, 2.4rem);
}
@media (min-width: 869px) {
  .index-works_cardDesc .index-works_cardname {
    padding: 15px 0 0;
    font-size: min(1.0666666667vw, 18px);
  }
}
@media (max-width: 520px) {
  .index-works_cardDesc .index-works_cardname {
    font-size: min(3.0666666667vw, 2.4rem);
  }
}
.index-news {
  position: relative;
  padding: 13.3333333333vw 0 10.6666666667vw;
}
@media (min-width: 869px) {
  .index-news {
    padding: 2.6666666667vw 0;
  }
}
.index-news_categories {
  margin-top: 6.6666666667vw;
  display: flex;
}
@media (min-width: 869px) {
  .index-news_categories {
    justify-content: flex-start;
  }
}
@media (max-width: 868px) {
  .index-news_categories {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 520px) {
  .index-news_categories {
    display: block;
  }
}
.index-news_tab_btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  border: 0.1rem solid #FFC700;
  transition: all 0.2s;
  cursor: pointer;
  font-size: max(3.7333333333vw, 12px);
  font-weight: bold;
}
@media (min-width: 869px) {
  .index-news_tab_btn {
    font-size: max(0.9333333333vw, 20px);
    width: 20%;
  }
}
@media (max-width: 868px) {
  .index-news_tab_btn {
    width: 46%;
  }
  .index-news_tab_btn:nth-child(2n) {
    margin-left: 20px;
  }
}
@media (max-width: 520px) {
  .index-news_tab_btn {
    width: 100%;
  }
  .index-news_tab_btn:nth-child(2n) {
    margin-left: 0px;
  }
}
@media (min-width: 869px) {
  .index-news_tab_btn:not(:last-of-type) {
    margin-right: 1em;
  }
}
@media (max-width: 868px) {
  .index-news_tab_btn:not(:last-of-type) {
    margin-bottom: 26px;
  }
}
.index-news_tab_btn.active {
  color: #333;
  background-color: #FFC700;
  border: 1px solid #FFC700;
}
.index-news_content {
  position: relative;
  margin-top: 2.6666666667vw;
  border-radius: 20px;
}
@media (min-width: 869px) {
  .index-news_content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
.index-news_content-item a {
  display: block;
  padding: 25px 10px;
  text-decoration: none;
  color: #444;
  position: relative;
}
@media (min-width: 869px) {
  .index-news_content-item a {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 6px 0;
    margin: 6px 0;
  }
}
.index-news_content-itemCategory {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  border: 0.1rem solid #FFC700;
  transition: all 0.2s;
  cursor: pointer;
  font-size: 2vw;
  max-width: 160px;
}
@media (min-width: 869px) {
  .index-news_content-itemCategory {
    font-size: 1.6rem;
    width: 18%;
    margin-right: 2vw;
  }
}
@media (max-width: 520px) {
  .index-news_content-itemCategory {
    font-size: 4vw;
    height: 3rem;
  }
}
.index-news_content-itemDate {
  letter-spacing: 1px;
  font-weight: 700;
  color: #aaa;
  line-height: 1.5;
  font-size: 2vw;
  width: auto;
  font-family: Heebo, sans-serif;
  display: block;
  margin-bottom: 10px;
}
@media (min-width: 869px) {
  .index-news_content-itemDate {
    font-size: 1.6rem;
    width: 15%;
  }
}
@media (max-width: 520px) {
  .index-news_content-itemDate {
    font-size: 4vw;
  }
}
.index-news_content-itemTtl {
  font-weight: 400;
  margin-right: auto;
  line-height: 1.5;
  font-size: 2.4vw;
}
@media (min-width: 869px) {
  .index-news_content-itemTtl {
    padding: 20px 0;
    font-size: 2rem;
    text-overflow: ellipsis;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
@media (max-width: 520px) {
  .index-news_content-itemTtl {
    font-size: 4.6666666667vw;
  }
}
.index-news_content-itemTtl:hover {
  text-decoration: underline;
}
.index-news_content-itemTtl .headline {
  font-weight: bold;
}
.index-news_content-itemTtl .desc {
  line-height: 1.5;
}
.index-news_content-item:nth-child(2) {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.index-news_content-item:nth-child(3) {
  border-bottom: 1px solid #e6e6e6;
}
.index-news_content::after {
  position: absolute;
  bottom: -50%;
  right: -40%;
  width: 400px;
  height: 600px;
  background: url(../images/service_newsimg.png) no-repeat left top;
  background-size: 400px auto;
  content: "";
}
@media (max-width: 868px) {
  .index-news_content::after {
    content: none;
  }
}
@media (max-width: 520px) {
  .index-news_content::after {
    bottom: -50%;
    right: -35%;
    width: 200px;
    height: 400px;
    background-size: 200px auto;
  }
}
.index-contact {
  margin-top: 80px;
  position: relative;
  z-index: 1;
}
@media (max-width: 868px) {
  .index-contact {
    padding: 5.3333333333vw 0;
  }
}
.index-contact_title {
  text-align: center;
  padding: 2.6666666667vw 0;
  color: #fff !important;
}
.index-contact_title::before {
  background: #fff !important;
}
.index-contact_content {
  padding: 2.6666666667vw 0 0;
}
.index-contact_head {
  color: #fff;
  text-align: center;
  line-height: 1.5;
  margin: 30px 0;
  font-size: 4.6666666667vw;
}
@media (min-width: 869px) {
  .index-contact_head {
    margin-bottom: 40px;
    font-size: max(1.3333333333vw, 26px);
    margin: 30px 0;
  }
}
.index-contact_inner {
  justify-content: space-between;
  padding: 4vw 0;
}
@media (max-width: 868px) {
  .index-contact_inner {
    display: block;
  }
}
.index-contact_item {
  position: relative;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  background-color: #FFF3D7;
  padding: 15px 10px;
}
@media (min-width: 869px) {
  .index-contact_item {
    width: calc(48% - 8px);
    padding: 15px 15px 10px;
  }
}
.index-contact_item p {
  color: #333;
  text-align: center;
  font-size: min(1.4666666667vw, 16px);
  line-height: 1.5;
}
@media (max-width: 868px) {
  .index-contact_item p {
    font-size: 2.6666666667vw;
  }
}
@media (max-width: 320px) {
  .index-contact_item p {
    font-size: 3.3333333333vw;
  }
}
@media (max-width: 868px) {
  .index-contact_item + .index-contact_item {
    margin-top: 13.3333333333vw;
  }
}
@media (max-width: 520px) {
  .index-contact_item + .index-contact_item {
    margin-top: 20vw;
  }
}
.index-contact_btn_txt {
  position: absolute;
  top: -35px;
  background-color: #fff;
  font-weight: bold;
  display: block;
  width: 95%;
  font-size: max(1.0666666667vw, 16px);
  padding: 10px;
  filter: drop-shadow(2px 4px 5px rgba(0, 0, 0, 0.3));
}
.index-contact_btn_txt::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -20px;
  border: 12px solid transparent;
  border-top: 18px solid #fff;
}
@media (max-width: 868px) {
  .index-contact_btn_txt {
    top: -5.0666666667vw;
    left: 15px;
  }
}
@media (max-width: 520px) {
  .index-contact_btn_txt {
    top: -3.7333333333vw;
    left: 10px;
    padding: 5px 10px;
  }
  .index-contact_btn_txt::before {
    border: 10px solid transparent;
    border-top: 14px solid #fff;
    left: 52%;
  }
}
@media (max-width: 320px) {
  .index-contact_btn_txt {
    top: -6.6666666667vw;
  }
}
.index-contact_btn {
  margin: 20px auto 10px;
  max-width: 500px;
}
.index-contact_btn a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-radius: 20px;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  font-size: 3.3333333333vw;
  font-weight: bold;
  color: #333 !important;
}
.index-contact_btn a::after {
  content: "";
  width: 8px;
  height: 8px;
  border: 0;
  border-top: solid 2px #333;
  border-right: solid 2px #333;
  position: absolute;
  top: 50%;
  right: 30px;
  margin-top: -4px;
  transform: rotate(45deg);
}
@media (max-width: 520px) {
  .index-contact_btn a::after {
    width: 6px;
    height: 6px;
    right: 10px;
  }
}
@media (min-width: 869px) {
  .index-contact_btn a {
    font-size: min(1.6vw, 22px);
  }
}
@media (max-width: 520px) {
  .index-contact_btn a {
    font-size: 4.6666666667vw;
    height: 40px;
  }
}

.service-mv {
  position: relative;
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
}
@media (min-width: 869px) {
  .service-mv {
    background-image: url(../images/background-service_mv.png);
    background-position: center;
  }
}
@media (max-width: 868px) {
  .service-mv {
    background-image: url(../images/background_mvsp.png);
  }
}
.service-mv_wrap {
  height: 160vw;
}
@media (min-width: 869px) {
  .service-mv_wrap {
    height: 56vw;
  }
}
@media (max-width: 520px) {
  .service-mv_wrap {
    height: 200vw;
  }
}
@media (max-width: 320px) {
  .service-mv_wrap {
    height: 70vh;
  }
}
.service-mv-inner {
  position: relative;
  justify-content: center;
  flex-flow: column;
  padding-top: 90px;
}
@media (min-width: 869px) {
  .service-mv-inner {
    height: inherit;
    flex-flow: row;
    padding-top: 0;
  }
}
.service-mv-ttl {
  position: relative;
  text-align: center;
  padding-top: 0px;
  width: 100%;
  padding: 10.6666666667vw 10.6666666667vw 0;
}
@media (min-width: 869px) {
  .service-mv-ttl {
    width: 40%;
    padding: 120px 0 0;
    margin-right: 20px;
    border: none;
    text-align: left;
  }
  .service-mv-ttl::before {
    content: none;
  }
}
.service-mv-ttl h1 {
  font-size: 5.8666666667vw;
  padding-bottom: 10px;
  line-height: 1.5;
}
@media (min-width: 869px) {
  .service-mv-ttl h1 {
    padding-bottom: 20px;
    font-size: 2.4vw;
  }
}
.service-mv-ttl .sub {
  display: block;
  font-weight: 300;
  font-size: 3.3333333333vw;
}
@media (min-width: 869px) {
  .service-mv-ttl .sub {
    font-size: 1.6vw;
  }
}
.service-mv-ttl p {
  position: relative;
  font-size: 1.4rem;
  padding-top: 10px;
  border-top: 6px solid #333;
  text-align: left;
}
.service-mv-ttl p::before {
  position: absolute;
  content: "";
  width: 50%;
  left: 0;
  top: -6px;
  border-top: 7px solid #F4F403;
}
@media (min-width: 869px) {
  .service-mv-ttl p {
    border-top: none;
    font-size: 0.9333333333vw;
    padding-top: 30px;
  }
  .service-mv-ttl p::before {
    content: none;
  }
}
@media (max-width: 520px) {
  .service-mv-ttl p {
    padding-top: 1.3333333333vw;
    font-size: 2.6666666667vw;
  }
}
.service-mv_img {
  position: relative;
  width: 80vw;
  margin: 5.3333333333vw 0;
  z-index: 3;
}
@media (min-width: 869px) {
  .service-mv_img {
    width: 38%;
    margin-bottom: 0;
    margin-top: 100px;
  }
}
@media (max-width: 520px) {
  .service-mv_img {
    margin-bottom: 8vw;
  }
}
.service-mv_btncontainer {
  margin-top: 30px;
}
@media (max-width: 868px) {
  .service-mv_btncontainer.pc {
    display: none;
  }
}
@media (max-width: 868px) {
  .service-mv_btncontainer.sp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    padding: 0 13.3333333333vw 0;
  }
}
@media (max-width: 520px) {
  .service-mv_btncontainer.sp {
    display: block;
    padding: 0 13.3333333333vw 0;
  }
}
@media (min-width: 869px) {
  .service-mv_btncontainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.service-mv_btn {
  -webkit-box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
  width: 48%;
  max-width: 250px;
}
@media (max-width: 868px) {
  .service-mv_btn {
    width: 48%;
    max-width: 100%;
  }
}
@media (max-width: 520px) {
  .service-mv_btn {
    width: 100%;
  }
}
.service-mv_btn a {
  font-weight: bold;
  font-size: max(3.7333333333vw, 12px);
  height: auto;
  padding: 15px 0;
  justify-content: center;
}
@media (min-width: 869px) {
  .service-mv_btn a {
    font-size: max(0.9333333333vw, 18px);
    height: 50px;
  }
}
@media (min-width: 869px) {
  .service-mv_btn + .service-mv_btn {
    margin-left: 40px;
  }
}
@media (max-width: 520px) {
  .service-mv_btn + .service-mv_btn {
    margin-top: 4vw;
  }
}
.service-concept {
  position: relative;
  background-color: #F9F9F9;
  padding: 60px 0 29.3333333333vw;
  z-index: 1;
}
@media (min-width: 869px) {
  .service-concept {
    padding: 100px 0;
  }
}
.service-concept::before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  border-style: solid;
  bottom: 0;
  border-color: transparent #fff transparent;
  border-width: 20vw 50vw 0;
}
@media (min-width: 869px) {
  .service-concept::before {
    border-width: 8vw 50vw 0;
  }
}
@media (min-width: 869px) {
  .service-concept_wrap {
    margin-top: 2.6666666667vw;
  }
}
@media (min-width: 869px) {
  .service-concept_List {
    justify-content: space-between;
    align-items: baseline;
  }
}
@media (max-width: 868px) {
  .service-concept_List {
    margin-top: 10.6666666667vw;
    display: block;
  }
}
@media (min-width: 869px) {
  .service-concept_item {
    width: calc(33.333% - 30px);
  }
  .service-concept_item:first-child .service-concept_img img {
    width: 100px;
  }
  .service-concept_item:nth-child(2n) .service-concept_img img {
    width: 120px;
  }
}
@media (max-width: 868px) {
  .service-concept_item {
    margin: 6.6666666667vw 0 0;
  }
}
.service-concept_img {
  display: block;
  margin: 0 auto;
}
@media (max-width: 868px) {
  .service-concept_img {
    width: 40%;
  }
}
.service-concept_img img {
  object-fit: cover;
  margin: 0 auto;
}
@media (min-width: 869px) {
  .service-concept_img img {
    width: 180px;
  }
}
.service-concept_ttl {
  line-height: 1.5;
  margin: 10px 0;
  font-size: 4.6666666667vw;
  text-align: center;
}
@media (min-width: 869px) {
  .service-concept_ttl {
    font-size: max(0.4vw, 18px);
  }
}
.service-concept_cont {
  margin: 15px 0;
}
@media (max-width: 868px) {
  .service-concept_cont {
    font-size: 3.7333333333vw;
  }
}
.service-concept_cont li {
  line-height: 1.5;
}
.service-melit {
  position: relative;
}
@media (max-width: 868px) {
  .service-melit {
    margin-top: 6.6666666667vw;
  }
}
.service-melit_ttl {
  position: relative;
  font-weight: lighter;
  line-height: 1.5;
  border-radius: 20px;
  padding: 2vw 10px;
  filter: drop-shadow(2px 4px 5px rgba(0, 0, 0, 0.3));
  text-align: center;
  font-size: 3.4666666667vw;
}
@media (min-width: 869px) {
  .service-melit_ttl {
    font-size: 30px;
    max-width: 700px;
    margin: 4vw auto 2.4vw;
    padding: 0.6666666667vw;
  }
}
.service-melit_ttl::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -20px;
  border: 18px solid transparent;
}
.service-melit_imgBox {
  position: relative;
  margin: 5.3333333333vw auto;
}
@media (min-width: 869px) {
  .service-melit_imgBox {
    max-width: 430px;
  }
}
.service-melit_imgBox::after {
  position: absolute;
  top: -65%;
  right: -190%;
  width: 550px;
  height: 780px;
  background: url(../images/background_service1.png) no-repeat left top;
  background-size: 550px auto;
  content: "";
}
@media (max-width: 320px) {
  .service-melit_imgBox::after {
    content: none;
  }
}
.service-melit-body {
  position: relative;
  margin: 20px auto 0;
  text-align: center;
}
@media (min-width: 869px) {
  .service-melit-body {
    max-width: 800px;
  }
}
.service-melit-body p {
  font-size: 4vw;
}
@media (min-width: 869px) {
  .service-melit-body p {
    font-size: 2rem;
  }
}
.service-melit-body::before {
  position: absolute;
  top: -50%;
  left: -88%;
  width: 450px;
  height: 554px;
  background: url(../images/background_service01.png) no-repeat left top;
  background-size: 450px auto;
  content: "";
  z-index: -1;
}
@media (max-width: 520px) {
  .service-melit-body::before {
    content: none;
  }
}
.service-section {
  padding: 16vw 0 10.6666666667vw;
  position: relative;
  z-index: 1;
}
@media (min-width: 869px) {
  .service-section {
    padding: 8vw 0 0vw;
  }
}
.service-section_content {
  margin-top: 16vw;
}
@media (min-width: 869px) {
  .service-section_content {
    margin-top: 0;
  }
}
@media (min-width: 869px) {
  .service-section_list {
    margin: 5.3333333333vw 0;
  }
}
.service-section_inner {
  position: relative;
  justify-content: flex-end;
}
@media (max-width: 868px) {
  .service-section_inner {
    padding-top: 5.3333333333vw;
    flex-flow: column;
  }
}
.service-section_item {
  position: relative;
  background: rgb(249, 249, 249);
  padding: 0vw 0 6.6666666667vw;
}
@media (min-width: 869px) {
  .service-section_item {
    padding: 4vw 0 8.6666666667vw;
    background: linear-gradient(270deg, rgb(249, 249, 249) 30%, rgb(249, 249, 249) 70%, rgba(255, 255, 255, 0) 60%);
  }
}
.service-section_item:first-child .service-section_inner::before {
  position: absolute;
  top: 5%;
  left: -25%;
  width: 400px;
  height: 354px;
  background: url(../images/background_service01.png) no-repeat left top;
  background-size: 400px auto;
  content: "";
  z-index: 1;
}
@media (max-width: 868px) {
  .service-section_item:first-child .service-section_inner::before {
    top: 75%;
    left: -35%;
  }
}
@media (max-width: 520px) {
  .service-section_item:first-child .service-section_inner::before {
    top: 70%;
    left: -40%;
    width: 300px;
    height: 454px;
    background-size: 300px auto;
  }
}
@media (max-width: 320px) {
  .service-section_item:first-child .service-section_inner::before {
    content: none;
  }
}
@media (max-width: 868px) {
  .service-section_item:first-child .service-section_inner::after {
    position: absolute;
    top: -80%;
    right: -35%;
    width: 400px;
    height: 600px;
    background: url(../images/background_service1.png) no-repeat left top;
    background-size: 500px auto;
    background-size: 400px auto;
    content: "";
  }
}
@media (max-width: 520px) {
  .service-section_item:first-child .service-section_inner::after {
    top: -80%;
    width: 300px;
    height: 500px;
    background-size: 300px auto;
  }
}
@media (max-width: 320px) {
  .service-section_item:first-child .service-section_inner::after {
    content: none;
  }
}
.service-section_item:nth-child(2n) {
  margin: 16vw 0;
}
@media (min-width: 869px) {
  .service-section_item:nth-child(2n) {
    margin: 4vw 0;
  }
}
@media (min-width: 869px) {
  .service-section_item:nth-child(2n) {
    background: linear-gradient(90deg, rgb(249, 249, 249) 30%, rgb(249, 249, 249) 70%, rgba(255, 255, 255, 0) 60%);
  }
}
.service-section_item:nth-child(2n) .service-section_inner {
  flex-flow: column;
}
@media (min-width: 869px) {
  .service-section_item:nth-child(2n) .service-section_inner {
    flex-flow: row-reverse;
  }
}
.service-section_item:nth-child(2n) .service-section_textBox {
  padding-left: 0;
}
@media (min-width: 869px) {
  .service-section_item:nth-child(2n) .service-section_textBox {
    padding-right: 2.6666666667vw;
  }
}
.service-section_item:last-child .service-section_inner::before {
  position: absolute;
  top: 10%;
  left: -30%;
  width: 450px;
  height: 704px;
  background: url(../images/background_service03.png) no-repeat left top;
  background-size: 450px auto;
  content: "";
  z-index: -1;
}
@media (max-width: 868px) {
  .service-section_item:last-child .service-section_inner::before {
    top: 90%;
    left: -45%;
    width: 300px;
    height: 504px;
    background-size: 300px auto;
  }
}
.service-section_img {
  width: 50%;
}
@media (min-width: 869px) {
  .service-section_img {
    width: 18%;
  }
}
.service-section_textBox {
  position: relative;
  margin-top: 2.6666666667vw;
  color: #404040;
  opacity: 0.8;
  z-index: 1;
}
@media (min-width: 869px) {
  .service-section_textBox {
    padding-left: 3.3333333333vw;
    width: 60%;
    margin-top: 0;
  }
}
.service-section_textBox .number {
  font-weight: bold;
  font-size: max(7.7333333333vw, 5rem);
  display: block;
  margin-bottom: 10px;
}
@media (min-width: 869px) {
  .service-section_textBox .number {
    font-size: max(2.6666666667vw, 5rem);
  }
}
.service-section_textBox p {
  font-size: 4.4vw;
}
@media (min-width: 869px) {
  .service-section_textBox p {
    font-size: 2rem;
  }
}
.service-section_ttl {
  margin: 1.3333333333vw 0 2.4vw;
  font-size: max(6.6666666667vw, 20px);
}
@media (min-width: 869px) {
  .service-section_ttl {
    margin: 0.6666666667vw 0 1.3333333333vw;
    font-size: 3rem;
    line-height: 1.5;
  }
}
.service-section_slider-item {
  position: relative;
}
.service-section_slider-item a {
  color: #fff;
  display: block;
}
.service-section_slider_cont {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(30%, rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.6)));
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.6));
  padding: 88px 24px 24px;
}
.service-section_slider_ttl {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.service-section_slider_ttl::before {
  content: "";
  display: block;
  width: 56px;
  height: 1px;
  background: #fff !important;
  position: absolute;
  top: unset !important;
  left: 0 !important;
  bottom: 0 !important;
  opacity: 1;
  -webkit-transition: 0.4s;
}
.service-section_slider_ttl .en {
  color: #fff;
}
@media (min-width: 869px) {
  .service-section_slider_ttl .en {
    font-size: 24px !important;
    letter-spacing: 3px;
    margin-bottom: 12px;
    font-weight: lighter;
    text-transform: uppercase;
  }
}
.service-section_slider-txt {
  font-size: 11px;
  color: #fff;
  line-height: 1.5;
}
.service-works {
  padding: 8vw 0;
}
@media (min-width: 869px) {
  .service-works {
    padding: 5.3333333333vw 0 2.6666666667vw;
  }
}
.service-works .ttl {
  margin-bottom: 40px;
}
@media (min-width: 869px) {
  .service-works .ttl {
    margin-bottom: 40px;
  }
}
.service-works_loopSlide {
  display: flex;
  overflow: hidden;
  padding: 2.6666666667vw 0 0;
}
.service-works_loopSlide ul {
  display: flex;
  list-style: none;
  padding: 0;
  animation: infinity-scroll-right 20s infinite linear 0.5s both;
}
.service-works_loopSlide ul li {
  width: 33.3333333333vw;
  padding-right: 2vw;
}
@media (min-width: 869px) {
  .service-works_loopSlide ul li {
    width: 16.6666666667vw;
  }
}
.service-works_loopSlide ul li img {
  display: block;
  width: 100%;
  height: auto;
}
@keyframes infinity-scroll-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
.service-works_loopSlide:nth-of-type(2) ul {
  margin-left: 40px;
}
.service-works_loopSlide:nth-of-type(2) ul:nth-of-type(2) {
  margin-left: 0;
}
.service-works_loopSlide:nth-of-type(2) ul:last-child {
  margin-left: 0;
}
.service-works_inner {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.service-works_inner::before {
  position: absolute;
  bottom: 0;
  left: -38%;
  width: 380px;
  height: 554px;
  background: url(../images/service_worksimg.png) no-repeat left top;
  background-size: 380px auto;
  content: "";
}
@media (max-width: 868px) {
  .service-works_inner::before {
    content: none;
  }
}
.service-works_inner::after {
  position: absolute;
  top: -30%;
  right: -52%;
  width: 480px;
  height: 680px;
  background: url(../images/service_worksimg02.png) no-repeat left top;
  background-size: 480px auto;
  content: "";
  z-index: -1;
}
@media (max-width: 868px) {
  .service-works_inner::after {
    content: none;
  }
}
@media (max-width: 520px) {
  .service-works_inner {
    display: block;
  }
}
@media (min-width: 869px) {
  .service-works_inner {
    margin-top: 40px;
  }
}
.service-works_card {
  height: inherit;
  background: #fff;
  position: relative;
  padding-bottom: 40px;
  margin: 0 0 16vw;
  width: calc(50% - 10px);
  z-index: 1;
}
@media (min-width: 869px) {
  .service-works_card {
    padding: 0;
    width: calc(33.333% - 25px);
  }
  .service-works_card + .service-works_card {
    margin-left: 20px;
  }
  .service-works_card:nth-child(n+4) {
    margin-bottom: 10.6666666667vw;
  }
  .service-works_card:nth-child(4n) {
    margin-left: 0;
  }
}
@media (max-width: 868px) {
  .service-works_card {
    margin: 0 0 26.6666666667vw;
  }
  .service-works_card:nth-child(2n) {
    margin-left: 20px;
  }
}
@media (max-width: 520px) {
  .service-works_card {
    width: 100%;
    margin: 0 0 46.6666666667vw;
  }
  .service-works_card:nth-child(2n) {
    margin-left: 0px;
  }
}
@media (max-width: 320px) {
  .service-works_card {
    margin: 0 0 53.3333333333vw;
  }
}
.service-works_card a {
  color: #333;
}
.service-works_card a:hover {
  opacity: 1;
}
.service-works_cardImgBox {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
}
.service-works_cardImgBox::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 70%;
}
.service-works_cardImgBox img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
}
.service-works_cardImgBox:hover .service-works_cardImg::after {
  opacity: 1;
}
.service-works_cardImgBox:hover img {
  transform: scale(1.1);
  opacity: 1;
}
.service-works_cardImgBox:hover .service-works_cardlogo img {
  transform: scale(1);
}
.service-works_cardlogo {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
}
.service-works_cardDesc {
  padding: 15px 1.3333333333vw;
  position: absolute;
  width: 95%;
  left: 2.5%;
  top: 65%;
  background: #fff;
  height: inherit;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}
@media (max-width: 868px) {
  .service-works_cardDesc {
    padding: 15px 3.3333333333vw;
  }
}
@media (max-width: 520px) {
  .service-works_cardDesc {
    padding: 15px 4vw;
  }
}
.service-works_cardDesc .service-works_cardTtl {
  font-size: min(1.8666666667vw, 2.6rem);
  letter-spacing: 2px;
  color: #444;
  line-height: 1.5;
  width: 100%;
}
@media (min-width: 869px) {
  .service-works_cardDesc .service-works_cardTtl {
    font-size: min(1.2vw, 1.8rem);
  }
}
@media (max-width: 520px) {
  .service-works_cardDesc .service-works_cardTtl {
    font-size: min(4vw, 2.6rem);
  }
}
.service-works_cardDesc .service-works_cardcont {
  color: #666;
  letter-spacing: 2px;
  font-size: min(1.4666666667vw, 2rem);
  line-height: 1.5;
  text-transform: uppercase;
  margin-top: 0.6666666667vw;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}
@media (min-width: 869px) {
  .service-works_cardDesc .service-works_cardcont {
    font-size: min(1.0666666667vw, 16px);
  }
}
@media (max-width: 520px) {
  .service-works_cardDesc .service-works_cardcont {
    font-size: min(3.3333333333vw, 2rem);
  }
}
.service-works_cardDesc .service-works_cardname {
  padding: 10px 0 0;
  font-size: min(1.8666666667vw, 2.4rem);
}
@media (min-width: 869px) {
  .service-works_cardDesc .service-works_cardname {
    padding: 15px 0 0;
    font-size: min(1.0666666667vw, 18px);
  }
}
@media (max-width: 520px) {
  .service-works_cardDesc .service-works_cardname {
    font-size: min(3.0666666667vw, 2.4rem);
  }
}
@media (min-width: 869px) {
  .service-flow {
    padding: 4vw 0 4vw;
  }
}
.service-flow_container {
  position: relative;
}
@media (min-width: 869px) {
  .service-flow_container {
    max-width: 600px;
    margin: 2.6666666667vw auto 0;
  }
}
.service-flow_container::before {
  position: absolute;
  top: -20%;
  left: -38%;
  width: 200px;
  height: 350px;
  background: url(../images/service_flowimg.png) no-repeat left top;
  background-size: 200px auto;
  content: "";
}
@media (max-width: 868px) {
  .service-flow_container::before {
    content: none;
  }
}
.service-flow_container::after {
  position: absolute;
  bottom: -45%;
  right: -128%;
  width: 480px;
  height: 680px;
  background: url(../images/service_flowimg02.png) no-repeat left top;
  background-size: 480px auto;
  content: "";
}
@media (max-width: 868px) {
  .service-flow_container::after {
    content: none;
  }
}
.service-flow_List {
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-left: 60px;
}
@media (max-width: 868px) {
  .service-flow_List {
    display: block;
    margin-left: 0;
  }
}
.service-flow_List dt {
  position: relative;
  font-weight: bold;
  text-align: center;
  padding: 18px 0;
  width: 16vw;
  font-size: 7.3333333333vw;
  margin: 0 auto 8vw;
  display: block;
}
.service-flow_List dt::after {
  content: "";
  position: absolute;
  bottom: -45px;
  left: 6.4vw;
  border: 12px solid transparent;
  border-top: 16px solid #333;
}
@media (min-width: 869px) {
  .service-flow_List dt::after {
    bottom: -85%;
    left: 35px;
  }
}
@media (max-width: 520px) {
  .service-flow_List dt::after {
    bottom: -28px;
    border: 8px solid transparent;
    border-top: 12px solid #333;
  }
}
@media (min-width: 869px) {
  .service-flow_List dt {
    font-size: max(1.6vw, 3rem);
    width: 18%;
    margin: 0 auto 0;
  }
}
@media (min-width: 869px) {
  .service-flow_List dd {
    width: 80%;
    margin-left: 15px;
  }
}
.service-flow_List dd h3 {
  font-size: 4.6666666667vw;
  text-align: center;
}
@media (min-width: 869px) {
  .service-flow_List dd h3 {
    font-size: 2.4rem;
    text-align: left;
  }
}
.service-flow_List dd p {
  margin-top: 10px;
  line-height: 1.5;
  font-size: 5.3333333333vw;
}
@media (min-width: 869px) {
  .service-flow_List dd p {
    font-size: 2rem;
  }
}
@media (min-width: 869px) {
  .service-flow_List:last-child dt::after {
    content: none;
  }
}
.service-accordion {
  padding-bottom: 8vw;
}
.service-accordion_inner {
  position: relative;
  padding: 60px 0 20px;
  background-color: #F9F9F9;
  margin: 9.3333333333vw auto 0;
}
@media (min-width: 869px) {
  .service-accordion_inner {
    max-width: 980px;
    margin: 5.3333333333vw auto 0;
  }
}
.service-accordion_inner::before {
  position: absolute;
  bottom: -70%;
  left: -60%;
  width: 500px;
  height: 604px;
  background: url(../images/service_accordionimg.png) no-repeat left top;
  background-size: 450px auto;
  content: "";
  z-index: 1;
}
@media (max-width: 868px) {
  .service-accordion_inner::before {
    bottom: -50%;
    left: -70%;
    width: 350px;
    height: 450px;
    background-size: 350px auto;
  }
}
@media (max-width: 520px) {
  .service-accordion_inner::before {
    bottom: -45%;
    left: -80%;
    width: 250px;
    height: 350px;
    background-size: 250px auto;
  }
}
.service-accordion_container {
  max-width: 90%;
  margin: 50px auto;
}
@media (min-width: 869px) {
  .service-accordion_container {
    max-width: 80%;
  }
}
.service-accordion-list {
  margin-bottom: 40px;
  padding: 0 20px;
  line-height: 1.5;
}
.service-accordion_title {
  position: relative;
  font-size: 3.3333333333vw;
  font-weight: bold;
  padding: 30px 25px;
  background-color: #fff;
}
@media (min-width: 869px) {
  .service-accordion_title {
    font-size: 2rem;
    padding: 30px;
  }
}
.service-accordion_title::before {
  position: absolute;
  content: "Q";
  font-size: 3.3333333333vw;
  left: 0px;
}
@media (min-width: 869px) {
  .service-accordion_title::before {
    font-size: 2rem;
  }
}
.service-accordion_title::after {
  position: absolute;
  display: block;
  content: "";
  top: 40%;
  right: 0px;
  border: 10px solid transparent;
  border-top: 12px solid #333;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 868px) {
  .service-accordion_title::after {
    right: 0;
    border: 6px solid transparent;
    border-top: 8px solid #333;
  }
}
.service-accordion_text {
  display: none;
  padding: 20px 20px;
  border-top: 1px solid #333;
}
.service-news {
  padding: 13.3333333333vw 0 10.6666666667vw;
  background: #F9F9F9;
}
@media (min-width: 869px) {
  .service-news {
    padding: 5.3333333333vw 0;
  }
}
.service-news_content {
  position: relative;
  margin-top: 5.3333333333vw;
  background-color: #fff;
  border-radius: 20px;
  padding: 4vw 4vw 1.3333333333vw;
}
@media (min-width: 869px) {
  .service-news_content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    max-width: 1000px;
    margin: 5.3333333333vw auto 0;
    padding: 4vw 6.6666666667vw 1.3333333333vw;
  }
}
.service-news_content-item a {
  display: block;
  padding: 25px 10px;
  text-decoration: none;
  color: #444;
  position: relative;
}
@media (min-width: 869px) {
  .service-news_content-item a {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 6px 0;
    margin: 6px 0;
  }
}
.service-news_content-itemDate {
  letter-spacing: 1px;
  font-weight: 700;
  color: #aaa;
  line-height: 1.5;
  font-size: 1.6vw;
  width: auto;
  display: block;
  margin-bottom: 10px;
}
@media (min-width: 869px) {
  .service-news_content-itemDate {
    font-size: 1.6rem;
    width: 32%;
  }
}
@media (max-width: 520px) {
  .service-news_content-itemDate {
    font-size: 4vw;
  }
}
.service-news_content-itemTtl {
  font-weight: 400;
  margin-right: auto;
  line-height: 1.5;
  font-size: 2.4vw;
}
@media (min-width: 869px) {
  .service-news_content-itemTtl {
    padding: 20px 0;
    font-size: 2rem;
    text-overflow: ellipsis;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
@media (max-width: 520px) {
  .service-news_content-itemTtl {
    font-size: 4.6666666667vw;
  }
}
.service-news_content-itemTtl:hover {
  text-decoration: underline;
}
.service-news_content-itemTtl .headline {
  font-weight: bold;
}
.service-news_content-itemTtl .desc {
  line-height: 1.5;
}
.service-news_content-item:nth-child(2) {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.service-news_content-item:nth-child(3) {
  border-bottom: 1px solid #e6e6e6;
}
.service-news_content::after {
  position: absolute;
  bottom: -50%;
  right: -55%;
  width: 400px;
  height: 600px;
  background: url(../images/service_newsimg.png) no-repeat left top;
  background-size: 400px auto;
  content: "";
}
@media (max-width: 868px) {
  .service-news_content::after {
    content: none;
  }
}
@media (max-width: 520px) {
  .service-news_content::after {
    bottom: -50%;
    right: -35%;
    width: 200px;
    height: 400px;
    background-size: 200px auto;
  }
}
.service-contact {
  margin-top: 80px;
  position: relative;
  z-index: 1;
}
@media (max-width: 868px) {
  .service-contact {
    padding: 5.3333333333vw 0;
  }
}
.service-contact_title {
  text-align: center;
  padding: 2.6666666667vw 0;
}
.service-contact_title::before {
  background: #333 !important;
}
.service-contact_content {
  padding: 2.6666666667vw 0 0;
}
.service-contact_head {
  text-align: center;
  line-height: 1.5;
  margin: 30px 0;
  font-size: 4.6666666667vw;
  color: #333;
}
@media (min-width: 869px) {
  .service-contact_head {
    margin-bottom: 40px;
    font-size: max(1.3333333333vw, 26px);
    margin: 30px 0;
  }
}
.service-contact_inner {
  justify-content: space-between;
  padding: 4vw 0;
}
@media (max-width: 868px) {
  .service-contact_inner {
    display: block;
  }
}
.service-contact_item {
  position: relative;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  background-color: #FDFDD8;
  padding: 15px 10px;
}
@media (min-width: 869px) {
  .service-contact_item {
    width: calc(48% - 8px);
    padding: 15px 15px 10px;
  }
}
.service-contact_item p {
  color: #333;
  text-align: center;
  font-size: min(1.4666666667vw, 16px);
  line-height: 1.5;
}
@media (max-width: 868px) {
  .service-contact_item p {
    font-size: 2.6666666667vw;
  }
}
@media (max-width: 320px) {
  .service-contact_item p {
    font-size: 3.3333333333vw;
  }
}
@media (max-width: 868px) {
  .service-contact_item + .service-contact_item {
    margin-top: 13.3333333333vw;
  }
}
@media (max-width: 520px) {
  .service-contact_item + .service-contact_item {
    margin-top: 20vw;
  }
}
.service-contact_btn_txt {
  position: absolute;
  top: -35px;
  background-color: #fff;
  font-weight: bold;
  display: block;
  width: 95%;
  font-size: max(1.0666666667vw, 16px);
  padding: 10px;
  filter: drop-shadow(2px 4px 5px rgba(0, 0, 0, 0.3));
}
.service-contact_btn_txt::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -20px;
  border: 12px solid transparent;
  border-top: 18px solid #fff;
}
@media (max-width: 868px) {
  .service-contact_btn_txt {
    top: -5.0666666667vw;
    left: 15px;
  }
}
@media (max-width: 520px) {
  .service-contact_btn_txt {
    top: -3.7333333333vw;
    left: 10px;
    padding: 5px 10px;
  }
  .service-contact_btn_txt::before {
    border: 10px solid transparent;
    border-top: 14px solid #fff;
    left: 52%;
  }
}
@media (max-width: 320px) {
  .service-contact_btn_txt {
    top: -6.6666666667vw;
  }
}
.service-contact_btn {
  margin: 20px auto 10px;
  max-width: 500px;
}
.service-contact_btn a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-radius: 20px;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  font-size: 3.3333333333vw;
  font-weight: bold;
  color: #333 !important;
}
.service-contact_btn a::after {
  content: "";
  width: 8px;
  height: 8px;
  border: 0;
  border-top: solid 2px #333;
  border-right: solid 2px #333;
  position: absolute;
  top: 50%;
  right: 30px;
  margin-top: -4px;
  transform: rotate(45deg);
}
@media (max-width: 520px) {
  .service-contact_btn a::after {
    width: 6px;
    height: 6px;
    right: 10px;
  }
}
@media (min-width: 869px) {
  .service-contact_btn a {
    font-size: min(1.6vw, 22px);
  }
}
@media (max-width: 520px) {
  .service-contact_btn a {
    font-size: 4.6666666667vw;
    height: 40px;
  }
}

@media (min-width: 869px) {
  #aws .service-mv-inner::after {
    position: absolute;
    top: 47%;
    width: 80%;
    height: 50px;
    background: url(../images/border_aws.png) no-repeat center;
    background-size: 100% auto;
    content: "";
  }
}
#aws .service-melit_ttl::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -20px;
  border: 18px solid transparent;
  border-top: 23px solid #F4F403;
}
#aws .service-aws_partner {
  margin-top: 2.6666666667vw;
}
@media (min-width: 869px) {
  #aws .service-aws_partner {
    display: flex;
    align-items: center;
    width: 80%;
  }
}
#aws .service-aws_partner span {
  font-size: 2.9333333333vw;
}
@media (min-width: 869px) {
  #aws .service-aws_partner span {
    font-size: max(1.0666666667vw, 18px);
  }
}
#aws .service-aws_img {
  display: inline-block;
}

@media (max-width: 868px) {
  #grandit .service-mv-ttl p::before {
    position: absolute;
    content: "";
    width: 50%;
    left: 0;
    top: -6px;
    border-top: 7px solid #38AFFE;
  }
}
@media (min-width: 869px) {
  #grandit .service-mv-inner::after {
    position: absolute;
    top: 52%;
    width: 80%;
    height: 50px;
    background: url(../images/border_grandit.png) no-repeat center;
    background-size: 100% auto;
    content: "";
  }
}
@media (min-width: 869px) {
  #grandit .service-concept_img img {
    width: 100px;
  }
}
#grandit .service-melit_ttl::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -20px;
  border: 18px solid transparent;
  border-top: 23px solid #38AFFE;
}
@media (min-width: 869px) {
  #grandit .service-section_item:nth-child(2n) .service-section_img {
    width: 10%;
  }
}
@media (min-width: 869px) {
  #grandit .service-flow_container::before {
    position: absolute;
    top: -20%;
    left: -38%;
    width: 200px;
    height: 350px;
    background: url(../images/grandit_flowimg.png) no-repeat left top;
    background-size: 200px auto;
    content: "";
  }
}
#grandit .service-contact_item {
  background-color: #DBEFFE;
}

@media (max-width: 868px) {
  #service_now .service-mv-ttl p::before {
    position: absolute;
    content: "";
    width: 50%;
    left: 0;
    top: -6px;
    border-top: 7px solid #00CC7F;
  }
}
@media (min-width: 869px) {
  #service_now .service-mv-inner::after {
    position: absolute;
    top: 52%;
    width: 80%;
    height: 50px;
    background: url(../images/border_servicenow.png) no-repeat center;
    background-size: 100% auto;
    content: "";
  }
}
@media (max-width: 868px) {
  #service_now .service-ttl p::before {
    position: absolute;
    content: "";
    width: 50%;
    left: 0;
    top: -6px;
    border-top: 7px solid #00CC7F;
  }
}
#service_now .service-melit_ttl::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -20px;
  border: 18px solid transparent;
  border-top: 23px solid #00CC7F;
}
@media (max-width: 868px) {
  #service_now .service-concept_item:last-child .service-concept_img {
    width: 60%;
  }
}
@media (min-width: 869px) {
  #service_now .service-section_item:nth-child(2n) .service-section_img {
    width: 20%;
  }
}
#service_now .service-contact_item {
  background-color: #D8F5E6;
}
@media (min-width: 869px) {
  #service_now .service-flow_container::before {
    position: absolute;
    top: -20%;
    left: -38%;
    width: 200px;
    height: 350px;
    background: url(../images/service_flowgreenimg.png) no-repeat left top;
    background-size: 200px auto;
    content: "";
  }
}

@media (min-width: 869px) {
  .pc-none {
    display: none;
  }
}

.sp-none {
  display: none;
}
@media (min-width: 869px) {
  .sp-none {
    display: block;
  }
}

.mini-none {
  display: none;
}
@media (max-width: 320px) {
  .mini-none {
    display: block;
  }
}

.wpcf7 .screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  word-wrap: normal !important;
}

.wpcf7 form .wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.2em 1em;
  border: 2px solid #00a0d2;
}

.wpcf7 form.init .wpcf7-response-output,
.wpcf7 form.resetting .wpcf7-response-output,
.wpcf7 form.submitting .wpcf7-response-output {
  display: none;
}

.wpcf7 form.sent .wpcf7-response-output {
  border-color: #46b450;
}

.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output {
  border-color: #dc3232;
}

.wpcf7 form.spam .wpcf7-response-output {
  border-color: #f56e28;
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output,
.wpcf7 form.payment-required .wpcf7-response-output {
  border-color: #ffb900;
}

.wpcf7-form-control-wrap {
  position: relative;
}

.wpcf7-not-valid-tip {
  color: #dc3232;
  font-size: 1em;
  font-weight: 400;
  display: block;
}

.use-floating-validation-tip .wpcf7-not-valid-tip {
  position: relative;
  top: -2ex;
  left: 1em;
  z-index: 100;
  border: 1px solid #dc3232;
  background: #fff;
  padding: 0.2em 0.8em;
  width: 24em;
}

.wpcf7-list-item {
  display: inline-block;
  margin: 0 0 0 1em;
}

.wpcf7-list-item-label::before,
.wpcf7-list-item-label::after {
  content: " ";
}

.wpcf7-spinner {
  visibility: hidden;
  display: inline-block;
  background-color: #23282d;
  opacity: 0.75;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 100%;
  padding: 0;
  margin: 0 24px;
  position: relative;
}

form.submitting .wpcf7-spinner {
  visibility: visible;
}

.wpcf7-spinner::before {
  content: "";
  position: absolute;
  background-color: #fbfbfc;
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border: none;
  border-radius: 100%;
  transform-origin: 8px 8px;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion: reduce) {
  .wpcf7-spinner::before {
    animation-name: blink;
    animation-duration: 2000ms;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes blink {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.wpcf7 [inert] {
  opacity: 0.5;
}

.wpcf7 input[type=file] {
  cursor: pointer;
}

.wpcf7 input[type=file]:disabled {
  cursor: default;
}

.wpcf7 .wpcf7-submit:disabled {
  cursor: not-allowed;
}

.wpcf7 input[type=url],
.wpcf7 input[type=email],
.wpcf7 input[type=tel] {
  direction: ltr;
}

.wpcf7-reflection > output {
  display: list-item;
  list-style: none;
}

.wpcf7-reflection > output[hidden] {
  display: none;
}