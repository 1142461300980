@use "../global"as *;

.single {
    position: relative;

    &-mv {
        position: relative;
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;

        @include pc() {
            background-image: url(../images/background-service_mv.png);
            background-position: center;
        }

        @include sp() {
            background-image: url(../images/background_mvsp.png);
            height: vw(1200);
        }

        @include small() {
            height: vw(1550);
        }

        @include mini() {
            height: 50vh;
        }

        &_wrap {
            height: vw(1200);

            @include pc() {
                height: vw(420);
            }

            @include small() {
                height: vw(1550);
            }

            @include mini() {
                height: 60vh;
            }
        }
    }

    &_detail {
        margin-top: vw(40);

        h1 {
            font-size: max(vw(35), 24px);
            margin: vw(20) 0;
            border-bottom: 1px solid #707070;
            padding-bottom: 20px;

            @include pc() {
                margin: vw(10) 0;
                font-size: 3rem;
            }
        }
    }

    &_category {
        &Name {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4rem;
            border: 0.1rem solid $color-yellow;
            transition: all .2s;
            cursor: pointer;
            font-size: vw(15);
            max-width: 160px;
            width: 35%;

            @include pc() {
                font-size: 1.6rem;
                width: 18%;
                margin-right: vw(15);
            }

            @include small() {
                font-size: vw(30);
            }
        }
    }

    &_body {

        &-Img {
            display: block;
            height: 600px;
            margin: vw(10) 0 vw(25);

            img {
                object-fit: cover;
                height: 100%;
            }

            @include sp() {
                height: auto;
            }
        }

        &-cont {
            h1 {
                font-size: max(vw(38), 26px);
                margin: vw(20) 0;
                border-bottom: 1px solid #707070;
                padding-bottom: 20px;

                @include pc() {
                    margin: vw(10) 0;
                    font-size: 3rem;
                }
            }


            h2 {
                font-size: max(vw(35), 22px);
                border-bottom: 1px solid #707070;
                padding-bottom: 20px;

                @include pc() {
                    font-size: max(vw(7), 26px);
                }
            }

            h3 {
                font-size: max(vw(32), 20px);
                border-bottom: 1px solid #707070;
                padding-bottom: 20px;

                @include pc() {
                    font-size: max(vw(7), 24px);
                }
            }

            h4 {
                font-size: max(vw(30), 18px);
                border-bottom: 1px solid #707070;
                padding-bottom: 20px;

                @include pc() {
                    font-size: max(vw(7), 20px);
                }
            }

            font-size: vw(28);

            @include pc() {
                font-size: 2rem;
            }
        }
    }

    &_contentList {

        @include sp() {
            margin-top: vw(60);
        }

        &-item {
            h3 {
                font-size: max(vw(28), 16px);
                padding: 15px 30px 15px;
                -webkit-box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
                box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
                text-align: center;

                @include pc() {
                    font-size: max(vw(7), 20px);
                }
            }

            p {
                margin-top: 15px;
                color: #707070;
            }

            &:nth-child(2n) {
                margin: vw(30) 0;

                @include sp() {
                    margin: vw(50) 0;
                }
            }
        }
    }

    &-works {
        position: relative;
        margin-top: vw(-1000);
        z-index: 10;

        @include pc() {
            margin-top: vw(-350);
        }

        @include small() {
            margin-top: vw(-1400);
            padding: vw(60) 0;
        }

        @include mini() {
            margin-top: -35vh;
        }

        &::before {
            position: absolute;
            bottom: 0%;
            left: -5%;
            width: 500px;
            height: 604px;
            background: url(../images/background_service01.png) no-repeat left top;
            background-size: 450px auto;
            content: "";
            z-index: 1;

            @include sp() {
                bottom: 0%;
                left: -5%;
                width: 350px;
                height: 450px;
                background-size: 350px auto;
            }

            @include small() {
                bottom: -0%;
                left: -10%;
                width: 250px;
                height: 350px;
                background-size: 250px auto;
            }
        }

        &::after {
            position: absolute;
            bottom: -10%;
            right: -8%;
            width: 400px;
            height: 600px;
            background: url(../images/service_newsimg.png) no-repeat left top;
            background-size: 400px auto;
            content: "";

            @include sp() {
                bottom: -12%;
                right: -12%;
                width: 300px;
                height: 500px;
                background-size: 300px auto;
            }

            @include small() {
                bottom: -10%;
                right: -8%;
                width: 200px;
                height: 400px;
                background-size: 200px auto;
            }
        }

        @include pc() {
            padding: vw(40) 0 vw(20);
        }

        .ttl {
            margin-bottom: vw(40);

            @include pc() {
                margin-bottom: 40px;
            }
        }

        .sub_ttl {
            margin-bottom: vw(30);

            @include sp() {
                font-size: max(vw(20), 16px);
            }
        }

        &_inner {
            position: relative;
            margin-top: vw(80);
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @include small() {
                display: block;
            }

            @include sp() {
                margin-top: vw(120);
            }

        }

        .menu-box {
            &:first-child {
                margin: 10px;
            }
        }

        &_card {
            height: inherit;
            background: #fff;
            position: relative;
            // padding: 40px 0px;
            margin: 0 0 vw(120);
            // box-shadow: 0 2px 8px rgba(0, 0, 0, .05);
            z-index: 1;
            width: 100%;

            @include pc() {
                padding: 0;
                width: calc(33.333% - 25px);
                margin-bottom: vw(80);

                +.single-works_card {
                    margin-left: 20px;
                }
            }


            @include small() {
                margin: 0 0 vw(300);

                &:nth-child(2n) {
                    margin-left: 0px;
                }
            }

            @include mini() {
                margin: 0 0 vw(350);
            }


            a {
                color: #333;

                &:hover {
                    opacity: 1;
                }
            }

            &ImgBox {
                position: relative;
                overflow: hidden;
                display: block;
                width: 100%;

                &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    padding-top: 70%;
                }



                @include pc() {
                    // width: 50%;
                }

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                    -o-object-position: center center;
                    object-position: center center;
                    transition: transform .5s, opacity .5s, -webkit-transform .5s;
                }

                &:hover {
                    .single-works_cardImg {
                        &::after {
                            opacity: 1;
                        }
                    }

                    img {
                        transform: scale(1.1);
                        opacity: 1;

                    }

                    .single-works_cardlogo {
                        img {
                            transform: scale(1);

                        }
                    }


                }
            }

            &logo {
                position: absolute;
                top: 0;
                right: 0;
                width: 80px;
                height: 80px;

            }

            &Desc {
                padding: 15px vw(10);
                position: absolute;
                width: 95%;
                left: 2.5%;
                top: 65%;
                background: #fff;
                height: inherit;
                -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
                box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
                border-radius: 20px;

                @include sp() {
                    padding: 25px vw(25);
                }


                @include small() {
                    padding: 15px vw(30);
                }





                .single-works_cardTtl {
                    font-size: min(vw(30), 2.6rem);
                    letter-spacing: 2px;
                    color: #444;
                    line-height: 1.5;
                    width: 100%;

                    @include pc() {
                        font-size: min(vw(9), 1.8rem);
                    }

                }

                .single-works_cardcont {
                    color: #666;
                    letter-spacing: 2px;
                    font-size: min(vw(23), 2.4rem);
                    line-height: 1.5;
                    text-transform: uppercase;
                    margin-top: vw(5);
                    border-bottom: 1px solid #eee;
                    padding-bottom: 20px;

                    @include pc() {
                        font-size: min(vw(8), 16px);
                    }

                    @include small() {
                        font-size: min(vw(25), 2rem);
                    }
                }

                .single-works_cardname {
                    padding: 10px 0 0;
                    font-size: min(vw(23), 2.4rem);

                    @include pc() {
                        padding: 15px 0 0;
                        font-size: min(vw(8), 18px);
                    }

                    @include small() {
                        font-size: min(vw(23), 2.4rem);
                    }

                }
            }

            @include pc() {
                &:nth-child(n+4) {
                    margin: 0 0 vw(120);
                }
            }
        }

    }


    &-news {
        position: relative;
        margin-top: vw(-1000);
        z-index: 10;

        @include pc() {
            margin-top: vw(-350);
        }

        @include small() {
            margin-top: vw(-1400);
            padding: vw(60) 0;
        }

        @include mini() {
            margin-top: -45vh;
        }

        &::before {
            position: absolute;
            bottom: 0%;
            left: -5%;
            width: 500px;
            height: 604px;
            background: url(../images/background_service01.png) no-repeat left top;
            background-size: 450px auto;
            content: "";
            z-index: 1;

            @include sp() {
                bottom: 0%;
                left: -5%;
                width: 350px;
                height: 450px;
                background-size: 350px auto;
            }

            @include small() {
                bottom: -0%;
                left: -30%;
                width: 250px;
                height: 350px;
                background-size: 250px auto;
            }

            @include mini() {
                content: none;
            }
        }

        &::after {
            position: absolute;
            bottom: -10%;
            right: -8%;
            width: 400px;
            height: 600px;
            background: url(../images/service_newsimg.png) no-repeat left top;
            background-size: 400px auto;
            content: "";

            @include sp() {
                bottom: -5%;
                right: -30%;
                width: 300px;
                height: 500px;
                background-size: 300px auto;
            }

            @include small() {
                bottom: -7%;
                right: -40%;
                width: 300px;
                height: 400px;
                background-size: 300px auto;
            }

            @include mini() {
                content: none;
            }
        }

        @include pc() {
            padding: vw(40) 0 vw(20);
        }

        .ttl {
            margin-bottom: vw(40);

            @include pc() {
                margin-bottom: 40px;
            }
        }

        .sub_ttl {
            margin-bottom: vw(30);

            @include sp() {
                font-size: max(vw(20), 16px);
            }
        }


        &_inner {
            position: relative;
            margin-top: vw(70);
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @include small() {
                margin-top: vw(120);
                display: block;
            }

        }

        .menu-box {
            &:first-child {
                margin: 10px;
            }
        }

        &_card {
            height: inherit;
            background: #fff;
            position: relative;
            margin: 0 0 vw(120);
            // box-shadow: 0 2px 8px rgba(0, 0, 0, .05);
            z-index: 1;
            width: 100%;

            @include pc() {
                padding: 0;
                width: calc(33.333% - 25px);
                margin-bottom: vw(80);

                +.single-news_card {
                    margin-left: 20px;
                }
            }

            @include small() {
                margin: 0 0 vw(200);

                &:nth-child(2n) {
                    margin-left: 0px;
                }
            }

            @include mini() {
                margin: 0 0 vw(250);
            }


            a {
                color: #333;

                &:hover {
                    opacity: 1;
                }
            }

            &ImgBox {
                position: relative;
                overflow: hidden;
                display: block;
                width: 100%;

                &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    padding-top: 70%;
                }



                @include pc() {
                    // width: 50%;
                }

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                    -o-object-position: center center;
                    object-position: center center;
                    transition: transform .5s, opacity .5s, -webkit-transform .5s;
                }

                &:hover {
                    .single-news_cardImg {
                        &::after {
                            opacity: 1;
                        }
                    }

                    img {
                        transform: scale(1.1);
                        opacity: 1;

                    }

                    .single-news_cardlogo {
                        img {
                            transform: scale(1);

                        }
                    }


                }
            }

            &category {
                position: absolute;
                top: 0;
                right: 0;
                padding: 10px 20px;
                border: 1px solid $color-yellow;
                font-size: min(vw(23), 2.4rem);

                @include pc() {
                    font-size: min(vw(8), 16px);
                }

                @include small() {
                    font-size: min(vw(25), 2rem);
                    padding: 10px vw(30);
                }
            }

            &Desc {
                padding: 15px vw(10);
                position: absolute;
                width: 95%;
                left: 2.5%;
                top: 65%;
                background: #fff;
                height: inherit;
                -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
                box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
                border-radius: 20px;

                @include sp() {
                    padding: 25px vw(25);
                }


                @include small() {
                    padding: 15px vw(30);
                }


                .single-news_cardTtl {
                    font-size: min(vw(30), 2.6rem);
                    letter-spacing: 2px;
                    color: #444;
                    line-height: 1.5;
                    width: 100%;

                    @include pc() {
                        font-size: min(vw(9), 1.8rem);
                    }

                }


                .single-news_cardcont {
                    color: #666;
                    letter-spacing: 2px;
                    font-size: min(vw(23), 2.4rem);
                    line-height: 1.5;
                    text-transform: uppercase;
                    margin-top: vw(5);
                    border-bottom: 1px solid #eee;
                    padding-bottom: 20px;

                    @include pc() {
                        font-size: min(vw(8), 16px);
                    }

                    @include small() {
                        font-size: min(vw(25), 2rem);
                    }
                }

                .single-news_cardname {
                    padding: 10px 0 0;
                    font-size: min(vw(14), 2.4rem);

                    @include pc() {
                        padding: 15px 0 0;
                        font-size: min(vw(8), 18px);
                    }

                    // @include small() {
                    //     font-size: min(vw(23), 2.4rem);
                    // }

                }
            }

            @include pc() {
                &:nth-child(n+4) {
                    margin: 0 0 vw(120);
                }
            }
        }

    }

    &-contact {
        margin-top: 80px;
        position: relative;
        z-index: 1;

        @include sp() {
            padding: vw(40) 0;
        }

        &_title {
            text-align: center;
            padding: vw(20) 0;
            color: #fff !important;

            &::before {
                background: #fff !important;
            }
        }

        &_content {
            padding: vw(20) 0 0;
        }

        &_head {
            color: #fff;
            text-align: center;
            line-height: 1.5;
            margin: 30px 0;
            font-size: vw(35);

            @include pc() {
                margin-bottom: 40px;
                font-size: max(vw(10), 26px);
                margin: 30px 0;
            }
        }

        &_inner {
            justify-content: space-between;
            padding: vw(30) 0;

            @include sp() {
                display: block;
            }
        }


        &_item {
            position: relative;
            -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
            background-color: #FFF3D7;
            padding: 15px 10px;

            @include pc() {
                width: calc(48% - 8px);
                padding: 15px 15px 10px;
            }

            p {
                color: #333;
                text-align: center;
                font-size: min(vw(11), 16px);
                line-height: 1.5;

                @include sp() {
                    font-size: vw(20);
                }

                @include mini() {
                    font-size: vw(25);
                }

            }

            // &:nth-child(2) {
            //   margin-left: vw(60);
            // }

            @include sp() {
                &+.single-contact_item {
                    margin-top: vw(100);
                }
            }

            @include small() {
                &+.single-contact_item {
                    margin-top: vw(150);
                }
            }
        }

        &_btn_txt {
            position: absolute;
            top: -35px;
            background-color: #fff;
            font-weight: bold;
            display: block;
            width: 95%;
            font-size: max(1.0666666667vw, 16px);
            padding: 10px;
            filter: drop-shadow(2px 4px 5px rgba(0, 0, 0, 0.3));


            &::before {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -20px;
                border: 12px solid transparent;
                border-top: 18px solid #fff;
            }

            @include sp() {
                top: vw(-38);
                left: 15px;
            }

            @include small() {
                top: vw(-28);
                left: 10px;
                padding: 5px 10px;

                &::before {
                    border: 10px solid transparent;
                    border-top: 14px solid #fff;
                    left: 52%;
                }
            }

            @include mini() {
                top: vw(-50);
            }

        }

        &_btn {
            margin: 20px auto 10px;
            max-width: 500px;

            a {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 60px;
                border-radius: 20px;
                -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
                box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
                font-size: vw(25);
                font-weight: bold;
                color: #333 !important;

                &::after {
                    content: '';
                    width: 8px;
                    height: 8px;
                    border: 0;
                    border-top: solid 2px #333;
                    border-right: solid 2px #333;
                    position: absolute;
                    top: 50%;
                    right: 30px;
                    margin-top: -4px;
                    transform: rotate(45deg);

                    @include small() {
                        width: 6px;
                        height: 6px;
                        right: 10px;
                    }
                }

                @include pc() {
                    font-size: min(vw(12), 22px);
                }

                @include small() {
                    font-size: vw(35);
                    height: 40px;
                }
            }
        }
    }

}