@use "../global"as *;

#service_now {
    .service {
        &-mv {
            @include sp() {
                &-ttl {
                    p {
                        &::before {
                            position: absolute;
                            content: "";
                            width: 50%;
                            left: 0;
                            top: -6px;
                            border-top: 7px solid $color-green;
                        }
                    }
                }
            }

            &-inner {
                @include pc() {
                    &::after {
                        position: absolute;
                        top: 52%;
                        width: 80%;
                        height: 50px;
                        background: url(../images/border_servicenow.png) no-repeat center;
                        background-size: 100% auto;
                        content: "";
                    }
                }
            }
        }

        @include sp() {
            &-ttl {
                p {
                    &::before {
                        position: absolute;
                        content: "";
                        width: 50%;
                        left: 0;
                        top: -6px;
                        border-top: 7px solid $color-green;
                    }
                }


            }
        }

        &-melit {
            &_ttl {
                &::before {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    margin-left: -20px;
                    border: 18px solid transparent;
                    border-top: 23px solid $color-green;
                }
            }
        }

        &-concept {
            &_item {
                @include sp() {
                    &:last-child {
                        .service-concept_img {
                            width: 60%;
                        }
                    }
                }
            }
        }

        &-section {
            &_item {
                &:nth-child(2n) {

                    @include pc() {
                        .service-section_img {
                            width: 20%;
                        }
                    }
                }
            }
        }

        &-contact {
            &_item {
                background-color: #D8F5E6;
            }
        }

        &-flow {
            @include pc() {
                &_container {
                    &::before {
                        position: absolute;
                        top: -20%;
                        left: -38%;
                        width: 200px;
                        height: 350px;
                        background: url(../images/service_flowgreenimg.png) no-repeat left top;
                        background-size: 200px auto;
                        content: "";
                    }
                }
            }
        }
    }
}