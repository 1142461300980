@use "../global"as *;

.ttl {
  position: relative;

  span {
    display: block;
  }

  .en {
    color: $ttl;
    @include en;
    display: block;
  }

  .ja {
    letter-spacing: 6px;

    @include pc {
      display: flex;
      align-items: center;
      text-align: left;
    }
  }

}


.section-title {
  position: relative;
  z-index: 1;

  .ttl {
    font-size: vw(40);
    text-align: center;
    color: #333;
    padding-bottom: 20px;

    @include pc() {
      font-size: 3.4rem;
    }

    @include mini() {
      font-size: 20px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 80px;
      height: 3px;
      text-align: center;
      bottom: 0;
      left: 50%;
      -webkit-transform: translateY(-0%) translateX(-50%);
      transform: translateY(-0%) translateX(-50%);
      background: $color-yellow;
    }
  }

  p {
    text-align: center;
    font-size: 2rem;
    font-weight: lighter;
  }

}

#aws {

  .section-title {
    position: relative;

    .ttl {
      font-size: vw(40);
      text-align: center;
      color: #333;
      padding-bottom: 20px;

      @include pc() {
        font-size: 3.4rem;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 80px;
        height: 3px;
        text-align: center;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateY(-0%) translateX(-50%);
        transform: translateY(-0%) translateX(-50%);
        background: $color-fresh;
      }
    }

    p {
      text-align: center;
      font-size: 2rem;
      font-weight: lighter;
    }

  }
}

#service_now {

  .section-title {
    position: relative;

    .ttl {
      font-size: vw(40);
      text-align: center;
      color: #333;
      padding-bottom: 20px;

      @include pc() {
        font-size: 3.4rem;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 80px;
        height: 3px;
        text-align: center;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateY(-0%) translateX(-50%);
        transform: translateY(-0%) translateX(-50%);
        background: $color-green;
      }
    }

    p {
      text-align: center;
      font-size: 2rem;
      font-weight: lighter;
    }

  }
}

#grandit {

  .section-title {
    position: relative;

    .ttl {
      font-size: vw(40);
      text-align: center;
      color: #333;
      padding-bottom: 20px;

      @include pc() {
        font-size: 3.4rem;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 80px;
        height: 3px;
        text-align: center;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateY(-0%) translateX(-50%);
        transform: translateY(-0%) translateX(-50%);
        background: $color-blue;
      }
    }

    p {
      text-align: center;
      font-size: 2rem;
      font-weight: lighter;
    }

  }
}

.archive {
  .section-title {
    position: relative;

    .ttl {
      font-size: vw(40);
      text-align: center;
      color: #333;
      padding-bottom: 20px;
      margin-bottom: vw(40);

      @include pc() {
        margin-bottom: 40px;
        font-size: 3.4rem;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 80px;
        height: 3px;
        text-align: center;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateY(-0%) translateX(-50%);
        transform: translateY(-0%) translateX(-50%);
        background: $color-yellow;
      }
    }

    p {
      text-align: center;
      font-weight: lighter;
      font-size: vw(20);

      @include pc() {
        font-size: 2rem;
      }
    }

    @include sp() {
      padding: vw(30) vw(100) vw(60);
    }
  }
}