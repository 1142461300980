@use "../global"as *;

.footer {
  padding: vw(50) 0 0;
  position: relative;

  &-breadcrumb {
    background: #f3f3f3;
    padding: vw(18) 0;
  }

  &-logo {
    // margin: 0 auto;
    width: 160px;

    @include pc() {
      text-align: left;
      width: 45%;
    }

    @include mini {
      margin: 0 auto;
    }
  }


  &-content {

    &_inner {
      padding-bottom: vw(30);

      @include pc() {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 vw(40);
      }
    }


    &_address {
      margin: vw(30) 0;

      @include pc() {
        width: 55%;
        margin: 0 vw(35);
      }

      &Img {
        img {
          width: 60px;
        }
      }

      p {
        margin-top: 10px;


      }


      a {
        color: #fff;
        display: inline-block;
      }
    }

    &_navwrap {
      @include pc() {
        width: 80%;
      }
    }

    &_navList {
      // max-width: 350px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include pc() {}


      li {
        margin-top: vw(20);
        width: calc(100% / 2);

        @include pc() {
          margin-top: vw(10);
        }

        @include small() {
          width: 100%;
          margin-top: vw(30);
        }


        a {
          color: #333;
          display: flex;
          align-items: center;
          position: relative;
          width: 100%;

          &::before {
            content: '';
            width: 6px;
            height: 6px;
            border: 0;
            border-top: solid 2px #333;
            border-right: solid 2px #333;
            position: absolute;
            top: 50%;
            left: -20px;
            margin-top: -4px;
            transform: rotate(45deg);
          }

          @include pc() {
            justify-content: flex-start;
          }

          @include sp() {
            padding-left: 15px;

            &::before {
              left: -5px;
            }
          }

        }

        // .en {
        //   font-family: 'Heebo', sans-serif;
        //   letter-spacing: .14em;
        //   margin-right: 5px;
        //   font-size: max(vw(20), 15px);

        //   @include pc {
        //     font-size: max(vw(8), 18px);
        //   }

        // }

        // .jp {
        //   font-size: max(vw(10), 10px);
        //   letter-spacing: .14em;
        //   white-space: nowrap;

        //   @include pc {
        //     font-size: 11px;
        //   }
        // }
      }
    }
  }

  &-copy {
    position: relative;
    height: 54px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 300;
    font-size: 14px;
    color: #333;

    @include sp() {
      font-size: 12px;
    }
  }
}