@use "../global"as *;



.accordion-trigger {
  text-align: center;
  cursor: pointer;
  display: block;
  /* background-color: lightblue; */
  position: relative;

  @include small() {
    text-align: left;
  }
}

.accordion-trigger::before,
.accordion-trigger::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  margin: auto;
  height: 1px;
  width: 15px;
  background-color: #000;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;

  @include small() {
    left: -30px;
  }
}

.accordion-trigger::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.accordion-trigger.is-opened::after {
  opacity: 0;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.accordion-target {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  height: 0;
  -webkit-transition: height 0.4s ease-out;
  transition: height 0.4s ease-out;
  text-align: center;
  display: block;

  @include small() {
    text-align: left;
  }
}

.accordion-target>.list__item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* border-bottom: 1px solid #ccc; */
  opacity: 1;
  padding-top: 10px;
  padding-bottom: 15px;
  font-size: 1.8rem;
  position: relative;

}



.accordion-target>.list__item:first-child {
  padding-top: 0;
}

.accordion-target>.list__item>a {
  display: block;
  margin-bottom: -15px;
  padding: 15px 0;
  color: #000;
  text-decoration: none;
  font-family: "Chillax", sans-serif;
}

.fedein {
  opacity: 0;
  transform: translate(0, 30px);
  transition: 0.4s;

  &.isAnimate {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@-webkit-keyframes active-btn05-bar02 {
  100% {
    height: 0;
  }
}

@keyframes active-btn05-bar02 {
  100% {
    height: 0;
  }
}

.slider {
  .slick-slide {
    width: 260px;
    // height: 400px;
    margin: 0 5px;
    position: relative;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .slick-next {
    right: 0;

    &::before {
      left: 20px;
    }
  }

  .slick-prev {
    left: 0;

    &::before {
      border-width: 0 0 3px 3px;
      right: 22px;
    }
  }

}

.slick-slider {

  .slick-arrow {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    // background-color: #fff;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 0;
    position: absolute;
    top: 200px;
    z-index: 1;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    opacity: 0;
    -webkit-transition: .4s;
    transition: .4s;

    &::before {
      content: '';
      display: block;
      width: 32px;
      height: 32px;
      border: 2px solid #fff;
      border-width: 3px 3px 0 0;
      position: absolute;
      top: 24px;
      left: 20px;
      transform: rotate(45deg);
    }
  }

  &:hover {
    .slick-arrow {
      opacity: 1;
    }
  }
}



.slider .slick-next {
  right: 0;
}

.slider .slick-prev {
  left: 0;
}

.slider .slick-next::before {
  left: 20px;
}

.slider .slick-prev::before {
  border-width: 0 0 3px 3px;
  right: 22px;
}

// .dots-2 .slick-dots {
//   text-align: center;
// }

// .dots-2 .slick-dots li {
//   display: inline-block;
// }

// .dots-2 .slick-dots button {
//   display: block;
//   width: 10px;
//   height: 10px;
//   margin: 6px;
//   font-size: 0;
//   padding: 0;
//   border: 0;
//   outline: none;
//   cursor: pointer;
//   background: #bcbcbc;
//   border-radius: 50%;
// }

// .dots-2 .slick-dots .slick-active button {
//   background: #0a467d;
// }