@use "../global"as *;

.sec-pt {
  padding-top: 100px;

  @include pc() {
    padding-top: 144px;
  }
}

.sec-orange {
  background: #F9F0E8;
}